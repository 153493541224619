import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "../../../store";
import AdvertisementPreviewPDF from "../../../components/Printing/AdvertisementPreviewPDF/AdvertisementPreviewPDF";

import Download from "../../../assets/icons/DownloadWhite.svg";

import classes from "./Header.module.scss";

const Header = (props) => {
  const [viewPdf, setViewPdf] = useState<boolean>(false);

  const t = useSelector(
    (state: RootState) =>
      state.translations.translations[state.translations.appLanguage]["product"]
  );

  const loggedUser = useSelector((state: RootState) => state.auth.user.user);
  const userId = loggedUser.id.toString();

  return (
    <React.Fragment>
      <div className={classes.header_wrapper}>
        <div className={classes.ad_info_wrapper}>
          <div className={classes.ad_info}>
            <div className={classes.ad_number}>
              {t.ad_number} <span>{props.products.advertisement.id}</span>
            </div>
            <span className={classes.vertical_line}></span>
            <div className={classes.date}>
              {props.products.advertisement.created_at}
            </div>
          </div>
          <div className={classes.actions_wrapper}>
            <div
              className={classes.download_doc}
              onClick={() => setViewPdf(true)}
            >
              <img src={Download} alt="Download" />
              {t.download_doc}
            </div>
            <Link
              to={`/messages/${props.products.advertisement.company_user_id}-${userId}?receiverId=${props.products.advertisement.company_user_id}`}
              className={classes.ask_question}
            >
              {t.ask_question}
            </Link>
          </div>
        </div>
        <div className={classes.company_info_wrapper}>
          <div className={classes.image_wrapper}>
            <div className={classes.image}>
              <img src={props.products.advertisement.company_logo} alt="" />
            </div>
            <div className={classes.company}>
              {props.products.advertisement.dogovoren_organ}
            </div>
          </div>
          <div className={classes.info_wrapper}>
            <div className={classes.label_style}>{t.contracting_authority}</div>
            <div className={classes.company}>
              {props.products.advertisement.dogovoren_organ}
            </div>
            <div className={classes.label_style}>
              {props.products.advertisement.company_website}
            </div>
            <div className={classes.label_style}>
              {props.products.advertisement.company_email}
            </div>
            <div className={classes.label_style}>
              {props.products.advertisement.company_address_info}
            </div>
            <div className={classes.label_style}>
              {props.products.advertisement.company_phone}
            </div>
          </div>
        </div>
      </div>
      {viewPdf && (
        <AdvertisementPreviewPDF
          data={props.products}
          onCancel={() => setViewPdf(false)}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
