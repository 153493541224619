import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
    Image
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import ModalComponent from "../../UI/ModalComponent/ModalComponent";
import { IPdfProps } from "./model";
import { RootState } from "../../../store";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

const styles = StyleSheet.create({
    viewer: {
        width: "100%",
        height: "80vh"
    },
    page: {
        fontFamily: "Roboto",
        padding: "10px",
        fontSize: "12px",
        color: "#404040",
        paddingBottom: "18px"
    },
    company_info_wrapper: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #DFDFDF",
        paddingBottom: "5px"
    },
    company_image_wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        marginRight: "15px"
    },
    info_wrapper: {
        width: "300px",
        color: "#808080"
    },
    date_wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "220px"
    },
    contact_person_wrapper: {
        padding: "5px 0",
        borderBottom: "1px solid #DFDFDF",
    },
    contact_info_wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    contact_info_text: {
        color: "#606060",
        fontSize: "11px"
    },
    ads_wrapper: {
        padding: "5px 0",
    },
    product_image_wrapper: {
        border: "1px solid #E3E7EE",
        borderRadius: "2px",
        width: "48px",
        height: "48px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    gray_color: {
        color: "#606060"
    },
    time_criteria: {
        padding: "5px 0",
        borderBottom: "1px solid #DFDFDF",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 11,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#F9FAFC",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
        padding: "0 10px"
    }
});

dayjs.extend(customParseFormat);

const OfferPreviewPDF = (props: IPdfProps) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const data = props?.data || {};

    const onCancelHandler = () => {
        props.onCancel();
    };
    
    const pdf = (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.company_info_wrapper}>
                        <View style={styles.company_image_wrapper}>
                            <View>
                                {(data?.logo && (data?.logo?.length > 0)) && (
                                    <Image
                                        src={data.logo}
                                        style={{
                                            maxWidth: "65px",
                                            maxHeight: "65px"
                                        }}
                                    />
                                )}
                            </View>
                            <Text style={{marginTop: "5px"}}>
                                {data?.naziv}
                            </Text>
                        </View>
                        <View style={styles.info_wrapper}>
                            <Text>{t.offers.contracting_authority}</Text>
                            <Text style={{color: "#404040"}}>
                                {data?.naziv}
                            </Text>
                            <Text>{data?.web_location}</Text>
                            <Text>{data?.email}</Text>
                            <Text>{data?.company_address} {data?.company_city}</Text>
                            <Text>{data?.company_number}</Text>
                        </View>
                        <View style={[styles.date_wrapper, {display: "flex", alignItems: "center", alignSelf: 'flex-start'}]}>
                            <Text style={{fontSize: "10px"}}>
                                {(data?.offer_created && dayjs(data?.offer_created).isValid())
                                    ? dayjs(data.offer_created).format("DD.MM.YYYY")
                                    : data.offer_created
                                }
                            </Text>
                            <Text style={{padding: "0 5px", color: "#9F9F9F"}}>|</Text>
                            <View style={{display: "flex", flexDirection: "row"}}>
                                <Text>{t.offers.offer_number}</Text>
                                <Text style={{color: "#0044BB"}}> {data.id}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.contact_person_wrapper}>
                        <Text>{t.offers.contact_person}</Text>
                        <View style={styles.contact_info_wrapper}>
                            <Text style={styles.contact_info_text}>
                                {data?.contact_person}
                            </Text>
                            <Text style={styles.contact_info_text}>
                                {data?.contact_email}
                            </Text>
                            <Text style={styles.contact_info_text}>
                                {data?.contact_phone}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.ads_wrapper}>
                        <Text style={{marginBottom: "5px"}}>
                            {t.offers.offer_title}
                        </Text>
                        {data?.products && (
                            data.products.map((prod, index) => {
                                return(
                                    <View
                                        key={index}
                                        style={{
                                            padding: "5px 0",
                                            borderBottom: (index < (data.products.length - 1)) ? "0.6px solid #DFDFDF" : "none",
                                        }}
                                    >
                                        <View
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                width: "100%"
                                            }}
                                        >
                                            <View
                                                style={{
                                                    width: "400px",
                                                    padding: "0 10px 0 0"
                                                }}
                                            >
                                                <View>
                                                    <View
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center"
                                                        }}>
                                                        <View style={styles.product_image_wrapper}>
                                                            {(prod?.images && (prod?.images?.length > 0)) && (
                                                                <Image
                                                                    src={prod?.images[0]?.file_url}
                                                                    style={{
                                                                        maxWidth: "45px",
                                                                        maxHeight: "45px",
                                                                        width: "100%",
                                                                        height: "auto"
                                                                    }}
                                                                />
                                                            )}
                                                        </View>
                                                        <View
                                                            style={{
                                                                padding: "0 0 0 10px"
                                                            }}
                                                        >
                                                            <Text>{prod?.title}</Text>
                                                            <Text
                                                                style={{
                                                                    color: "#606060",
                                                                    fontSize: "10px"
                                                                }}
                                                            >
                                                                {prod?.category}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                                <Text
                                                    style={{
                                                        color: "#323232",
                                                        fontSize: "11px",
                                                        marginTop: "5px",
                                                        width: "440px"
                                                    }}
                                                >
                                                    {prod?.description}
                                                </Text>
                                                {(prod?.images && (prod?.images?.length > 0)) && (
                                                    <View
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginTop: "5px"
                                                        }}
                                                    >
                                                        {prod.images.map((img, index) => {
                                                            return(
                                                                <View
                                                                    key={index}
                                                                    style={{
                                                                        width: "87px",
                                                                        height: "87px",
                                                                        marginRight: "5px",
                                                                        border: "1px solid #E3E7EE",
                                                                        borderRadius: "4px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    {(img?.file_url && (img?.file_url?.length > 0)) && (
                                                                        <Image
                                                                            src={img.file_url}
                                                                            style={{
                                                                                maxWidth: "85px",
                                                                                maxHeight: "85px",
                                                                                width: "100%",
                                                                                height: "auto"
                                                                            }}
                                                                        />
                                                                    )}
                                                                </View>
                                                            )
                                                        })}
                                                    </View>
                                                )}
                                            </View>
                                            <View>
                                                <View
                                                    style={{
                                                        width: "150px",
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    <Text>{t.offers.quantity_requested}</Text>
                                                    <Text
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "#606060"
                                                        }}
                                                    >{prod?.quantity}</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        width: "150px",
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    <Text>{t.offers.offered_price}</Text>
                                                    <Text
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "#606060"
                                                        }}
                                                    >{prod?.offer_price}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                )
                            })
                        )}
                    </View>
                    <View style={[styles.time_criteria, {borderTop: "1px solid #DFDFDF"}]}>
                        <Text>{t.offers.time_criteria_title}</Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                maxWidth: "400px"
                            }}
                        >
                            <View>
                                <Text style={styles.gray_color}>
                                    {t.offers.delivery_deadline}
                                </Text>
                                <Text>
                                    {(data?.datum_za_dostavuvanje && dayjs(data.datum_za_dostavuvanje).isValid())
                                        ? dayjs(data.datum_za_dostavuvanje).format("DD.MM.YYYY")
                                        : data?.datum_za_dostavuvanje
                                    }
                                </Text>
                            </View>
                            <View>
                                <Text style={styles.gray_color}>
                                    {t.offers.criteria_title}
                                </Text>
                                <Text>
                                    {data?.kriterium
                                        ? t.global.criteria[`criteria_${data?.kriterium}`]
                                        : data?.kriterium
                                    }
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.time_criteria}>
                        <Text>{t.offers.auction}</Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                maxWidth: "373px"
                            }}
                        >
                            <View>
                                <Text style={styles.gray_color}>
                                    {t.offers.auction}
                                </Text>
                                <Text>
                                    {(data?.auction)
                                        ? t.global.yes
                                        : t.global.no
                                    }
                                </Text>
                            </View>
                            <View>
                                <Text style={styles.gray_color}>
                                    {t.offers.auction_date}
                                </Text>
                                <Text>
                                    {(data?.datum_oddrzuvanje_aukcija && dayjs(data.datum_oddrzuvanje_aukcija).isValid())
                                        ? dayjs(data.datum_oddrzuvanje_aukcija).format("DD.MM.YYYY")
                                        : data?.datum_oddrzuvanje_aukcija
                                    } | {(data?.vreme_oddrzuvanje_aukcija_od && dayjs(data?.vreme_oddrzuvanje_aukcija_od, "HH:mm:ss").isValid())
                                            ? dayjs(data?.vreme_oddrzuvanje_aukcija_od, "HH:mm:ss").format("HH:mm")
                                            : data?.vreme_oddrzuvanje_aukcija_od
                                    } - {(data?.vreme_oddrzuvanje_aukcija_do && dayjs(data?.vreme_oddrzuvanje_aukcija_do, "HH:mm:ss").isValid())
                                            ? dayjs(data?.vreme_oddrzuvanje_aukcija_do, "HH:mm:ss").format("HH:mm")
                                            : data?.vreme_oddrzuvanje_aukcija_do
                                    }
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.time_criteria}>
                        <Text>{t.offers.administrative_info_title}</Text>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                maxWidth: "520px"
                            }}
                        >
                            <View
                                style={{
                                    maxWidth: "160px"
                                }}
                            >
                                <Text style={styles.gray_color}>
                                    {t.offers.offers_submit}
                                </Text>
                                <Text>
                                    {(data?.datum_za_dostavuvanje && dayjs(data?.datum_za_dostavuvanje).isValid())
                                        ? dayjs(data.datum_za_dostavuvanje).format("DD.MM.YYYY")
                                        : data?.datum_za_dostavuvanje
                                    } | {(data?.dostavuvanje_ponudi_vreme && dayjs(data?.dostavuvanje_ponudi_vreme, "HH:mm:ss").isValid())
                                            ? dayjs(data?.dostavuvanje_ponudi_vreme, "HH:mm:ss").format("HH:mm")
                                            : data?.dostavuvanje_ponudi_vreme
                                    }
                                </Text>
                            </View>
                            <View
                                style={{
                                    maxWidth: "160px"
                                }}
                            >
                                <Text style={styles.gray_color}>
                                    {t.offers.questions_dedline}
                                </Text>
                                <Text>
                                    {(data?.datum_za_prasanja && dayjs(data.datum_za_prasanja).isValid())
                                        ? dayjs(data.datum_za_prasanja).format("DD.MM.YYYY")
                                        : data?.datum_za_prasanja
                                    } | {(data?.vreme_za_prasanja && dayjs(data?.vreme_za_prasanja, "HH:mm:ss").isValid())
                                            ? dayjs(data?.vreme_za_prasanja, "HH:mm:ss").format("HH:mm")
                                            : data?.vreme_za_prasanja
                                    }
                                </Text>
                            </View>
                            <View
                                style={{
                                    maxWidth: "160px"
                                }}
                            >
                                <Text style={styles.gray_color}>
                                    {t.offers.answers_dedline}
                                </Text>
                                <Text>
                                    {(data?.datum_za_odgovori && dayjs(data.datum_za_odgovori).isValid())
                                        ? dayjs(data.datum_za_odgovori).format("DD.MM.YYYY")
                                        : data?.datum_za_odgovori
                                    } | {(data?.vreme_za_odgovori && dayjs(data?.vreme_za_prasanja, "HH:mm:ss").isValid())
                                            ? dayjs(data?.vreme_za_prasanja, "HH:mm:ss").format("HH:mm")
                                            : data?.vreme_za_prasanja
                                    }
                                </Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.pageNumber} fixed>
                        <Text
                            style={{
                                color: "#9F9F9F"
                            }}
                        >
                            {t.global.footer_rights}
                        </Text>
                        <Text
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber}/${totalPages}`
                            )}
                        />
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );

    return (
        <>
            <ModalComponent
                show={true}
                title={t.global.document_overview}
                component={pdf}
                hideactions
                onCancel={onCancelHandler}
                onAccept={() => {}}
            />
        </>
    );
};

export default OfferPreviewPDF;
