import axios from "axios";
import { LocalStorageEnum } from "../../enums/local-storage-keys";

let token = localStorage.getItem(LocalStorageEnum.UserToken);

const axiosInstance = axios.create({
	baseURL: 'https://api-tender.echo-dev.net/api/v1',
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')!.getAttribute('content')!,
		'Authorization': `Bearer ${token}`
	}
});

export default axiosInstance; 