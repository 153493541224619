import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber  } from "react-phone-number-input";
import { useSelector } from "react-redux";
import TimePicker from "react-bootstrap-time-picker";
import * as FileSaver from "file-saver";

import AdvertisementForm from "./AdvertisementForm/AdvertisementForm";
import Switch from "../../../../../components/UI/Switch/Switch";
import RadioButton from "../../../../../components/UI/RadioButton/RadioButton";
import Datepicker from "../../../../../components/UI/Datepicker/Datepicker";
import { IBaseField } from "../../../../../models/field-validation.model";
import { RootState } from "../../../../../store";
import { httpGet, httpPost } from "../../../../../services/http";
import GlobalLoader from "../../../../../components/UI/GlobalLoader/GlobalLoader";
import { showNotification, hideNotifications } from "../../../../../notification_functions/notifications_functions";

import ClockIcon from "../../../../../assets/icons/Clock-icon.svg";

import classes from "./BaseInfo.module.scss";

const BaseInfo = () => {

    const {
        REACT_APP_MAX_LEGAL_DOCUMENTS,
        REACT_APP_MAX_ADDITIONAL_DOCUMENTS,
        REACT_APP_FILES_TYPE
    }:any = process.env;

    const user = useSelector((state: RootState) => state.auth.user.user);
    const isAnon = useSelector(
        (state: RootState) => state.sidebar.anonymousCreate
    );

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "create_ad"
        ];
    });

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "global"
        ];
    });

    const [categories, setCategories] = useState([{
        id: '',
        name: '',
    }]);

    const [contactPerson, setContactPerson] = useState({
        name: `${user?.first_name} ${user?.last_name}`,
        phone: user?.cell_phone_number,
        email: user?.company_email
    });

    const [errors, setErrors]:any = useState({
        products: [{
            title: "",
            category: "",
            quantity: "",
            description: ""
        }]
    });

    const [adsFormValues, setAdsFormValues]:any = useState([{
        title: "",
        category: "",
        quantity: "",
        description: "",
        images: [],
    }]);

    const fetchCategories = async() => {
        try {
            const response = await httpGet<any>('/industry/get?XDEBUG_SESSION_START=phpstorm');
            setCategories(response.data.data);
        } catch (error) {
          console.error(error);
        };
    };

    useEffect(() => {
        fetchCategories();
        return () => hideNotifications();
    }, []);

    const addMoreAdvertisements = async () => {
        const products = adsFormValues;
        const product = products[products.length - 1];
        await setAdsFormValues([
            ...products,
            {
                title: product.title,
                category: product.category,
                quantity: "",
                description: "",
                images: []
            }
        ]);        
        
        await setErrors({...errors, products: [
            ...errors.products,
            {
                title: "",
                category: "",
                quantity: "",
                description: ""
            }
        ]});
    }

    const RemoveAdForm = (index) => {
        if (index !== undefined) {
            const adsForm = [...adsFormValues];
            adsForm.splice(index, 1);
            setAdsFormValues(adsForm);
            const newErrors = {...errors};
            newErrors.products.splice(index, 1);
            setErrors(newErrors);
        }
    }

    const [pravniDokumenti, setPravniDokumenti] = useState<{ file_name: string; file_url: string | ArrayBuffer | null; }[]>([]);

    const [dopolnitelniDokumenti, setDopolnitelniDokumenti] = useState<{ file_name: string; file_url: string | ArrayBuffer | null; }[]>([]);

    const [datumIsporaka, setDatumIsporaka] = useState<IBaseField<Date | null>>({
        value: null,
        name: "datum_isporaka",
    });

    const [datumOddrzuvanjeAukcija, setDatumOddrzuvanjeAukcija] = useState<IBaseField<Date | null>>({
        value: null,
        name: "datum_aukcija",
    });

    const [datumZaDostavuvanje, setDatumZaDostavuvanje] = useState<IBaseField<Date | null>>({
        value: null,
        name: "datum_za_dostavuvanje",
    });

    const [datumZaPrasanja, setDatumZaPrasanja] = useState<IBaseField<Date | null>>({
        value: null,
        name: "datum_za_prasanja",
    });

    const [datumZaOdgovori, setDatumZaOdgovori] = useState<IBaseField<Date | null>>({
        value: null,
        name: "datum_za_odgovori",
    });
    
    const [doVremeOddrzuvanjeAukcija, setDoVremeOddrzuvanjeAukcija] = useState<IBaseField<String | null>>({
        value: null,
        name: "do_vreme_oddrzuvanje_aukcija",
    });

    const [auctionCriteria, setAuctionCriteria] = useState<IBaseField<Number | null>>({
        value: null,
        name: "criteria",
    });

    const [adAuction, setAdAuction] = useState<IBaseField<boolean | undefined>>({
        value: false,
        name: "ad_auction",
    });

    const onSetAdAuction = (auction) => {
        setAdAuction((state) => {
            return {
                ...state,
                value: auction,
            };
        });
        const newErrors = {...errors};
        if (auction === false) {
            setDatumOddrzuvanjeAukcija((state) => {
                return {
                    ...state,
                    value: null,
                };
            });
            setOdVremeOddrzuvanjeAukcija((state) => {
                return {
                    ...state,
                    value: null,
                };
            });
            setDoVremeOddrzuvanjeAukcija((state) => {
                return {
                    ...state,
                    value: null,
                };
            });
            delete newErrors.datum_oddrzuvanje_aukcija;
            delete newErrors.od_vreme_oddrzuvanje_aukcija;
            delete newErrors.do_vreme_oddrzuvanje_aukcija;
            setErrors(newErrors);
        } else {
            setErrors((state) => {
                return {
                    ...state,
                    datum_oddrzuvanje_aukcija: translations.input_required,
                    od_vreme_oddrzuvanje_aukcija: translations.input_required,
                    do_vreme_oddrzuvanje_aukcija: translations.input_required
                }
            });
        }
    };

    const onDoVremeOddrzuvanjeAukcija = (time) => {
        let formattedTime = formatTime(time);
        setDoVremeOddrzuvanjeAukcija((state) => {
            return {
                ...state,
                value: formattedTime,
            };
        });
        setErrors({
            ...errors,
            do_vreme_oddrzuvanje_aukcija: ""
        });
    };

    const [odVremeOddrzuvanjeAukcija, setOdVremeOddrzuvanjeAukcija] = useState<IBaseField<String | null>>({
        value: null,
        name: "od_vreme_oddrzuvanje_aukcija",
    });

    const onOdVremeOddrzuvanjeAukcija = (time) => {
        let formattedTime = formatTime(time);
        setOdVremeOddrzuvanjeAukcija((state) => {
            return {
                ...state,
                value: formattedTime,
            };
        });
        setErrors({
            ...errors,
            od_vreme_oddrzuvanje_aukcija: ""
        });
    };

    const [dostavuvanjePonudiVreme, setDostavuvanjePonudiVreme] = useState<IBaseField<String | null>>({
        value: null,
        name: "vreme_dostavuvanje_ponuda",
    });

    const onDostavuvanjePonudiVreme = (time) => {
        let formattedTime = formatTime(time);
        setDostavuvanjePonudiVreme((state) => {
            return {
                ...state,
                value: formattedTime,
            };
        });
        setErrors({
            ...errors,
            dostavuvanje_ponudi_vreme: ""
        });
    };

    const [rokPrasanjaVreme, setRokPrasanjaVreme] = useState<IBaseField<String | null>>({
        value: null,
        name: "vreme_prasanja",
    });

    const onRokPrasanjaVreme = (time) => {
        let formattedTime = formatTime(time);
        setRokPrasanjaVreme((state) => {
            return {
                ...state,
                value: formattedTime,
            };
        });
    };

    const [rokOdgovorPrasanjaVreme, setRokOdgovorPrasanjaVreme] = useState<IBaseField<String | null>>({
        value: null,
        name: "vreme_odgovor",
    });

    const onRokOdgovorPrasanjaVreme = (time) => {
        let formattedTime = formatTime(time);
        setRokOdgovorPrasanjaVreme((state) => {
            return {
                ...state,
                value: formattedTime,
            };
        });

    };

    const onDatumZaOdgovoriChange = (date) => {
        setDatumZaOdgovori((state) => {
            return {
                ...state,
                value: date,
            };
        });
    };

    const onDatumIsporakaChange = (date) => {
        setDatumIsporaka((state) => {
            return {
                ...state,
                value: date,
            };
        });
    };

    const onDatumOddrzuvanjeAukcija = (date) => {
        setDatumOddrzuvanjeAukcija((state) => {
            return {
                ...state,
                value: date,
            };
        });
        if (date) {
            setErrors({
                ...errors,
                datum_oddrzuvanje_aukcija: ""
            });
        } else {
            setErrors({
                ...errors,
                datum_oddrzuvanje_aukcija: translations.input_required
            });
        }
    };

    const onDatumZaDostavuvanje = (date) => {
        setDatumZaDostavuvanje((state) => {
            return {
                ...state,
                value: date,
            };
        });
        if (date) {
            setErrors({
                ...errors,
                datum_za_dostavuvanje: ""
            });
        } else {
            setErrors({
                ...errors,
                datum_za_dostavuvanje: translations.input_required
            });
        }
    };

    const onDatumZaPrasanja = (date) => {
        setDatumZaPrasanja((state) => {
            return {
                ...state,
                value: date,
            };
        });
    };

    const onCriteriaChange = (criteria) => {
        setAuctionCriteria((state) => {
            return {
                ...state,
                value: criteria,
            };
        });
    };

    const onPersonalInfoChange = (e) => {
        let {name, value} = e.target;
        
        if (value === undefined) {
            value = "";
        }

        const newErrors = {...errors};
        setContactPerson({
            ...contactPerson,
            [name]: value
        });
        validatePersonalInfoField(name, value, newErrors);
    }

    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);
        return `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    }

    const validatePersonalInfoField = (name, value, newErrors) => {
        switch(name) {
            case "name":
                newErrors[name] = (value && (value.length > 0)) ? "" : translations.input_required;
                break;
            case "email":
                if (value && (value.length > 0)) {
                    const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                    newErrors[name] = (emailValid) ? "" : translations.invalid_email;
                } else {
                    newErrors[name] = translations.input_required;
                }
                break;
            case "phone":
                newErrors[name] = (value && (value.length > 0)) ? (isValidPhoneNumber(value)) ? "" : translations.invalid_phone_number : translations.input_required;
                break;
            default:
                break;
        }

        setErrors(newErrors);
        return newErrors;
    }

    const confirmAttachedImages = (form_index, images) => {
        const newFormValues:any = [...adsFormValues];
        if (newFormValues[form_index]) {
            newFormValues[form_index]['images'] = images;
            setAdsFormValues(newFormValues);
        }
    }

    const onPravniDokumentiChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
      
        reader.readAsDataURL(file);

        reader.onload = (e) => {
            if (e.target) {
                let filename = file.name;
                let dataFile = e.target.result;
                setPravniDokumenti(prevState => [
                    ...prevState,
                    {
                        file_name: filename,
                        file_url: dataFile,
                        existing: false
                    }
                ]);
                setErrors((state) => {
                    return {
                        ...state,
                        pravniDokumenti: ""
                    }
                });
            }
        };
    };

    const onPravniDokumentiRemove = (index) => {
        if (pravniDokumenti[index]) {
            setPravniDokumenti(prevValues => {
                const newValues = [...prevValues];
                newValues.splice(index, 1);
                if (newValues.length === 0) {
                    setErrors((state) => {
                        return {
                            ...state,
                            pravniDokumenti: translations.input_required
                        }
                    });
                }
                return newValues;
            });
        }
    }

    const onDopolnitelniDokumentiChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
      
        reader.readAsDataURL(file);

        reader.onload = (e) => {
            if (e.target) {
                let filename = file.name;
                let dataFile = e.target.result;
                setDopolnitelniDokumenti(prevState => [
                    ...prevState,
                    {
                        file_name: filename,
                        file_url: dataFile,
                        existing: false
                    }
                ]);
            }
        };
    };

    const onDopolnitelniDokumentiRemove = (index) => {
        if (dopolnitelniDokumenti[index]) {
            setDopolnitelniDokumenti(prevValues => {
                const newValues = [...prevValues];
                newValues.splice(index, 1);
                return newValues;
            });
        }
    }

    const removeAdImage = (form_index, index) => {
        const newFormValues = [...adsFormValues];
        if (newFormValues[form_index]) {
            setAdsFormValues(prevValues => {
                const newValues = [...prevValues];
                newValues[form_index].images.splice(index, 1);
                return newValues;
            });
        }
    }

    const onAdsFormValueChange = (e, form_index) => {
        const newErrors = {...errors};
        const { name, value, required } = e.target;
        const newFormValues = [...adsFormValues];
        if (newFormValues[form_index]) {
            if (name === "description") {
                if ((value.length <= 1000)) {
                    newFormValues[form_index][name] = value;
                }
            } else {
                newFormValues[form_index][name] = value;
            }
        } else {
            newFormValues.push({
                [name]: value
            });
        }
        if (required) {
            if ((typeof value === "string") && (value.length === 0)) {
                newErrors.products[form_index][name] = translations.input_required;
            } else if ((name === "quantity") && ((value === 0) || (value === ""))) {
                if (value === "") {
                    newErrors.products[form_index][name] = translations.input_required;
                } else if (value === 0) {
                    newErrors.products[form_index][name] = translations.error_value_grater_then_0;
                }
            } else {
                newErrors.products[form_index][name] = "";
            }
            setErrors(newErrors);
        }
        setAdsFormValues(newFormValues);
    }

    const downloadFile = (file, file_name) => {
        if (file && file_name) {
            FileSaver.saveAs(file, file_name);
        }
    };

    const checkRequiredFields = (data) => {

        let newErrors = {...errors};

        function checkAuctionFileds(auction, name, value) {
            if (auction === true) {
                if ((value === null) || (value.length === 0)) {
                    newErrors[name] = translations.input_required;
                } else {
                    newErrors[name] = "";
                }
            } else {
                newErrors[name] = "";
            }
        }

        function checkFieldsValues(name, value) {
            if (value && value.length > 0) {
                newErrors[name] = "";
            } else {
                newErrors[name] = translations.input_required;
            }
        }

        return new Promise((resolve) => {
            if (data) {
                Object.keys(data).forEach((dataKey) => {
                    switch (dataKey) {
                        case "contactPerson":
                            if (data[dataKey]) {
                                Object.keys(data[dataKey]).forEach((key) => {
                                    newErrors = validatePersonalInfoField(key, data.contactPerson[key], newErrors);
                                });
                            }
                            break;
                        case "adsFormValues":
                            if (data[dataKey] && (data[dataKey].length > 0)) {
                                data[dataKey].forEach((adsForm, form_index) => {
                                    Object.keys(adsForm).forEach((name) => {
                                        const value = adsForm[name];
                                        if ((name === "title") || (name === "category") || (name === "description")) {
                                            if (value && (value.length > 0)) {
                                                newErrors.products[form_index][name] = "";
                                            } else {
                                                newErrors.products[form_index][name] = translations.input_required;
                                            }
                                        } else if (name === "quantity") {
                                            if (value === "") {
                                                newErrors.products[form_index][name] = translations.input_required;
                                            } else if (value === 0) {
                                                newErrors.products[form_index][name] = translations.error_value_grater_then_0;
                                            }
                                        }
                                    });
                                });
                            }
                            break;
                        case "datum_oddrzuvanje_aukcija":
                            checkAuctionFileds(data.aukcija, dataKey, data[dataKey]);
                            break;
                        case "od_vreme_oddrzuvanje_aukcija":
                            checkAuctionFileds(data.aukcija, dataKey, data[dataKey]);
                            break;
                        case "do_vreme_oddrzuvanje_aukcija":
                            checkAuctionFileds(data.aukcija, dataKey, data[dataKey]);
                            break;
                        case "datum_za_dostavuvanje":
                            checkFieldsValues(dataKey, data[dataKey]);
                            break;
                        case "dostavuvanje_ponudi_vreme":
                            checkFieldsValues(dataKey, data[dataKey]);
                            break;
                        case "pravniDokumenti":
                            checkFieldsValues(dataKey, data[dataKey]);
                            break;
                        default:
                            break;
                    }
                });
            }
            setErrors(newErrors);
            resolve(newErrors);
        });
    }

    const checkForErrors = (new_errors) => {
        let has_error = false;
        function errors_check(newErrors) {
            Object.keys(newErrors).forEach((key) => {
                if (typeof newErrors[key] === "object" ) {
                    errors_check(newErrors[key]);
                } else {
                    if (newErrors[key].length > 0) {
                        has_error = true;
                    }
                }
            });
        }
        errors_check(new_errors);
        return has_error;
    }

    const saveData = () => {

        const postData = {
            contactPerson,
            adsFormValues: adsFormValues.map((prod) => {
                if (prod?.images && (prod?.images?.length > 0)) {
                    prod.images = prod.images.map((img) => {
                        if (img.hasOwnProperty("uploaded")) {
                            delete img.uploaded;
                        }
                        return img;
                    });
                }
                return prod;
            }),
            datum_isporaka: datumIsporaka.value,
            datum_oddrzuvanje_aukcija: datumOddrzuvanjeAukcija.value,
            od_vreme_oddrzuvanje_aukcija: odVremeOddrzuvanjeAukcija.value,
            do_vreme_oddrzuvanje_aukcija: doVremeOddrzuvanjeAukcija.value,
            datum_za_dostavuvanje: datumZaDostavuvanje.value,
            dostavuvanje_ponudi_vreme: dostavuvanjePonudiVreme.value,
            datum_za_prasanja: datumZaPrasanja.value,
            vreme_za_prasanja: rokPrasanjaVreme.value,
            datum_za_odgovori: datumZaOdgovori.value,
            vreme_za_odgovori: rokOdgovorPrasanjaVreme.value,
            kriterium: auctionCriteria.value,
            aukcija: adAuction.value,
            anonimno: isAnon,
            pravniDokumenti,
            dopolnitelniDokumenti
        };
        
        checkRequiredFields(postData)
        .then(async (newErrors) => {
            const hasErrors = checkForErrors(newErrors);
            if (hasErrors) {
                showNotification(translations.invalid_fields, "danger");
            } else {
                setIsLoading(true);
                try {
                    const response = await httpPost<any, any>('/tender/create?XDEBUG_SESSION_START=phpstorm', postData);
                    if (response.message === "Success") {
                        navigate("/my-advertisements");
                    } else {
                        setIsLoading(false);
                        showNotification(t.error_message, "danger");
                    }
                } catch (error) {
                    setIsLoading(false);
                    showNotification(t.error_message, "danger");
                    console.error(error);
                };
            }
        });
    }
    
    return (
        <React.Fragment>
            <div className={classes.contact_person_wrapper}>
                <div className={classes.label_title}>{translations.contact_person}</div>
                <form className={classes.contact_person_form}>
                    <div className={classes.col_one}>
                        <div
                            className={`${classes.input_wrapper} ${(errors?.name?.length > 0) ? classes.has_error : ""}`}
                        >
                            <label>{translations.name_surname}</label>
                            <input
                                type="text"
                                name="name"
                                value={contactPerson.name}
                                required
                                onChange={(e) => onPersonalInfoChange(e)}
                            />
                            <div className={classes.error}>{errors?.name}</div>
                        </div>
                        <div
                            className={`${classes.input_wrapper} ${(errors?.phone?.length > 0) ? classes.has_error : ""}`}
                        >
                            <label>{translations.phone_number}</label>
                            <PhoneInput
                                name="phone"
                                required
                                value={contactPerson.phone}
                                onChange={(value) => onPersonalInfoChange({target: {value, name: "phone", required: true}})}
                            />
                            <div className={classes.error}>{errors?.phone}</div>
                        </div>
                    </div>
                    <div className={classes.col_two}>
                        <div
                            className={`${classes.input_wrapper} ${(errors?.email?.length > 0) ? classes.has_error : ""}`}
                        >
                            <label>{translations.email}</label>
                            <input
                                type="email"
                                name="email"
                                required
                                value={contactPerson.email}
                                onChange={(e) => onPersonalInfoChange(e)}
                            />
                            <div className={classes.error}>{errors?.email}</div>
                        </div>
                    </div>
                </form>
            </div>

            <div className={classes.ads_wrapper}>
                <div className={classes.label_title}>{translations.advertisement}</div>
                <div id="ads_form_wrapper">
                    {adsFormValues.map((values, index) => {
                        return(
                            <AdvertisementForm
                                addMore={addMoreAdvertisements}
                                key={index}
                                formIndex={index}
                                removeForm={RemoveAdForm}
                                onChangeHandler={onAdsFormValueChange}
                                values={values}
                                errors={errors.products[index]}
                                forms={adsFormValues.length}
                                onImageRemove={removeAdImage}
                                categories={categories}
                                confirmAttachments={confirmAttachedImages}
                            />
                        )
                    })}
                </div>
            </div>

            <div className={classes.ad_time_and_criteria_wrapper}>
                <div className={classes.label_title}>{translations.date_and_criteria_title}</div>
                <form className={classes.form_wrapper}>
                    <div className={classes.input_wrapper}>
                        <label>{translations.delivery_date_title}</label>
                        <Datepicker
                            field={datumIsporaka}
                            onInputChange={onDatumIsporakaChange}
                            preventPreviousDates={true}
                            customClasses={classes.data_picker_wrapper}
                            required={true}
                            containerStyle={{
                                maxWidth: "360px"
                            }}
                        />
                    </div>
                    <div className={classes.input_wrapper}>
                        <label>{translations.criteria_title}</label>
                        <div className={classes.radio_buttons_wrapper}>
                            <RadioButton
                                label={translations.no_criteria}
                                name="kriterium"
                                value="0"
                                customClasses={classes.criteria_wrapper}
                                onChange={() => onCriteriaChange(0)}
                                required={true}
                            />
                            <RadioButton
                                label={translations.lowest_price}
                                name="kriterium"
                                value="1"
                                customClasses={classes.criteria_wrapper}
                                onChange={() => onCriteriaChange(1)}
                                required={true}
                            />
                            <RadioButton
                                label={translations.quality}
                                name="kriterium"
                                value="2"
                                customClasses={classes.criteria_wrapper}
                                onChange={() => onCriteriaChange(2)}
                                required={true}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className={classes.auction_wrapper}>
                <div className={classes.label_title}>{translations.auction_title}</div>
                <div className={classes.inputs_wrapper}>
                    <div className={classes.switch_wrapper}>
                        <label>{translations.auction_title}</label>
                        <div className={classes.switch}>
                            <label>{t.no}</label>
                            <Switch
                                label=""
                                name="on_auction"
                                onChange={(e) => onSetAdAuction(e)}
                                customClasses={classes.custom_switch}
                                checked={adAuction.value}
                            />
                            <label className={classes.active}>{t.yes}</label>
                        </div>
                    </div>
                    <div className={classes.auction_date}>
                        <div className={`${classes.date_wrapper} ${(errors?.datum_oddrzuvanje_aukcija?.length > 0) ? classes.has_error : ""}`}>
                            <label>{translations.auction_date}</label>
                            <Datepicker
                                field={datumOddrzuvanjeAukcija}
                                onInputChange={onDatumOddrzuvanjeAukcija}
                                preventPreviousDates={true}
                                customClasses={classes.data_picker_wrapper}
                                placeholder=""
                                required={true}
                                disabled={!adAuction.value}
                                containerClass={!adAuction.value ? classes.auctionDateDisabled : ""}
                                hasError={errors?.datum_oddrzuvanje_aukcija?.length > 0}
                            />
                            <div className={classes.error}>{errors?.datum_oddrzuvanje_aukcija}</div>
                        </div>
                        <div className={`${classes.time_from_wrapper} ${(errors?.od_vreme_oddrzuvanje_aukcija?.length > 0) ? classes.has_error : ""}`}>
                            <label>{t.from}:</label>
                            <div className={classes.input_wrapper}>
                                <TimePicker 
                                    step={30} 
                                    start="00:00"
                                    end="23:30"
                                    value={odVremeOddrzuvanjeAukcija.value}
                                    onChange={onOdVremeOddrzuvanjeAukcija}
                                    format={24}
                                    disabled={!adAuction.value}
                                />
                                <img src={ClockIcon} alt="Clock" />
                            </div>
                            <div className={classes.error}>{errors?.od_vreme_oddrzuvanje_aukcija}</div>
                        </div>
                        <div className={`${classes.time_to_wrapper} ${(errors?.do_vreme_oddrzuvanje_aukcija?.length > 0) ? classes.has_error : ""}`}>
                            <label>{t.to}:</label>
                            <div className={classes.input_wrapper}>
                                <TimePicker 
                                    step={30} 
                                    start="00:00"
                                    end="23:30"
                                    value={doVremeOddrzuvanjeAukcija.value}
                                    onChange={onDoVremeOddrzuvanjeAukcija}
                                    format={24}
                                    disabled={!adAuction.value}
                                />
                                <img src={ClockIcon} alt="Clock" />
                            </div>
                            <div className={classes.error}>{errors?.do_vreme_oddrzuvanje_aukcija}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.administrative_info_wrapper}>
                <div className={classes.label_title}>{translations.administrative_info_title}</div>
                <div className={classes.administrative_info}>
                    <div className={classes.row_one}>
                        <label>{translations.submission_last_date}</label>
                        <div className={classes.inputs_wrapper}>
                            <div className={`${classes.input_wrapper} ${(errors?.datum_za_dostavuvanje?.length > 0) ? classes.has_error : ""}`}>
                                <Datepicker
                                    field={datumZaDostavuvanje}
                                    onInputChange={onDatumZaDostavuvanje}
                                    preventPreviousDates={true}
                                    customClasses={classes.data_picker_wrapper}
                                    required={true}
                                    hasError={(errors?.datum_za_dostavuvanje?.length > 0)}
                                />
                                <div className={classes.error}>{errors?.datum_za_dostavuvanje}</div>
                            </div>
                            <div className={`${classes.input_wrapper} ${(errors?.dostavuvanje_ponudi_vreme?.length > 0) ? classes.has_error : ""}`}>
                                <TimePicker 
                                    step={30} 
                                    start="00:00"
                                    end="23:30"
                                    value={dostavuvanjePonudiVreme.value}
                                    onChange={onDostavuvanjePonudiVreme}
                                    format={24}
                                />
                                <img src={ClockIcon} alt="Clock" />
                                <div className={classes.error}>{errors?.dostavuvanje_ponudi_vreme}</div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.row_two}>
                        <div className={classes.inputs_wrapper}>
                            <label>{translations.deadline_for_questions}</label>
                            <div className={classes.inputs_container}>
                                <div className={classes.input_wrapper}>
                                    <Datepicker
                                        field={datumZaPrasanja}
                                        onInputChange={onDatumZaPrasanja}
                                        preventPreviousDates={true}
                                        customClasses={classes.data_picker_wrapper}
                                        required={true}
                                    />
                                </div>
                                <div className={classes.input_wrapper}>
                                    <TimePicker 
                                        step={30} 
                                        start="00:00"
                                        end="23:30"
                                        value={rokPrasanjaVreme.value}
                                        onChange={onRokPrasanjaVreme}
                                        format={24}
                                    />
                                    <img src={ClockIcon} alt="Clock" />
                                </div>
                            </div>
                        </div>
                        <div className={classes.inputs_wrapper}>
                            <label>{translations.deadline_for_answers}</label>
                            <div className={classes.inputs_container}>
                                <div className={classes.input_wrapper}>
                                    <Datepicker
                                        field={datumZaOdgovori}
                                        onInputChange={onDatumZaOdgovoriChange}
                                        preventPreviousDates={true}
                                        customClasses={classes.data_picker_wrapper}
                                        required={true}
                                    />
                                </div>
                                <div className={classes.input_wrapper}>
                                    <TimePicker 
                                        step={30} 
                                        start="00:00"
                                        end="23:30"
                                        value={rokOdgovorPrasanjaVreme.value}
                                        onChange={onRokOdgovorPrasanjaVreme}
                                        format={24}
                                    />
                                    <img src={ClockIcon} alt="Clock" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.technical_info_wrapper}>
                <div className={classes.label_title}>
                    {translations.technical_info_title}
                </div>
                <div className={classes.technical_info}>
                    <label>{translations.registered_activity}</label>
                    <div className={classes.uploaded_docs}>
                        {pravniDokumenti.map((value, i) => {
                            return (
                                <div className={classes.uploaded_doc} key={i}>
                                    <div className={classes.document_icon}></div>
                                    <div
                                        className={classes.file_name}
                                        onClick={() => downloadFile(value.file_url, value.file_name)}
                                    >
                                        {value.file_name}
                                    </div>
                                    <div className={classes.close_icon} onClick={() => onPravniDokumentiRemove(i)}></div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${classes.upload_doc} ${(errors?.pravniDokumenti?.length > 0) ? classes.has_error : ""}`}>
                        {(pravniDokumenti?.length < REACT_APP_MAX_LEGAL_DOCUMENTS) && (
                            <>
                                <div
                                    className={classes.attach_wrapper}
                                    onClick={() => {
                                        document.getElementById("add_document")?.click();
                                    }}
                                >
                                    <div className={classes.icon}></div>
                                    <div className={classes.text}>
                                        {translations.add_document}
                                    </div>
                                </div>
                                <div className={classes.error}>{errors?.pravniDokumenti}</div>
                            </>
                        )}
                        <input
                            type="file"
                            accept={REACT_APP_FILES_TYPE}
                            style={{display: "none"}}
                            onChange={(e) => onPravniDokumentiChange(e)}
                            name="add_document"
                            id="add_document"
                        />
                    </div>
                </div>
            </div>

            <div className={classes.additional_documents_wrapper}>
                <div className={classes.label_title}>
                    {translations.additional_docs}&nbsp;
                    <span>{translations.max_docs_attach}</span>
                </div>
                <div className={classes.uploaded_docs}>
                    {dopolnitelniDokumenti.map((value, i) => {
                        return (
                            <div className={classes.uploaded_doc} key={i}>
                                <div className={classes.document_icon}></div>
                                <div
                                    className={classes.file_name}
                                    onClick={() => downloadFile(value.file_url, value.file_name)}
                                >
                                    {value.file_name}
                                </div>
                                <div className={classes.close_icon} onClick={() => onDopolnitelniDokumentiRemove(i)}></div>
                            </div>
                        )
                    })}
                </div>
                <div className={classes.button_wrapper}>
                    {(dopolnitelniDokumenti?.length < REACT_APP_MAX_ADDITIONAL_DOCUMENTS) && (
                        <div
                            className={classes.add_additional_docs_btn}
                            onClick={() => {
                                document.getElementById("additional_docs")?.click();
                            }}
                        >
                            <div className={classes.icon}></div>
                            <div className={classes.text}>
                                {translations.add_new_docs}
                            </div>
                        </div>
                    )}
                    <input
                        type="file"
                        name="additional_docs"
                        id="additional_docs"
                        onChange={(e) => onDopolnitelniDokumentiChange(e)}
                        accept={REACT_APP_FILES_TYPE}
                        style={{display: "none"}}
                    />
                </div>
            </div>

            <div className={classes.buttons_wrapper}>
                <button
                    type="button"
                    className={classes.cancel_btn}
                    onClick={() => navigate(-1)}
                >
                    {t.cancel}
                </button>
                <button
                    type="button"
                    className={classes.save_btn}
                    onClick={saveData}
                >
                    {t.save}
                </button>
            </div>
            <GlobalLoader show={isLoading}/>
        </React.Fragment>
    );
};

export default BaseInfo;
