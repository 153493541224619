import React, { useEffect, useState } from "react";
import classes from "./Datepicker.module.scss";

import CalendarIcon from "./../../../assets/icons/Calendar.svg";
import Delete from "./../../../assets/icons/Close.svg";

import { IDatePicker } from "./model/datepicker.model";
import { OverlayTrigger, Popover } from "react-bootstrap";
import MonthYear from "./Components/MonthYear/MonthYear";
import Actions from "./Components/Actions/Actions";
import Calendar from "./Components/Calendar/Calendar";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const Datepicker = (props: IDatePicker) => {
    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "components"
            ]
    );

    // https://blog.logrocket.com/react-custom-datepicker-step-by-step/
    // https://codesandbox.io/s/monthly-calendar-ur29q?file=/src/index.js:4213-4843
    const [date, setDate] = useState(props.field.value ?? new Date());
    const parsedDate = dayjs(date).format("YYYY-MM-DD");
    const [parsedViewDate, setParsedViewDate] = useState(props?.field?.value
        ? dayjs(props.field.value).format("DD.MM.YYYY")
        : props.placeholder
    );

    useEffect(() => {
        setParsedViewDate(props?.field?.value
            ? dayjs(props.field.value).format("DD.MM.YYYY")
            : props.placeholder
        );

        const new_date = props.field.value || new Date();
        const newDate:any = dayjs(new_date).format("YYYY-MM-DD");
        setDate(new_date);

        if (newDate) {
            setMonth((state) => {
                return newDate.split("-")[1];
            });
    
            setYear((state) => {
                return newDate.split("-")[0];
            });
        }
    }, [props?.field?.value]);

    const [month, setMonth] = useState(parsedDate.split("-")[1]);
    const [year, setYear] = useState(parsedDate.split("-")[0]);

    const onMonthSelect = (month: number) => {
        setMonth((state) => {
            return `${month}`;
        });
    };

    const onYearSelect = (year: number) => {
        setYear((state) => {
            return `${year}`;
        });
    };

    const onDateSelectedHandler = (date: string) => {
        setDate((state) => {
            return new Date(date);
        });

        setMonth((state) => {
            return date.split("-")[1];
        });

        setYear((state) => {
            return date.split("-")[0];
        });
    };

    const onDateConfirmHandler = () => {
        // setViewDate((state) => {
        //     return new Date(parsedDate);
        // });

        props.onInputChange(parsedDate);
        document.body.click();
    };

    const onDateCancelHandler = () => {
        if (props.field.value) {
            setDate((state) => {
                return new Date(props.field.value!);
            });
        }

        setTouched();

        document.body.click();
    };

    const datepickerPopover = (
        <div className={classes.CalendarContainer}>
            <MonthYear
                month={month}
                year={year}
                onMonthSelect={onMonthSelect}
                onYearSelect={onYearSelect}
            />
            <Calendar
                month={month}
                year={year}
                selectedDate={parsedDate}
                preventPreviousDates={props.preventPreviousDates}
                onDateSelected={onDateSelectedHandler}
            />
            <Actions
                onCancel={onDateCancelHandler}
                onConfirm={onDateConfirmHandler}
            />
        </div>
    );

    const popover = (
        <Popover
            className="custom-popover-container"
            id="popover-positioned-left"
            // arrowProps={{
            //     style: { marginRight: "30px" },
            // }}
        >
            {datepickerPopover}
        </Popover>
    );

    const onClearHandler = (
        event: React.MouseEvent<HTMLImageElement, MouseEvent>
    ) => {
        event.preventDefault();
        event.stopPropagation();

        const newDate = dayjs().format("YYYY-MM-DD");

        props.onInputChange("");
        setParsedViewDate(props?.placeholder || "");
        setDate(new Date());

        setMonth((state) => {
            return newDate.split("-")[1];
        });

        setYear((state) => {
            return newDate.split("-")[0];
        });
        document.body.click();
    };

    const setTouched = () => {
        props.markAsTouched?.();
    };

    return (
        <React.Fragment>
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                show={props?.disabled ? false : undefined}
                defaultShow={false}
                rootClose
                overlay={popover}
                onExited={onDateCancelHandler}
            >
                <div
                    className={`${classes.MainContainer} ${props.customClasses ? props.customClasses : ""}`}
                    style={props.style}
                    // onClick={setTouched}
                >
                    <div className={classes.Label}>
                        {props.label}{" "}
                        {!props.hideAsterisk && (
                            <span className={classes.RequiredIndicator}>
                                {props.field?.validators?.required && "*"}
                            </span>
                        )}
                    </div>

                    <div
                        className={`${classes.Container} ${
                            props.field?.touched && props.field?.errorMessage
                                ? classes.Error
                                : ""
                        } ${props?.containerClass || ""}
                        ${
                            props?.hasError ? classes.hasError : ""
                        }`}
                        style={props.containerStyle}
                    >
                        {(props?.iconPosition && (props.iconPosition === "left")) && (
                            <div>
                                <img src={CalendarIcon} alt="Calendar" style={props?.iconStyle || {}} />
                            </div>
                        )}
                        <input
                            type="text"
                            className={`${classes.Label} ${
                                !props.field.value && props.placeholder
                                    ? classes.Placeholder
                                    : ""
                            }`}
                            value={parsedViewDate}
                            required={props.required}
                            disabled={true}
                            onChange={() => {}}
                        />
                        <div>
                            {(!props?.iconPosition || (props.iconPosition !== "left")) && (
                                <img src={CalendarIcon} alt="Calendar" />
                            )}
                            {(props.field.value) && (
                                <img
                                    src={Delete}
                                    alt="Delete"
                                    style={{ marginLeft: "0.5rem" }}
                                    onClick={(event) => onClearHandler(event)}
                                />
                            )}
                            {props?.rightIcon && (
                                props.rightIcon
                            )}
                        </div>
                    </div>
                    {props.field?.touched && props.field?.errorMessage && (
                        <small
                            id="emailHelp"
                            className={`${classes.ErrorText} form-text text-muted`}
                        >
                            {translations[props.field?.errorMessage]}
                        </small>
                    )}
                </div>
            </OverlayTrigger>
        </React.Fragment>
    );
};

export default Datepicker;
