import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import dayjs from "dayjs";

import Page from "../../components/Layout/Page/Page";
import Filters from "./Components/Filters/Filters";
import OfferHeader from "./Components/Header/OfferHeader";
import { RootState } from "../../store";
import ConfirmModal from "./Components/Modals/ConfirmModal/ConfirmModal";
import ConfirmModalCompany from "./Components/Modals/ConfirmModalCompany/ConfirmModalCompany";
import { httpGet, httpPost } from "../../services/http";
import { hideNotifications, showNotification } from "../../notification_functions/notifications_functions";

import {
    IConfirmModal,
    IPagination,
    ICompanyModal,
    ISortingParameters,
    IProductData
} from "./model";
import { ISearchParameters } from "./Components/Filters/model";

import NoImageIcon from "../../assets/icons/No-image-icon.svg";

import classes from "./OffersPreview.module.scss";

const productDataDefault = {
    product_images: [{
        file_url: ""
    }]
}

const CustomActionCol = (props) => {

    const { row, setShowConfirmModal } = props;

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "my_adds_offers"
        ];
    });

    if (row?.ad_auction_date) {
        if (dayjs(row?.ad_auction_date).isValid()) {
            if ((dayjs(dayjs()).isAfter(row?.ad_auction_date))) {
                if (row?.status && (row?.status === 4)) {
                    return(
                        <div className={classes.accepted}>
                            <span></span>
                            {t.sent_to_auction}
                        </div>
                    );
                } else {
                    return(
                        <div className={classes.rejected}>
                            <span></span>
                            {t.declined}
                        </div>
                    );
                }
            } else if (dayjs(row?.ad_auction_date).isAfter(dayjs())) {
                if (row?.status && ((row?.status === 3) || (row?.status === 4))) {
                    if (row?.status === 4) {
                        return(
                            <div className={classes.accepted}>
                                <span></span>
                                {t.sent_to_auction}
                            </div>
                        );
                    } else {
                        return(
                            <div className={classes.rejected}>
                                <span></span>
                                {t.declined}
                            </div>
                        );
                    }
                } else {
                    return(
                        <React.Fragment>
                            <button
                                className={classes.btn_reject}
                                onClick={() => setShowConfirmModal({
                                    show: true,
                                    type: "refuse",
                                    offer_id: row.product_offer_id
                                })}
                            >
                                <span className={classes.icon}></span>
                                {t.reject_offer}
                            </button>
                            <button
                                className={classes.btn_accept}
                                onClick={() => setShowConfirmModal({
                                    show: true,
                                    type: "auction",
                                    offer_id: row.product_offer_id
                                })}
                            >
                                <span className={classes.icon}></span>
                                {t.send_to_auction}
                            </button>
                        </React.Fragment>
                    );
                }
            } else {
                return(
                    <div className={classes.rejected}>
                        <span></span>
                        {t.declined}
                    </div>
                );
            }
        } else {
            return(
                <React.Fragment></React.Fragment>
            );
        }
    } else {
        if (row?.status && ((row?.status === 2) || (row?.status === 3))) {
            if (row?.status === 2) {
                return(
                    <div className={classes.accepted}>
                        <span></span>
                        {t.accepted}
                    </div>
                );
            } else {
                return(
                    <div className={classes.rejected}>
                        <span></span>
                        {t.declined}
                    </div>
                );
            }
        } else {
            return(
                <React.Fragment>
                    <button
                        className={classes.btn_reject}
                        onClick={() => setShowConfirmModal({
                            show: true,
                            type: "refuse",
                            offer_id: row.product_offer_id
                        })}
                    >
                        <span className={classes.icon}></span>
                        {t.reject_offer}
                    </button>
                    <button
                        className={classes.btn_accept}
                        onClick={() => setShowConfirmModal({
                            show: true,
                            type: "accept",
                            offer_id: row.product_offer_id
                        })}
                    >
                        <span className={classes.icon}></span>
                        {t.accept_offer}
                    </button>
                </React.Fragment>
            );
        }
    }
}

const OffersPreview = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    useEffect(() => () => hideNotifications(), []);

    const t_my_adds_offers = t.my_adds_offers;
    const t_global = t.global;

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [pagination, setPagination] = useState<IPagination>({
        sizePerPage: 10,
        page: 1
    });
    const [offers, setOffers]:any = useState<[]>([]);
    const [totalData, setTotalData] = useState<number>(0);
    const [showConfirmModal, setShowConfirmModal] = useState<IConfirmModal>({
        show: false,
        type: "",
        offer_id: ""
    });
    const [showConfirmModalCompany, setShowConfirmModalCompany]:any = useState<ICompanyModal>({
        show: false,
        type: "",
        offer: {},
        offer_id: ""
    });
    const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
        searchValue: "",
        datumOd: "",
        datumDo: "",
    });
    const [sorting, setSorting] = useState<ISortingParameters>({
        sortField: null,
        sortOrder: undefined
    });
    const [productData, setProductData] = useState<IProductData>({
        ...productDataDefault
    } as IProductData);

    const fetchProductOffers = async (page = 1, sizePerPage = 10, sortField = null, sortOrder = undefined, filter = searchParameters) => {
        let query = `product_id=${id}&page=${page}&per_page=${sizePerPage}`;
        if ((sortField !== null) && (sortOrder !== undefined)) {
            query += `&sort_field=${sortField}&sort_type=${sortOrder}`;
            setSorting({sortField, sortOrder});
        }
        if ((filter.datumOd !== "") && (filter.datumDo !== "")) {
            query += `&date_from=${dayjs(filter.datumOd).format("YYYY-MM-DD")}`;
            query += `&date_to=${dayjs(filter.datumDo).format("YYYY-MM-DD")}`;
        }
        if (filter.searchValue !== "") {
            query += `&search_word=${filter.searchValue}`;
        }
        setOffers([]);
        setPagination({
            page,
            sizePerPage
        });
        setLoadingData(true);
        try {
            const response = await httpGet<any>(`/offers/product?${query}&XDEBUG_SESSION_START=phpstorm`);
            setOffers(response?.data?.data?.product_offers || []);
            setTotalData(response?.data?.total_items || 0);
            setProductData(response?.data?.data?.ad_details || {})
            setLoadingData(false);
        } catch (error) {
          console.error(error);
          setLoadingData(false);
        };
    }

    const customCaret = (order, column) => {
        if (!order) {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-5px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else if (order === 'asc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                    </span>
                </span>
            );
        } else if (order === 'desc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else {
            return null;
        }
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {`${t_global["table_total_text"]} ${from} ${t_global["table_total_text_1"]} ${to} ${t_global["table_total_text_2"]} ${size} ${t_global["table_total_text_3"]}`}
        </span>
    );

    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        fetchProductOffers(page, sizePerPage, sortField, sortOrder);
    }

    const tableColumns = [{
        dataField: "offer_company_name",
        text: t_my_adds_offers.company,
        formatter: (cell, row) => {
            return(
                <div
                    onClick={() => {
                        setShowConfirmModalCompany({
                            show: true,
                            type: row?.ad_auction_date ? "auction" : "accept",
                            offer: {...row?.company_details, status: row?.status} || {},
                            offer_id: row.product_offer_id || ""
                        });
                    }}
                >
                    {cell}
                </div>
            )
        },
        sort: true
    }, {
        dataField: "offered_price",
        text: t_my_adds_offers.offered_price,
        sort: true
    }, {
        dataField: "auction",
        text: t_my_adds_offers.auctions,
        formatter: (cell, row) =>
            <CustomActionCol
                row={row}
                setShowConfirmModal={setShowConfirmModal}
            />
    }];

    const paginationOptions = {
        custom: true,
        totalSize: totalData,
        page: pagination.page,
        sizePerPage: pagination.sizePerPage,
        paginationSize: 4,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        firstPageText: "<<",
        prePageText: "<",
        nextPageText: ">",
        lastPageText: ">>",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
          text: "10", value: 10
        }, {
          text: "50", value: 50
        }, {
          text: "100", value: 100
        }],
    };

    const changeOfferStatusAPI = async (product_offer_id, status) => {
        try {
            const response = await httpPost<any, any>(`/offer/change-status?XDEBUG_SESSION_START=phpstorm`, {
                product_offer_id,
                status
            });
            if (response?.message === "Offer status changed") {
                const message = (status === 3)
                    ? t_my_adds_offers.offer_declined
                    : (status === 2)
                        ? t_my_adds_offers.offer_accepted
                        : ""
                ;
                showNotification(message, "success");
            } else {
                showNotification(t_my_adds_offers.offer_error_message, "danger");
            }
        } catch (error) {
          console.error(error);
          showNotification(t_my_adds_offers.offer_error_message, "danger");
        };
    }

    const confirmOfferFunc = (action:string = "") => {
        if (showConfirmModal.show) {
            if (showConfirmModal.type === "refuse") {
                changeOfferStatusAPI(showConfirmModal.offer_id, 3);
            } else if (showConfirmModal.type === "accept") {
                changeOfferStatusAPI(showConfirmModal.offer_id, 2);
            } else if (showConfirmModal.type === "auction") {
                changeOfferStatusAPI(showConfirmModal.offer_id, 4);
            }
            setShowConfirmModal({
                show: false,
                type: "",
                offer_id: ""
            });
        } else if (showConfirmModalCompany.show) {
            let offer_id = showConfirmModalCompany.offer_id;
            setShowConfirmModalCompany({
                show: false,
                type: "",
                offer: {},
                offer_id: ""
            });
            setShowConfirmModal({
                show: true,
                type: action,
                offer_id
            });
        }
    }

    const onSearchChangeHandler = (params:ISearchParameters) => {
        setSearchparameters(params);
        const { page, sizePerPage } = pagination;
        const { sortField, sortOrder } = sorting;
        fetchProductOffers(page, sizePerPage, sortField, sortOrder, params);
    };

    const BackBtn = () => {
        return(
            <div className={classes.header_content_container}>
                <button
                    type="button"
                    className={classes["back_btn"]}
                    onClick={() => navigate(-1)}
                >
                    &#60; {t_global["back"]}
                </button>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Page showBackBbtn={BackBtn}>
                <OfferHeader
                    Info={{
                        date: productData?.ad_date,
                        number: productData?.ad_number
                    }}
                />
                <div className={classes.product_wrapper}>
                    <div className={classes.product_info}>
                        <div className={classes.title_wrapper}>
                            <div className={classes.image_wrapper}>
                                <img
                                    src={productData?.product_images[0]?.file_url || ""}
                                    alt="company icon"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoImageIcon;
                                    }}
                                />
                            </div>
                            <div className={classes.info_wrapper}>
                                <div className={classes.title}>
                                    {productData?.product_title}
                                </div>
                                <div className={classes.category}>
                                    {productData?.ad_category}
                                </div>
                            </div>
                        </div>
                        <div className={classes.description}>
                            {productData?.product_description}
                        </div>
                    </div>
                    <div className={classes.product_quantity}>
                        <div className={classes.title}>
                            {t_my_adds_offers.quantity_requested}
                        </div>
                        <div className={classes.quantity}>
                            {productData?.product_quantity}
                        </div>
                    </div>
                </div>
                <Filters
                    offers={offers}
                    onSearchChange={onSearchChangeHandler}
                />
                <div className={classes.table_wrapper}>
                    <div className={classes.table_container}>
                        <PaginationProvider
                            pagination={ paginationFactory(paginationOptions) }
                        >
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <div>
                                        <BootstrapTable
                                            keyField="product_offer_id"
                                            remote={{
                                                pagination: true
                                            }}
                                            onTableChange={onTableChange}
                                            data={offers}
                                            columns={tableColumns}
                                            classes={classes.offers_table}
                                            noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                                            sort={{
                                                sortCaret: customCaret
                                            }}
                                            { ...paginationTableProps }
                                        />
                                        <div className={classes.pagination_wrapper}>
                                            <PaginationTotalStandalone
                                                { ...paginationProps }
                                            />
                                            <div>
                                                <PaginationListStandalone
                                                    { ...paginationProps }
                                                />
                                                <SizePerPageDropdownStandalone
                                                    { ...paginationProps }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </PaginationProvider>
                    </div>
                </div>
            </Page>
            <ConfirmModal
                show={showConfirmModal.show}
                toggleModal={() => setShowConfirmModal({
                    show: false,
                    type: "",
                    offer_id: ""
                })}
                confirm={confirmOfferFunc}
                title={(showConfirmModal.type === "refuse")
                    ? t_my_adds_offers.reject_offer
                    : (showConfirmModal.type === "auction")
                        ? t_my_adds_offers.send_to_auction
                        : t_my_adds_offers.accept_offer
                }
                body={(showConfirmModal.type === "refuse")
                    ? t_my_adds_offers.reject_modal_body
                    : (showConfirmModal.type === "auction")
                        ? t_my_adds_offers.offer_send_to_auction
                        : t_my_adds_offers.accept_modal_body
                }
                confirmBtnClass={(showConfirmModal.type === "refuse")
                    ? classes.refuse_btn_style
                    : classes.accept_btn_style
                }
                confirmBtnIcon={<span className={classes.icon}></span>}
                confirmBtnText={(showConfirmModal.type === "refuse")
                    ? t_my_adds_offers.reject_offer
                    : (showConfirmModal.type === "auction")
                        ? t_my_adds_offers.send_to_auction
                        : t_my_adds_offers.accept_offer
                }
            />
            <ConfirmModalCompany
                show={showConfirmModalCompany.show}
                offer={showConfirmModalCompany.offer}
                confirmBtnText={
                    (showConfirmModalCompany.type === "auction")
                        ? t_my_adds_offers.send_to_auction
                        : null
                }
                hideFooterBtns={
                    (showConfirmModalCompany.offer?.status &&
                        ((showConfirmModalCompany.offer?.status === 2) ||
                        (showConfirmModalCompany.offer?.status === 3) ||
                        (showConfirmModalCompany.offer?.status === 4))
                    )
                        ? true
                        : false
                }
                onHandleClose={() => setShowConfirmModalCompany({
                    show: false,
                    type: "",
                    offer: {},
                    offer_id: ""
                })}
                onHandleConfirm={() => confirmOfferFunc(showConfirmModalCompany.type)}
            />
        </React.Fragment>
    )
}

export default OffersPreview;