import React from "react";
import { ISwitch } from "./model/switch.model";
import classes from "./Switch.module.scss";

const Switch = (props: ISwitch) => {
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event.target.checked);
        }
    };

    let classList:any = [];
    if (props.type && (props.type === "only_switch_clicable")) {
        classList.push(classes.switch_container);
    } else {
        classList.push(classes.Switch);
    }
    if (props.switchPosition === "start") {
        classList.push(classes.Start);
    }

    return (
        <React.Fragment>
            <div className={`${classList.join(" ")} ${props.customClasses ? props.customClasses : ""}`} style={props.style}>
                {(props.type && (props.type === "only_switch_clicable")) ? (
                    <span className={classes.switch_wrapper}>
                        {props.label}
                        <label className={classes.switch}>
                            <input
                                type="checkbox"
                                id={props.name}
                                checked={props.checked}
                                onChange={onChangeHandler}
                            />
                            <span className={classes.slider}></span>
                        </label>
                    </span>
                ) : (
                    <>
                        <input
                            type="checkbox"
                            id={props.name}
                            checked={props.checked}
                            onChange={onChangeHandler}
                        />
                        <label htmlFor={props.name}>{props.label}</label>
                    </>
                )}
            </div>
        </React.Fragment>
    );
};

export default Switch;
