import React from "react";
import Button from "../../../../components/UI/Button/Button";

import ArrowLeft from "./../../../../assets/icons/ArrowLeft.svg";
import ArrowRight from "./../../../../assets/icons/ArrowRight.svg";

import classes from "./Footer.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { registerActions } from "../../../../store/register/register";
import { RootState } from "../../../../store";

const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isReCaptchaValid = useSelector(
        (state: RootState) => state.register.isReCaptchaValid
    );

    const activeStep = useSelector(
        (state: RootState) => state.register.activeStep
    );

    const step1IsInvalid = useSelector((state: RootState) => {
        const fields = state.register.formData.step1;
        console.log(fields)
        return Object.keys(fields).some(
            (field) => state.register.formData.step1[field].errorMessage
        );
    });

    const step2IsInvalid = useSelector((state: RootState) => {
        const fields = state.register.formData.step2;
        return Object.keys(fields).some(
            (field) => state.register.formData.step2[field].errorMessage
        );
    });

    const onNextStepHandler = () => {
        if (activeStep === 1) {
            // if we are on step 1, validate step 1
            // if any field is invalid, dont open step 2
            if (step1IsInvalid) {
                // if invalid -> mark all fields as touched for user feedback
                dispatch(registerActions.markStep1InvalidFields());
            } else {
                dispatch(
                    registerActions.setActiveStep({ step: activeStep + 1 })
                );
            }
        }
    };

    const onPreviousStepHandler = () => {
        dispatch(registerActions.setActiveStep({ step: activeStep - 1 }));
    };

    const onCancelHandler = () => {
        dispatch(registerActions.openCancelModal());
    };

    const onRegisterStepHandler = () => {
        if (step2IsInvalid) {
            // if invalid -> mark all fields as touched for user feedback
            dispatch(registerActions.markStep2InvalidFields());
        } else {
            dispatch(registerActions.openModal());
        }
    };

    return (
        <React.Fragment>
            <div className={`${classes.Footer} row mt-3`}>
                <div className="col-6 col-lg-4 order-lg-1">
                    {activeStep !== 1 && (
                        <Button
                            label="Претходен чекор"
                            icon={ArrowLeft}
                            class="dark-gray"
                            hideLabelOnSmallScren
                            onClick={onPreviousStepHandler}
                        />
                    )}
                </div>
                <div className="col-6 col-lg-2 order-lg-3 text-lg-end">
                    {activeStep !== 2 ? (
                        <Button
                            label="Следен чекор"
                            icon={ArrowRight}
                            iconPosition={"right"}
                            class="accent"
                            hideLabelOnSmallScren
                            // disabled={step1IsInvalid}
                            onClick={onNextStepHandler}
                            disabled={!isReCaptchaValid}
                        />
                    ) : (
                        <Button
                            label="Регистрирај се"
                            class="accent"
                            // disabled={step2IsInvalid}
                            onClick={onRegisterStepHandler}
                        />
                    )}
                </div>
                <div
                    className={`${classes.CancelButton} col-12 mt-2 mt-lg-0 col-lg-6 order-lg-2 text-lg-end`}
                >
                    <Button
                        label="Откажи"
                        class="accent"
                        asLink
                        onClick={onCancelHandler}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Footer;
