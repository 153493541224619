import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Lightbox } from "react-modal-image";

import { IProductList } from "./product-list.model";
import { RootState } from "../../../../../store";

import NoImageIcon from "../../../../../assets/icons/No-image-icon.svg";

import classes from "./ProductsList.module.scss";

const ProductsList = (props: IProductList) => {
    const t = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage]
    );

    const t_product = t.product;
    const t_global = t.global;

    const [showMore, setShowmore] = useState<string[]>([]);
    const [previewImage, setPreviewImage] = useState({
        show: false,
        image: ""
    })

    return (
        <React.Fragment>
            <div className={classes.ProductItems}>
                {props.products.map((p, i) => {
                    return (
                        <div key={i} className={classes.product_wrapper}>
                            <div className={classes.product_info_wrapper}>
                                <div className={classes.header_wrapper}>
                                    <div className={classes.image_wrapper}>
                                        <img
                                            src={p?.images[0]?.file_url || NoImageIcon}
                                            alt="product icon"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NoImageIcon;
                                            }}
                                        />
                                    </div>
                                    <div className={classes.title_wrapper}>
                                        <div className={classes.title}>
                                            {p?.title || ""}
                                        </div>
                                        <div className={classes.category}>
                                            {p?.category || ""}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.description}>
                                    {p?.description
                                        ? (
                                            ((p?.description.length > 255) && !showMore.includes(i.toString()))
                                                ? (
                                                    <>
                                                        {p?.description.substring(0, 255)}...
                                                        <span onClick={() => setShowmore([...showMore, i.toString()])}>{t_global.show_more}</span>
                                                    </>
                                                )
                                                : p?.description
                                        )
                                        : ""
                                    }
                                </div>
                                {(p?.images && (p?.images?.length > 0)) && (
                                    <div className={classes.images_wrapper}>
                                        {p?.images.map((image, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={classes.image_wrapper}
                                                    onClick={() => {
                                                        if (image?.file_url) {
                                                            setPreviewImage({
                                                                show: true,
                                                                image: image?.file_url
                                                            })
                                                        }
                                                    }}
                                                >
                                                    <img
                                                        src={image?.file_url}
                                                        alt="product icon"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = NoImageIcon;
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className={classes.sidebar_wrapper}>
                                <div className={classes.quantity_wrapper}>
                                    <div className={classes.title}>
                                        {t_product.quantity_requested}
                                    </div>
                                    <div className={classes.quantity}>
                                        {p?.quantity || ""}
                                    </div>
                                </div>
                                <div className={classes.price_wrapper}>
                                    <div className={classes.title}>
                                        {t_product.enter_price}
                                    </div>
                                    <div className={classes.input_wrapper}>
                                        <input
                                            type="number"
                                            name="price"
                                            disabled={props?.editOffer}
                                            defaultValue={p?.offer_price || ""}
                                        />
                                        <div className={classes.currency}>
                                            {t_global.currency_MKD}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {previewImage.show && (
                <Lightbox
                    medium={previewImage.image}
                    hideZoom={true}
                    hideDownload={true}
                    imageBackgroundColor="transparent"
                    onClose={() => setPreviewImage({
                        show: false,
                        image: ""
                    })}
                />
            )}
        </React.Fragment>
    );
};

export default ProductsList;
