import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Badge from "react-bootstrap/Badge";

import Countdown from "../../../components/UI/Countdown/Countdown";
import { RootState } from "../../../store";
import { RoutesEnum } from "../../../enums/routes";
import Page from "../../../components/Layout/Page/Page";
import {
    IPaginationParameters,
    ISortingParameters,
    ISearchParameters
} from "./current-ads.model";
import { httpGet } from "../../../services/http";
import Filters from "./Components/Filters/Filters";

import BidIcon from "../../../assets/icons/BidIcon.svg";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import classes from "./CurrentAdvertisements.module.scss";

const ActionTableContent = (props) => {
    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "current_advertisements"
        ];
    });
    return (t[props.cell ? "yes" : "no"]);
}

const endTimeFormatter = (cell, row) => {
    if (dayjs(cell).isValid() && dayjs(row.start).isValid()) {
        if (dayjs(cell).isAfter(dayjs()) && (dayjs(row.start).isSame(dayjs()) || dayjs(row.start).isBefore(dayjs()))) {
            return (
                <Countdown
                    date={dayjs(cell).format("YYYY/MM/DD")}
                    mode="light"
                />
            )
        } else if (dayjs(cell).isBefore(dayjs())) {
            return (
                <Countdown
                    date={dayjs(cell).format("YYYY/MM/DD")}
                    mode="light"
                />
            );
        } else {
            return dayjs(cell).format("DD.MM.YYYY");
        }
    } else {
        return null;
    }
}

const CurrentAdvertisements = () => {

    const [tableData, setTableData]:any = useState([]);
    const [activeTab, setActiveTab]:any = useState("active");
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
        searchValue: "",
        category_id: ""
    });
    const [pagination, setPagination] = useState<IPaginationParameters>({
        sizePerPage: 10,
        page: 1
    });
    const [sorting, setSorting] = useState<ISortingParameters>({
        sortField: null,
        sortOrder: undefined
    });
    const [adsCount, setAdsCount] = useState({
        active: 0,
        inactive: 0
    });
    const [totalData, setTotalData] = useState<number>(0);

    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const t_current_ads = translations.current_advertisements;
    const t_global = translations.global;

    const fetchAds = async(page = 1, sizePerPage = 10, sortField = null, sortOrder = undefined, selectedTab = "active", filter = searchParameters) => {
        const selectedTabVal = (selectedTab === "active") ? 1 : 2;
        let query = `page=${page}&per_page=${sizePerPage}&tender_type=${selectedTabVal}`;
        if ((sortField !== null) && (sortOrder !== undefined)) {
            query += `&sort_field=${sortField}&sort_type=${sortOrder}`;
            setSorting({sortField, sortOrder});
        }
        if (filter.searchValue !== "") {
            query += `&search_word=${filter.searchValue}`;
        }
        if (filter.category_id) {
            query += `&category_id=${filter.category_id}`;
        }
        setTableData([]);
        setPagination({
            page,
            sizePerPage
        });
        setLoadingData(true);
        try {
            const response = await httpGet<any>(`/tender/get-active?${query}&XDEBUG_SESSION_START=phpstorm`);
            setTableData(response.data[selectedTab].data);
            setTotalData(response.data[selectedTab].total_items)
            setLoadingData(false);
            const countAds = {...adsCount};
            ["active", "inactive"].forEach((tab) => {
                if (response?.data && response?.data[tab]) {
                    countAds[tab] = response?.data[tab].total_items;
                }
            });
            setAdsCount(countAds);
        } catch (error) {
          console.error(error);
          setLoadingData(false);
        };
    };

    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        fetchAds(page, sizePerPage, sortField, sortOrder, activeTab);
    }

    const onTabChange = (active) => {
        const { page, sizePerPage } = pagination;
        const { sortField, sortOrder } = sorting;
        setActiveTab(active);
        fetchAds(page, sizePerPage, sortField, sortOrder, active);
    }

    const tableColumns = [{
        dataField: "already_bid",
        text: "",
        headerFormatter: () => {
            return <img src={BidIcon} alt="Already bid" />;
        },
        formatter: (cell, row) => {
            if(cell && (cell === true)) {
                return <img src={BidIcon} alt="Already bid" />;
            } else {
                return "";
            }
        },
        sort: false
    }, {
        dataField: "id",
        text: t_current_ads.offer_num,
        formatter: (cell, row) => {
            return(
                <NavLink to={`/advertisements/${row.tender_id}`} className={classes.AdLink}>
                    {row.tender_id}
                </NavLink>
            );
        },
        sort: true
    }, {
        dataField: "company",
        text: t_current_ads["company"],
        formatter: (cell, row) => {
            if (row.company_id) {
                return(
                    <NavLink to={`${RoutesEnum.CompanyInfo}?id=${row.company_id}`} className={classes.AdLink}>
                        {cell}
                    </NavLink>
                );
            } else {
                return cell;
            }
        },
        sort: true
    }, {
        dataField: "title",
        text: t_current_ads.offer_name,
        sort: true
    }, {
        dataField: "category",
        text: t_current_ads.categories,
        sort: true
    }, {
        dataField: "start",
        text: t_current_ads.start,
        formatter: (cell, row) => {
            if (cell && dayjs(cell).isValid()) {
                return (
                    <div className={classes.date}>{dayjs(cell).format("DD.MM.YYYY")}</div>
                );
            } else {
                return cell;
            }
        },
        sort: true
    }, {
        dataField: "end",
        text: t_current_ads.closure,
        formatter: (cell, row) => {
            if (activeTab === "active") {
                return endTimeFormatter(cell, row);
            } else {
                return (
                    <div className={classes.date}>{dayjs(cell).format("DD.MM.YYYY")}</div>
                );
            }
        },
        sort: true
    }, {
        dataField: "aukcija",
        text: t_current_ads.auction,
        sort: true,
        formatter: (cell, row) => <ActionTableContent cell={cell} />
    }];

    const customCaret = (order, column) => {
        if (!order) {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-5px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else if (order === 'asc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                    </span>
                </span>
            );
        } else if (order === 'desc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else {
            return null;
        }
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {`${t_global["table_total_text"]} ${from} ${t_global["table_total_text_1"]} ${to} ${t_global["table_total_text_2"]} ${size} ${t_global["table_total_text_3"]}`}
        </span>
    );

    const paginationOptions = {
        custom: true,
        totalSize: totalData,
        page: pagination.page,
        sizePerPage: pagination.sizePerPage,
        paginationSize: 4,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        firstPageText: "<<",
        prePageText: "<",
        nextPageText: ">",
        lastPageText: ">>",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
          text: "10", value: 10
        }, {
          text: "50", value: 50
        }, {
          text: "100", value: 100
        }]
    };

    const onSearchChangeHandler = (params: ISearchParameters) => {
        setSearchparameters(params);
        const { page, sizePerPage } = pagination;
        const { sortField, sortOrder } = sorting;
        fetchAds(page, sizePerPage, sortField, sortOrder, activeTab, params);
    };

    return (
        <React.Fragment>
            <Page>
                <Filters
                    onSearchChange={onSearchChangeHandler}
                    advertisements={tableData}
                />
                <div className={classes.table_wrapper}>
                    <div className={classes.buttons_wrapper}>
                        <div
                            className={`${classes.button} ${(activeTab === "active") ? classes.active : ""}`}
                            onClick={() => onTabChange("active")}
                        >
                            {t_current_ads["active"]} {(activeTab === "active") ? <Badge bg="primary">{adsCount.active}</Badge> : <span className={classes.text}>({adsCount.active})</span>} 
                        </div>
                        <div
                            className={`${classes.button} ${(activeTab === "inactive") ? classes.active : ""}`}
                            onClick={() => onTabChange("inactive")}
                        >
                            {t_current_ads["finished"]} {(activeTab === "inactive") ? <Badge bg="primary">{adsCount.inactive}</Badge> : <span className={classes.text}>({adsCount.inactive})</span>}
                        </div>
                    </div>
                    <div className={classes.table_container}>
                        <PaginationProvider
                            pagination={ paginationFactory(paginationOptions) }
                        >
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <div>
                                        <BootstrapTable
                                            keyField="id"
                                            remote={{
                                                pagination: true
                                            }}
                                            onTableChange={onTableChange}
                                            data={tableData}
                                            columns={tableColumns}
                                            classes={classes.offers_table}
                                            noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                                            sort={{
                                                sortCaret: customCaret
                                            }}
                                            { ...paginationTableProps }
                                        />
                                        <div className={classes.pagination_wrapper}>
                                            <PaginationTotalStandalone
                                                { ...paginationProps }
                                            />
                                            <div>
                                                <PaginationListStandalone
                                                    { ...paginationProps }
                                                />
                                                <SizePerPageDropdownStandalone
                                                    { ...paginationProps }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </PaginationProvider>
                    </div>
                </div>
            </Page>
        </React.Fragment>
    );
};

export default CurrentAdvertisements;
