import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import {
    currencies,
    newAdFields,
} from "../../pages/NewAd/NewAdForm/Components/BaseInfo/fields/fields";
import { INewAdTitle, IRemoveProduct, IUpdateAdProduct } from "./actions";
import { INewAdd } from "./newAd.model";

const initialState: INewAdd = {
    data: {
        ime_oglas: {
            value: "",
        },
        rok_na_isporaka: newAdFields.rok_na_isporaka,
        rok_na_plakjanje: newAdFields.rok_na_plakjanje,
        rok_na_traenje: newAdFields.rok_na_traenje,
        products: [],
    },
    agreeToTerms: false,
};

const newAdSlice = createSlice({
    initialState: initialState,
    name: "newAd",
    reducers: {
        updateAdHeader: (state, action: PayloadAction<INewAdTitle>) => {
            let newState = { ...state };
            newState.data.ime_oglas =
                action.payload.ime_oglas ?? state.data.ime_oglas;

            newState.data.rok_na_isporaka =
                action.payload.rok_na_isporaka ?? state.data.rok_na_isporaka;

            newState.data.rok_na_plakjanje =
                action.payload.rok_na_plakjanje ?? state.data.rok_na_plakjanje;

            newState.data.rok_na_traenje =
                action.payload.rok_na_traenje ?? state.data.rok_na_traenje;
        },
        markAsTouched: (state, action) => {
            state.data[action.payload].touched = true;
        },
        toggleAgreeToTerms: (state) => {
            state.agreeToTerms = !state.agreeToTerms;
        },
        addProductObject: (state) => {
            state.data.products.push({
                id: {
                    ...newAdFields.id,
                    value: v4(),
                },
                bez_limit: newAdFields.bez_limit,
                cena_max: newAdFields.cena_max,
                cena_min: newAdFields.cena_min,
                ime: newAdFields.ime,
                kategorija: newAdFields.kategorija,
                kolicina: newAdFields.kolicina,
                opis: newAdFields.opis,
                opseg_na_cena: newAdFields.opseg_na_cena,
                valuta: {
                    ...newAdFields.valuta,
                    value: currencies[0],
                },
                dokumenti: newAdFields.dokumenti,
                image: newAdFields.image,
                kriterium: newAdFields.kriterium,
            });
        },
        reset: (state) => {
            Object.assign(state, initialState);
        },

        updateAdProduct: (state, action: PayloadAction<IUpdateAdProduct>) => {
            if (action.payload.fieldName) {
                state.data.products[action.payload.index][
                    action.payload.fieldName
                ] = action.payload.product[action.payload.fieldName];
            } else {
                state.data.products[action.payload.index] =
                    action.payload.product;
            }
        },

        markHeaderFieldsAsTouched: (state) => {
            state.data.ime_oglas.touched = true;
            state.data.ime_oglas.errorMessage = state.data.ime_oglas.value
                ? ""
                : "Задолжително поле";

            state.data.rok_na_isporaka.touched = true;
            state.data.rok_na_isporaka.errorMessage = state.data.rok_na_isporaka
                .value
                ? ""
                : "Задолжително поле";

            state.data.rok_na_plakjanje.touched = true;
            state.data.rok_na_plakjanje.errorMessage = state.data
                .rok_na_plakjanje.value
                ? ""
                : "Задолжително поле";

            state.data.rok_na_traenje.touched = true;
            state.data.rok_na_traenje.errorMessage = state.data.rok_na_traenje
                .value
                ? ""
                : "Задолжително поле";
        },

        removeProduct: (state, action: PayloadAction<IRemoveProduct>) => {
            state.data.products.splice(action.payload.index, 1);
        },

        mark: (state, action: PayloadAction<IRemoveProduct>) => {
            state.data.products.splice(action.payload.index, 1);
        },
    },
});

export const newAdSliceActions = newAdSlice.actions;
export default newAdSlice.reducer;
