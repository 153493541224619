import React from "react";
import classes from "./Hamburger.module.scss";
import { IHamburger } from "./model/Hamburger.model";

const Hamburger = (props: IHamburger) => {
    const classList = [classes.Hamburger];

    if (props.mode === "close") {
        classList.push(classes.Close);
    }

    if (props.theme) {
        classList.push(classes[props.theme]);
    } else {
        classList.push(classes.Light);
    }

    return (
        <React.Fragment>
            <button className={classList.join(" ")} onClick={props.onClick}>
                <span></span>
                <span></span>
                <span></span>
            </button>
        </React.Fragment>
    );
};

export default Hamburger;
