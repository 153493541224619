import React from "react";
import { useLocation } from "react-router-dom";
import RegisterNavbar from "./RegisterNavbar/RegisterNavbar";
import { IPrimaryNavbar } from "./models/primary-navbar.model";
import classes from "./PrimaryNavbar.module.scss";
import GeneralNavbar from "./GeneralNavbar/GeneralNavbar";
import { RoutesEnum } from "../../../../enums/routes";

const PrimaryNavbar = (props: IPrimaryNavbar) => {
    const currentRoute = useLocation().pathname;
    let navbar;

    if (currentRoute === RoutesEnum.Register) {
        navbar = <RegisterNavbar {...props} />;
    } else {
        navbar = <GeneralNavbar {...props} />;
    }

    return (
        <React.Fragment>
            <div className={`${classes.PrimaryNavbar} primary-nav`}>
                {navbar}
            </div>
        </React.Fragment>
    );
};

export default PrimaryNavbar;
