import React from "react";
import classes from "./FloatInput.module.scss";
import { IFloatInput } from "./model/FloatInput.model";

const FloatInput = (props: IFloatInput) => {
    let errors: Array<string> = [];
    if (props.error) {
        errors = Array.isArray(props.error) ? props.error : [props.error];
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (props.onChange) {
            props.onChange(value);
        }
    };

    const onKeyUpHandler = (event: React.KeyboardEvent) => {
        if (props.onKeyUp) {
            props.onKeyUp(event);
        }
    }

    const onActionClick = () => {
        props.onActionIconClick && props.onActionIconClick();
    };

    return (
        <React.Fragment>
            <div className={`${classes.FloatInputContainer}`}>
                <div
                    className={`${classes.FloatInput} ${
                        errors.length ? classes.ErrorInput : null
                    } input-group`}
                >
                    <div
                        className={`${classes.InputGroupText} input-group-text p-0 justify-content-center`}
                    >
                        <img src={props.icon} alt={props.icon} />
                    </div>
                    <input
                        type={props.type ?? "text"}
                        className={`${classes.FormControl} form-control`}
                        placeholder={props.label}
                        aria-label={props.label}
                        value={props.value}
                        onChange={onChangeHandler}
                        onKeyUp={onKeyUpHandler}
                    />
                    {props.actionIcon && (
                        <span
                            className={`${classes.InputGroupText} ${classes.ToggleIcon} input-group-text p-0 justify-content-center`}
                            onClick={onActionClick}
                        >
                            <img
                                src={props.actionIcon}
                                alt={props.actionIcon}
                            />
                        </span>
                    )}
                </div>
                {errors && errors.length > 0 && (
                    <div className={classes.Errors}>
                        {errors.map((error) => {
                            return (
                                <small
                                    id="emailHelp"
                                    className={`${classes.ErrorText} form-text text-muted`}
                                >
                                    {error}
                                </small>
                            );
                        })}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default FloatInput;
