import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "./auth/auth";
import listing from "./listing/listing";
import register from "./register/register";
import sidebar from "./sidebar/sidebar";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userListing from "./userListing/userListing";
import newAd from "./newAd/newAd";
import chat from "./chat/chat";
import auction from "./auction/auction";
import http from "./http/http";
import translations from "./translations/translations";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["newAd"],
};

const combinedReducer = combineReducers({
    auth: auth,
    auction: auction,
    chat: chat,
    listing: listing,
    newAd: newAd,
    register: register,
    sidebar: sidebar,
    userListing: userListing,
    http: http,
    translations: translations,
});

const rootReducer = (state, action) => {
    if (action.type === "RESET_STORE") {
        return combinedReducer(undefined, action);
    }

    return combinedReducer(state, action);
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: [
                    "register/updateInputValue",
                    "persist/PERSIST",
                    "newAd/updateProduct",
                ],
                // Ignore these field paths in all actions
                // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: [
                    "register",
                    "register.formData.step1.document_file.value",
                    "newAd",
                ],
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export default store;
