import React, { useEffect, useState } from "react";
import Input from "../../../../components/UI/Input/Input";
import Select from "../../../../components/UI/Select/Select";
import classes from "./Step1.module.scss";

import Upload from "./../../../../assets/icons/Upload.svg";
import Refresh from "./../../../../assets/icons/Refresh.svg";
import Test from "./../../../../assets/test.jpg";

import Checkbox from "../../../../components/UI/Checkbox/Checkbox";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { registerActions } from "../../../../store/register/register";
import UploadButton from "../../../../components/UI/Upload/UploadButton/UploadButton";
import { IBaseField } from "../../../../models/field-validation.model";

import Image from "./../../../../assets/icons/Image.svg";
import { countries } from '../../../../enums/countries';
import PhoneInput from 'react-phone-number-input'

import * as _ from "lodash";

export const Step1 = () => {

    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "components"
            ]
    );
            console.log(countries)
    const step1FormData = useSelector(
        (state: RootState) => state.register.formData.step1
    );
    const formData = _.cloneDeep(step1FormData);
    const checkboxes = useSelector(
        (state: RootState) => state.register.moreInfoCheckboxes
    );

    const dispatch = useDispatch();

    const onCompanyIndistriesChange = (value: string) => {
        dispatch(registerActions.checkCompanyIndustry(+value));
    };

    const onUploadFileChangeHandler = (files: any[]) => {
        if (!files || !files.length) {
            dispatch(
                registerActions.updateStep1FieldErrorMessage({
                    name: "document_file",
                    errorMessage: "Задолжително поле",
                })
            );
        } else {
            dispatch(
                registerActions.updateStep1FieldErrorMessage({
                    name: "document_file",
                    errorMessage: null,
                })
            );
        }

        dispatch(
            registerActions.updateStep1FieldValue({
                name: "document_file",
                value: files,
            })
        );
    };

    const onImageRemoveHandler = (index) => {
        dispatch(
            registerActions.updateStep1FieldErrorMessage({
                name: "document_file",
                errorMessage: "Задолжително поле",
            })
        );

        dispatch(
            registerActions.updateStep1FieldValue({
                name: "document_file",
                value: null,
            })
        );
    };

    const onValueChangeHandler = (name, value) => {
        const field = formData[name] as IBaseField<any>;
        const truthy = !!value ?? true;

        if (field.validators?.required && !truthy) {
            dispatch(
                registerActions.updateStep1FieldErrorMessage({
                    name,
                    errorMessage: "Задолжително поле",
                })
            );
        } else {
            dispatch(
                registerActions.updateStep1FieldErrorMessage({
                    name,
                    errorMessage: null,
                })
            );
        }

        dispatch(
            registerActions.updateStep1FieldValue({
                name,
                value,
            })
        );
    };

    const onInputBlurHandler = (name: string) => {
        dispatch(registerActions.markStep1FieldAsTouched({ name }));
    };

    const updateCaptcha = (event) => {
        setEnteredCaptcha((state) => event.target.value);
    };

    const generateCaptcha = () => {
        setEnteredCaptcha((state) => "");
        const val = Math.floor(Math.random() * 4);

        setScew((state) => {
            if (val === 1 || val === 2 || val === 3) return val;

            return 1;
        });

        //clear the contents of captcha div first
        document.getElementById("captcha")!.innerHTML = "";
        var charsArray = "01234567890";
        var lengthOtp = 4;
        var captcha: any = [];
        for (var i = 0; i < lengthOtp; i++) {
            //below code will not allow Repetition of Characters
            var index = Math.floor(Math.random() * charsArray.length); //get the next character from the array
            if (captcha.indexOf(charsArray[index]) === -1)
                captcha.push(charsArray[index]);
            else i--;
        }

        var canv = document.createElement("canvas");
        canv.id = "captcha";
        canv.width = 70;
        canv.height = 50;
        var ctx = canv.getContext("2d");
        ctx!.font = "30px Arial";
        ctx!.fillStyle = "#b3b3b3";
        ctx!.fillText(captcha.join(""), 0, 35);

        //storing captcha so that can validate you can save it somewhere else according to your specific requirements
        setCode((state) => captcha.join(""));

        document.getElementById("captcha")!.appendChild(canv); // adds the canvas to the body element
    };

    const [code, setCode] = useState("");
    const [enteredCaptcha, setEnteredCaptcha] = useState("");
    const [isClicked, setIsClicked] = useState(false);
    const [scew, setScew] = useState(1);

    useEffect(() => {
        generateCaptcha();
    }, []);

    const isReCaptchaValid = useSelector(
        (state: RootState) => state.register.isReCaptchaValid
    );

    const validateCaptchaOnClick = () => {
        if (!isClicked) {
            setIsClicked((state) => true);
        }

        if (code === enteredCaptcha) {
            dispatch(registerActions.setReCaptchaValid(true));
        } else {
            dispatch(registerActions.setReCaptchaValid(false));
        }
    };

    const onUploadLogoHandler = (file: any[]) => {
        if (file.length) {
            dispatch(
                registerActions.updateStep1FieldValue({
                    name: "logo",
                    value: file[0],
                })
            );

            dispatch(
                registerActions.updateStep1FieldErrorMessage({
                    name: "logo",
                    errorMessage: null,
                })
            );
        }
    };

    const onLogoRemoveHandler = (index) => {
        dispatch(
            registerActions.updateStep1FieldValue({
                name: "logo",
                value: null,
            })
        );

        dispatch(
            registerActions.updateStep1FieldErrorMessage({
                name: "logo",
                errorMessage: "Задолжително поле",
            })
        );
    };

    const countryOptions = [
        { value: "us", label: "United States" },
        { value: "ca", label: "Canada" },
        // ... more country options
      ];

    const handleCountryChange = (selectedOption) => {
        // Handle the selected country option here
        console.log("Selected country:", selectedOption);
    };

    return (
        <React.Fragment>
            <div className={`${classes.StepSection1}`}>
                <h6 className={`${classes.StepTitle} pb-2 mb-4`}>
                    Податоци за економски оператор
                </h6>
                <div className={classes.StepFormControls}>
                    <div className={classes.FormGrid}>
                        <div className={classes.Section1}>
                            <Input
                                label="Матичен број на економски оператор"
                                name="maticen_broj_ekonomski_operator"
                                type="text"
                                // max={9999999}
                                maxLength={7}
                                pattern="\d*"
                                field={formData.maticen_broj_ekonomski_operator}
                                onValueChange={(value) =>
                                    onValueChangeHandler(
                                        "maticen_broj_ekonomski_operator",
                                        value
                                    )
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler(
                                        "maticen_broj_ekonomski_operator"
                                    )
                                }
                            />
                            <Input
                                label="Единствен даночен број (ЕДБ)"
                                name="edinstven_danocen_broj"
                                type="text"
                                maxLength={13}
                                field={formData.edinstven_danocen_broj}
                                onValueChange={(value) =>
                                    onValueChangeHandler(
                                        "edinstven_danocen_broj",
                                        value
                                    )
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("edinstven_danocen_broj")
                                }
                            />
                            <Input
                                label="Назив на економски оператор"
                                name="naziv"
                                type="string"
                                maxLength={2000}
                                field={formData.naziv}
                                onValueChange={(value) =>
                                    onValueChangeHandler("naziv", value)
                                }
                                onInputBlur={() => onInputBlurHandler("naziv")}
                            />
                            <Input
                                label="Адреса"
                                name="address"
                                type="string"
                                maxLength={100}
                                field={formData.address}
                                onValueChange={(value) =>
                                    onValueChangeHandler("address", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("address")
                                }
                            />

                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <Input
                                        label="Град/Населено место"
                                        placeholder="Град"
                                        name="city"
                                        type="string"
                                        maxLength={100}
                                        field={formData.city}
                                        onValueChange={(value) =>
                                            onValueChangeHandler("city", value)
                                        }
                                        onInputBlur={() =>
                                            onInputBlurHandler("city")
                                        }
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        label="Поштенски број"
                                        name="postal_code"
                                        type="string"
                                        maxLength={100}
                                        field={formData.postal_code}
                                        onValueChange={(value) =>
                                            onValueChangeHandler(
                                                "postal_code",
                                                value
                                            )
                                        }
                                        onInputBlur={() =>
                                            onInputBlurHandler("postal_code")
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        label="Општина"
                                        name="municipality"
                                        type="string"
                                        maxLength={100}
                                        field={formData.municipality}
                                        onValueChange={(value) =>
                                            onValueChangeHandler("municipality", value)
                                        }
                                        onInputBlur={() =>
                                            onInputBlurHandler("municipality")
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Input
                                        label="Кратко инфо за компанијата"
                                        placeholder="Кратко инфо за компанијата"
                                        name="company_info"
                                        field={formData.company_info}
                                        type="textarea"
                                        rows={5}
                                        cols={10}
                                        onValueChange={(value) =>
                                            onValueChangeHandler("company_info", value)
                                        }
                                        onInputBlur={() =>
                                            onInputBlurHandler("company_info")
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classes.Section2}>
                            {/* <Input
                                label="Број на телефон"
                                name="phone_number"
                                field={formData.phone_number}
                                onValueChange={(value) =>
                                    onValueChangeHandler("phone_number", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("phone_number")
                                }
                            /> */}

                                <label>Број на телефон 
                                    <span className={classes.RequiredIndicator}>*</span> 
                                </label>
                                <PhoneInput
                                    className={formData.phone_number?.touched && formData.phone_number?.errorMessage ? classes.PhoneInputError : classes.PhoneInput}
                                    international
                                    countryCallingCodeEditable={false}
                                    name="phone_number"
                                    placeholder="Enter phone number"
                                    defaultCountry="MK"
                                    onChange={(value) =>
                                        onValueChangeHandler("phone_number", value)
                                    }
                                    onBlur={() =>
                                        onInputBlurHandler("phone_number")
                                    }
                                />
                                {formData.phone_number?.touched && formData.phone_number?.errorMessage && (
                                    <div id="phoneNumberHelp"className={`${classes.PhoneInputErrorText} form-text text-muted`}>
                                        Задолжително поле
                                    </div>
                                )}

                                {/* <small id="emailHelp" class="Input_ErrorText__vKFee form-text text-muted">Задолжително поле</small> */}
                            {/* <Input
                                label="Држава"
                                name="fax"
                                field={formData.fax}
                                onValueChange={(value) =>
                                    onValueChangeHandler("fax", value)
                                }
                                onInputBlur={() => onInputBlurHandler("fax")}
                            /> */}
                            
                            <Input
                                label="Е-пошта"
                                name="company_email"
                                type="email"
                                maxLength={100}
                                field={formData.company_email}
                                onValueChange={(value) =>
                                    onValueChangeHandler("company_email", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("company_email")
                                }
                            />
                            <Input
                                label="Веб локација"
                                name="web_location"
                                type="string"
                                maxLength={100}
                                field={formData.web_location}
                                onValueChange={(value) =>
                                    onValueChangeHandler("web_location", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("web_location")
                                }
                            />
                            {/* <Input
                                label="Држава"
                                name="country"
                                field={formData.country}
                                onValueChange={(value) =>
                                    onValueChangeHandler("country", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("country")
                                }
                            /> */}
                            <Select
                                field={formData.country}
                                options={countries}
                                label="Одбери држава"
                                name="country"
                                onItemSelected={(value) =>
                                    onValueChangeHandler("country", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("country")
                                }
                            />
                        </div>

                        <div className={classes.Section3}>
                            <div className={classes.File}>
                                <UploadButton
                                    label="Тековна состојба"
                                    labelAbove="Прикачи тековна состојба"
                                    icon={Upload}
                                    class="light-accent-step"
                                    required
                                    uploadedFiles={
                                        formData.document_file?.value
                                            ? (formData.document_file
                                                  ?.value as any)
                                            : []
                                    }
                                    errorMessage={
                                        formData.document_file?.errorMessage
                                    }
                                    touched={formData.document_file?.touched}
                                    onChange={onUploadFileChangeHandler}
                                    onRemove={onImageRemoveHandler}
                                />
                            </div>

                            <div className={classes.Logo}>
                                <UploadButton
                                    label={translations["Прикачи лого"]}
                                    icon={Image}
                                    // labelAbove={
                                    // 	translations[
                                    // 		"Прикачи фотографија (.jpeg, .png до 5Mb)"
                                    // 	]
                                    // }
                                    // hideButton
                                    acceptedFormats={["jpeg", "jpg", "png"]}
                                    autoWidth
                                    maxAllowedMB={5}
                                    // oneLine
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                    }}
                                    // errorMessage={props.product.image?.errorMessage}
                                    // touched={props.product.image?.touched}
                                    uploadedFiles={
                                        formData.logo.value
                                            ? [formData.logo.value]
                                            : []
                                    }
                                    // multiple
                                    onChange={(files) =>
                                        onUploadLogoHandler(files)
                                    }
                                    onRemove={onLogoRemoveHandler}
                                    type="image"
                                />
                            </div>

                            <div className={classes.ReCaptcha}>
                                <div
                                    className={`${classes.Label} ${
                                        !isReCaptchaValid && isClicked
                                            ? classes.Error
                                            : ""
                                    }`}
                                >
                                    Верификационен код <span>*</span>
                                </div>
                                <div className={classes.CodeInput}>
                                    <input
                                        type="text"
                                        value={enteredCaptcha}
                                        onChange={updateCaptcha}
                                    />
                                    <button
                                        type="button"
                                        onClick={validateCaptchaOnClick}
                                    >
                                        Прати
                                    </button>
                                </div>

                                <div className={classes.GeneratedCode}>
                                    <div
                                        className={`${classes.Container} ${classes.Skew}`}
                                    >
                                        <img src={Test} alt="" />
                                        <div
                                            className={classes.Skew}
                                            id="captcha"
                                        ></div>
                                    </div>
                                    <img
                                        src={Refresh}
                                        alt=""
                                        onClick={generateCaptcha}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`pb-4 mt-3`}>
                <h6 className="pb-2 mb-2">
                    Дејност на компанијата{" "}
                    {formData.company_industries.errorMessage &&
                        formData.company_industries.touched && (
                            <small
                                className={`${classes.CompanyIndustryError} form-text text-muted`}
                            >
                                {formData.company_industries.errorMessage}
                            </small>
                        )}
                </h6>

                {checkboxes.map((item, index) => (
                    <div className={classes.MarginSeparator}>
                        <Checkbox
                            label={item.label}
                            value={item.value}
                            checked={item.checked}
                            key={index.toString()}
                            id={index.toString()}
                            onValueChange={onCompanyIndistriesChange}
                        />
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Step1;
