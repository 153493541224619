import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import ModalComponent from "../../UI/ModalComponent/ModalComponent";
import { IPdfProps } from "./model";
import { RootState } from "../../../store";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

const styles = StyleSheet.create({
    viewer: {
        width: "100%",
        height: "80vh"
    },
    page: {
        fontFamily: "Roboto",
        paddingBottom: "18px"
    },
    table: {
        width: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row"
    },
    header: {
        borderTop: "none",
        backgroundColor: "#FAFAFA",
        padding: "10px 5px",
    },
    header_column: {
        padding: "0 5px",
        borderRight: "1px solid #EBEBEB",
        fontSize: "13px",
        fontWeight: "bold",
        height: "100%"
    },
    body: {
        padding: "10px",
        fontSize: "10px",
        fontWeight: "normal",
        overflow: "hidden"
    },
    row1: {
        minWidth: "80px",
        maxWidth: "80px",
        borderBottom: "2px solid #FAFAFA"
    },
    row2: {
        minWidth: "80px",
        maxWidth: "80px",
        borderBottom: "2px solid #FAFAFA"
    },
    row3: {
        minWidth: "100px",
        maxWidth: "100px",
        borderBottom: "2px solid #FAFAFA"
    },
    row4: {
        minWidth: "125px",
        maxWidth: "125px",
        borderBottom: "2px solid #FAFAFA"
    },
    row5: {
        minWidth: "65px",
        maxWidth: "65px",
        borderBottom: "2px solid #FAFAFA",
    },
    row6: {
        minWidth: "80px",
        maxWidth: "80px",
        borderBottom: "2px solid #FAFAFA",
    },
    row7: {
        minWidth: "58px",
        maxWidth: "58px",
        borderBottom: "2px solid #FAFAFA",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 11,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#F9FAFC",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
        padding: "0 10px"
    }
});

const ActiveAdvertisementPdf = (props: IPdfProps) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const onCancelHandler = () => {
        props.onCancel();
    };
    
    const pdf = (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <View style={[styles.row, styles.header]} wrap={false}>
                            <Text style={[styles.row1, styles.header_column]}>
                                {t.current_advertisements.offer_num}
                            </Text>
                            <Text style={[styles.row2, styles.header_column]}>
                                {t.current_advertisements.company}
                            </Text>
                            <Text style={[styles.row3, styles.header_column]}>
                                {t.current_advertisements.offer_name}
                            </Text>
                            <Text style={[styles.row4, styles.header_column]}>
                                {t.current_advertisements.categories}
                            </Text>
                            <Text style={[styles.row5, styles.header_column]}>
                                {t.current_advertisements.start}
                            </Text>
                            <Text style={[styles.row6, styles.header_column]}>
                                {t.current_advertisements.closure}
                            </Text>
                            <Text style={[styles.row7, styles.header_column]}>
                                {t.current_advertisements.auction}
                            </Text>
                        </View>
                        {props.data.map((ad, i) => {
                            return(
                                <View key={i} style={[styles.row]} wrap={true}>
                                    <Text style={[styles.body, styles.row1]}>
                                        {ad?.id}
                                    </Text>
                                    <Text style={[styles.body, styles.row2]}>
                                        {ad?.company}
                                    </Text>
                                    <Text style={[styles.body, styles.row3]}>
                                        {ad?.title}
                                    </Text>
                                    <Text style={[styles.body, styles.row4]}>
                                        {ad?.category}
                                    </Text>
                                    <Text style={[styles.body, styles.row5]}>
                                        {(ad?.start && dayjs(ad?.start).isValid())
                                            ? dayjs(ad.start).format("DD.MM.YYYY")
                                            : ad?.start
                                        }
                                    </Text>
                                    <Text style={[styles.body, styles.row6]}>
                                        {(ad?.end && dayjs(ad?.end).isValid())
                                            ? dayjs(ad.end).format("DD.MM.YYYY")
                                            : ad?.end
                                        }
                                    </Text>
                                    <Text style={[styles.body, styles.row7]}>
                                        {ad?.aukcija ? t.global.yes : t.global.no}
                                    </Text>
                                </View>
                            )
                        })}
                    </View>
                    <View style={styles.pageNumber} fixed>
                        <Text
                            style={{
                                color: "#9F9F9F"
                            }}
                        >
                            {t.global.footer_rights}
                        </Text>
                        <Text
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber}/${totalPages}`
                            )}
                        />
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );

    return (
        <>
            <ModalComponent
                show={true}
                title={t.global.document_overview}
                component={pdf}
                hideactions
                onCancel={onCancelHandler}
                onAccept={() => {}}
            />
        </>
    );
};

export default ActiveAdvertisementPdf;
