import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import ModalComponent from "../../UI/ModalComponent/ModalComponent";
import { IPdfProps } from "./model";
import { RootState } from "../../../store";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

const styles = StyleSheet.create({
    viewer: {
        width: "100%",
        height: "80vh"
    },
    page: {
        fontFamily: "Roboto",
        paddingBottom: "18px"
    },
    table: {
        width: "100%",
    },
    row: {
        display: "flex",
        flexDirection: "row"
    },
    header: {
        borderTop: "none",
        backgroundColor: "#FAFAFA",
        padding: "10px 5px",
        display: "flex",
        alignItems: "center"
    },
    header_column: {
        padding: "0 5px",
        borderRight: "1px solid #EBEBEB",
        fontSize: "13px",
        fontWeight: "bold"
    },
    body: {
        padding: "10px",
        fontSize: "10px",
        fontWeight: "normal",
        overflow: "hidden"
    },
    row1: {
        minWidth: "90px",
        maxWidth: "90px",
        borderBottom: "2px solid #FAFAFA"
    },
    row2: {
        minWidth: "150px",
        maxWidth: "150px",
        borderBottom: "2px solid #FAFAFA"
    },
    row3: {
        minWidth: "155px",
        maxWidth: "155px",
        borderBottom: "2px solid #FAFAFA"
    },
    row4: {
        minWidth: "85px",
        maxWidth: "85px",
        borderBottom: "2px solid #FAFAFA"
    },
    row5: {
        minWidth: "105px",
        maxWidth: "105px",
        borderBottom: "2px solid #FAFAFA",
    },
    pageNumber: {
        position: "absolute",
        fontSize: 11,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#F9FAFC",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
        padding: "0 10px"
    }
});

const MyAdvertisementPdf = (props: IPdfProps) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const onCancelHandler = () => {
        props.onCancel();
    };
    
    const pdf = (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <View style={[styles.row, styles.header]} wrap={false}>
                            <Text style={[styles.row1, styles.header_column]}>
                                {t.advertisements.offer_num}
                            </Text>
                            <Text style={[styles.row2, styles.header_column]}>
                                {t.advertisements.offer_name}
                            </Text>
                            <Text style={[styles.row3, styles.header_column]}>
                                {t.advertisements.categories}
                            </Text>
                            <Text style={[styles.row4, styles.header_column]}>
                                {t.advertisements.closure}
                            </Text>
                            <Text style={[styles.row5, styles.header_column]}>
                                {t.advertisements.auction}
                            </Text>
                        </View>
                        {props.data.map((ad, i) => {
                            return(
                                <View key={i} style={styles.row} wrap={true}>
                                    <Text style={[styles.body, styles.row1]}>
                                        {ad?.ad_id}
                                    </Text>
                                    <Text style={[styles.body, styles.row2]}>
                                        {ad?.title}
                                    </Text>
                                    <Text style={[styles.body, styles.row3]}>
                                        {ad?.category}
                                    </Text>
                                    <Text style={[styles.body, styles.row4]}>
                                        {(ad?.date_auction && dayjs(ad?.date_auction).isValid())
                                            ? dayjs(ad.date_auction).format("DD.MM.YYYY")
                                            : ad?.date_auction
                                        }
                                    </Text>
                                    <Text style={[styles.body, styles.row5]}>
                                        {ad?.auction ? t.global.yes : t.global.no}{"\n"}
                                        {(ad?.total_offers && (ad?.total_offers > 0))
                                            ? `(${t.global.offers} ${ad?.total_offers})`
                                            : ""
                                        }
                                    </Text>
                                </View>
                            )
                        })}
                    </View>
                    <View style={styles.pageNumber} fixed>
                        <Text
                            style={{
                                color: "#9F9F9F"
                            }}
                        >
                            {t.global.footer_rights}
                        </Text>
                        <Text
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber}/${totalPages}`
                            )}
                        />
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );

    return (
        <>
            <ModalComponent
                show={true}
                title={t.global.document_overview}
                component={pdf}
                hideactions
                onCancel={onCancelHandler}
                onAccept={() => {}}
            />
        </>
    );
};

export default MyAdvertisementPdf;
