import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import { IFilters, ISearchParameters, IRangeDate } from "./model";
import { RootState } from "../../../store";
import AdvertisementPdf from "../../../components/Printing/MyAdvertisementsPDF/MyAdvertisementsPDF";

import Search from "./../../../assets/icons/Search.svg";
import Download from "./../../../assets/icons/Download.svg";
import Calendar from "./../../../assets/icons/Calendar.svg";
import CaretDownGray from "./../../../assets/icons/CaretDownGray.svg";

import "react-datepicker/dist/react-datepicker.css";
import "./datePickerCustomStyle.scss";
import classes from "./Filters.module.scss";

const Filters = (props: IFilters) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    let pickerRef:any = React.createRef();

    const t_advertisements = t.advertisements;
    const t_global = t.global;

    const [viewPdf, setViewPdf] = useState<boolean>(false);
    const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
        searchValue: "",
        datumOd: "",
        datumDo: "",
    });
    const [, setDatumOd] = useState<Date | null>(null);
    const [, setDatumDo] = useState<Date | null>(null);
    const [selectedDateRange, setSelectedDateRange] = useState<IRangeDate>({start: null, end: null});

    useEffect(() => {
        const triggerTimeout = setTimeout(() => {
            props.onSearchChange(searchParameters);
        }, 500);

        return () => clearTimeout(triggerTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParameters]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchparameters((state) => {
            return {
                ...state,
                searchValue: event.target.value,
            };
        });
    };

    const previewPdfHandler = () => {
        setViewPdf(true);
    };

    const onCancelHandler = () => {
        setViewPdf(false);
    };

    const CustomDateInput = React.forwardRef(({ value, onClick }:any, ref:any) => {
        return(
            <button
                className={classes.date_wrapper}
                onClick={onClick}
                ref={ref}
            >
                <img src={Calendar} alt="Calendar" />
                <span>{t_advertisements.date}</span>
                <img src={CaretDownGray} alt="Carret down" />
            </button>
        )
    });

    return (
        <React.Fragment>
            <div className={`${classes.header_container}`}>
                <div className={`${classes.title} text-center`}>
                    {t_advertisements.my_ads}
                </div>

                <div className={`${classes.search_bar}`}>
                    <img src={Search} alt="search" className={classes.icon} />
                    <input
                        type="text"
                        placeholder={t_advertisements.search}
                        onChange={onChangeHandler}
                    />
                </div>

                <div className={classes.actions}>
                    {/* <button
                        className={classes.download_list}
                        onClick={previewPdfHandler}
                    >
                        <img src={Download} alt="download" />
                        <span className="">{t_advertisements.download_list}</span>
                    </button> */}
                    <div className={classes.date_container}>
                        <DatePicker
                            ref={pickerRef}
                            selected={selectedDateRange.start}
                            startDate={selectedDateRange.start}
                            endDate={selectedDateRange.end}
                            onChange={(dates) => {
                                const [start, end] = dates;
                                setSelectedDateRange({start, end});
                            }}
                            selectsRange
                            shouldCloseOnSelect={false}
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            popperClassName="date_picker_custom_style"
                            customInput={<CustomDateInput />}
                        >
                            <div>
                                <button
                                    onClick={() => {
                                        setSelectedDateRange({start: null, end: null});
                                        setSearchparameters((state) => {
                                            return {
                                                ...state,
                                                datumOd: "",
                                                datumDo: ""
                                            };
                                        });
                                        pickerRef.current.setOpen();
                                    }}
                                >
                                    {t_global.cancel}
                                </button>
                                <button
                                    onClick={() => {
                                        if (selectedDateRange.start && selectedDateRange.end) {
                                            setDatumOd(selectedDateRange.start);
                                            setDatumDo(selectedDateRange.end);
                                            setSearchparameters((state) => {
                                                return {
                                                    ...state,
                                                    datumOd: selectedDateRange.start || "",
                                                    datumDo: selectedDateRange.end || ""
                                                };
                                            });
                                        }
                                        pickerRef.current.setOpen();
                                    }}
                                >
                                    {t_global.confirm_date}
                                </button>
                            </div>
                        </DatePicker>
                    </div>
                </div>
            </div>

            {viewPdf && (
                <AdvertisementPdf
                    data={props.advertisements}
                    onCancel={onCancelHandler}
                />
            )}
        </React.Fragment>
    );
};

export default Filters;
