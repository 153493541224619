import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./MenuItems.module.scss";

import Home from "./../../../../assets/icons/Home.svg";
import Document from "./../../../../assets/icons/Document.svg";
import Paper from "./../../../../assets/icons/Paper.svg";
import Swap from "./../../../../assets/icons/Swap.svg";
import Logout from "./../../../../assets/icons/Logout.svg";
import CaretRight from "./../../../../assets/icons/CaretRight.svg";
import Work from "./../../../../assets/icons/Work.svg";
import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../../../store/sidebar/sidebar";
import { RoutesEnum } from "../../../../enums/routes";
import { RootState } from "../../../../store";

const MenuItems = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleSidebar = () => {
        dispatch(sidebarActions.toggle());
    };

    const checkIsActive = (val: any) => {
        if (val && val.isActive) return classes.ActiveLink;
        return null;
    };

    const onLogoutHandler = () => {
        toggleSidebar();
        dispatch({ type: "RESET_STORE" });
        navigate(RoutesEnum.Login);
    };


    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "sidemenu"
        ];
    });

    return (
        <React.Fragment>
            <div className={classes.MenuItems}>
                <ul>
                    <li>
                        <NavLink
                            to={RoutesEnum.Advertisements}
                            className={checkIsActive}
                            onClick={toggleSidebar}
                        >
                            <div className={classes.MenuIcon}>
                                <img src={Home} alt="Мои огласи" />
                            </div>
                            <div className={classes.MenuTitle}>
                                {translations["Мои огласи"]}
                            </div>
                            <div className={classes.MenuIconIdicator}>
                                <img src={CaretRight} alt=">" />
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={RoutesEnum.CurrentAdvertisements}
                            className={checkIsActive}
                            onClick={toggleSidebar}
                        >
                            <div className={classes.MenuIcon}>
                                <img src={Document} alt="Тековни огласи" />
                            </div>
                            <div className={classes.MenuTitle}>
                                {translations["Тековни огласи"]}
                            </div>
                            <div className={classes.MenuIconIdicator}>
                                <img src={CaretRight} alt=">" />
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={RoutesEnum.Auction}
                            className={checkIsActive}
                            onClick={toggleSidebar}
                        >
                            <div className={classes.MenuIcon}>
                                <img src={Swap} alt="Аукција" />
                            </div>
                            <div className={classes.MenuTitle}>
                                {translations["Аукција"]}
                            </div>
                            <div className={classes.MenuIconIdicator}>
                                <img src={CaretRight} alt=">" />
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={RoutesEnum.MyOffers}
                            className={checkIsActive}
                            onClick={toggleSidebar}
                        >
                            <div className={classes.MenuIcon}>
                                <img src={Work} alt="My offers" />
                            </div>
                            <div className={classes.MenuTitle}>
                                {translations["Мои понуди"]}
                            </div>
                            <div className={classes.MenuIconIdicator}>
                                <img src={CaretRight} alt=">" />
                            </div>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={RoutesEnum.TermsOfService}
                            className={checkIsActive}
                            onClick={toggleSidebar}
                        >
                            <div className={classes.MenuIcon}>
                                <img src={Paper} alt="Правила и услови" />
                            </div>
                            <div className={classes.MenuTitle}>
                                {translations["Правила и услови"]}
                            </div>
                            <div className={classes.MenuIconIdicator}>
                                <img src={CaretRight} alt=">" />
                            </div>
                        </NavLink>
                    </li>
                    <li
                        className={classes.LogoutButton}
                        onClick={onLogoutHandler}
                    >
                        <div className={classes.MenuIcon}>
                            <img src={Logout} alt="Одјава" />
                        </div>
                        <div className={classes.MenuTitle}>
                            {translations["Одјава"]}
                        </div>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};

export default MenuItems;
