import { getDatabase, ref, set, get, update, onValue, runTransaction, increment, query, push, remove } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";

export const baseHttp = {
	getDatabase,
	ref,
	set,
	get,
	push,
	update,
	onValue,
	runTransaction,
	increment,
	query,
	getStorage,
	storageRef,
	getDownloadURL,
	remove
}