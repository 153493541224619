import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../store";

import classes from "./ConfirmModal.module.scss";

interface IConfirmModal {
    show: boolean,
    title?: string,
    body?: string,
    toggleModal: () => void,
    confirm: () => void
    confirmBtnClass?: string,
    confirmBtnIcon?: JSX.Element,
    confirmBtnText?: string
};

const ConfirmModal = (props:IConfirmModal) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    return(
        <React.Fragment>
            <Modal show={props.show} className={classes.modal_wrapper} centered>
                <Modal.Header className={classes.custom_header}>
                    <Modal.Title>{props?.title || t.create_ad.remove_ad_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={classes.custom_body}>
                    {props?.body || t.create_ad.remove_confirm_text}
                </Modal.Body>
                <Modal.Footer className={classes.custom_footer}>
                    <button className={classes.cancel_btn} onClick={props.toggleModal}>
                        {t.global.cancel}
                    </button>
                    <button className={`${classes.confirm_btn} ${props?.confirmBtnClass}`} onClick={props.confirm}>
                        {props?.confirmBtnIcon} {props?.confirmBtnText || t.global.delete}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ConfirmModal;