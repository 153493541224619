import React from "react";
import classes from "./Confirm.module.scss";

const Confirm = () => {
    return (
        <React.Fragment>
            <div className={`${classes.ConfirmContainer} text-center`}>
                <p>По извршената уплата на годишната претплата која изнесува</p>
                <h3>4000 МКД (+18% ДДВ)</h3>
                <p className="m-0">Вашиот профил ќе биде активиран.</p>
            </div>
        </React.Fragment>
    );
};

export default Confirm;
