import React from "react";
import classes from "./PageFooter.module.scss";

const PageFooter = () => {
    return (
        <React.Fragment>
            <div className={`${classes.CopyrightText} page-footer`}>
                Copyright Ⓒ 2021 Produced by{" "}
                <a href="https://echo.com.mk/" target="_blank" rel="noreferrer">
                    Echo
                </a>
            </div>
        </React.Fragment>
    );
};

export default PageFooter;
