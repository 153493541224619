import React, { useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";

import FileText from "../../../assets/icons/FileText.svg";
import CloseIcon from "../../../assets/icons/CloseAccent.svg";
import PlusIcon from "../../../assets/icons/Plus.svg";
import { httpPost } from "../../../services/http";
import Button from "../../../components/UI/Button/Button";
import { showNotification } from "../../../notification_functions/notifications_functions";

import "react-sliding-pane/dist/react-sliding-pane.css";
import classes from "./SubmitOffer.module.scss";

const SubmitOfferModal = (props) => {

    const { REACT_APP_FILES_TYPE }:any = process.env;

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const [prices, setPrices] = useState<{}>({});
    const [addedDocuments, setAddedDocuments] = useState<{ file_name: string; file_url: string, existing: boolean }[]>([]);
    const [offerComment, setOfferComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const t_global = t.global;
    const t_product = t.product;

    useEffect(() => {
        document.addEventListener("wheel", function(event){
            const doc:any = document;
            if(doc.activeElement.type === "number"){
                doc.activeElement.blur();
            }
        });
    }, []);

    const checkForErrors = (checked_products) => {
        return new Promise((resolve) => {
            const newErrors = {...errors};
            const checkErrors = (products) => {
                Object.keys(products).forEach((key) => {
                    if (typeof products[key] === "object") {
                        checkErrors(products[key]);
                    } else {
                        if ((key === "checked") && (products[key] === true)) {
                            if (products.hasOwnProperty("value") && (products?.value?.length > 0)) {
                                if (products?.value < 100) {
                                    newErrors[products?.id] = t_product.minimum_amount;
                                } else {
                                    delete newErrors[products?.id];
                                }
                            } else {
                                newErrors[products?.id] = t_product.enter_price;
                            }
                        } else {
                            delete newErrors[products?.id];
                        }
                    }
                });
            }
            checkErrors(checked_products);
            setErrors(newErrors);
            resolve(newErrors);
        });
    }

    const returnCheckedProducts = (prods) => {
        return new Promise((resolve) => {
            const products = {};
            const getCheckedProducts = (prod) => {
                Object.keys(prod).forEach((key:any) => {
                    if (typeof prod[key] === "object") {
                        getCheckedProducts(prod[key]);
                    } else {
                        if ((key === "checked") && (prod[key] === true)) {
                            products[prod.id] = prod.value;
                        }
                    }
                });
            }
            getCheckedProducts(prods);
            resolve(products);
        });
    }

    const handleOnSubmit = () => {
        setIsLoading(true);
        checkForErrors(prices).then((err:any) => {
            let products:any = {};
            returnCheckedProducts(prices).then(async (newProducts:any) => {
                products = newProducts;
                if ((Object.keys(newProducts)?.length > 0) && (Object.keys(err)?.length === 0)) {
                    const dataToSend = {
                        tender_id: props.advertisment.id,
                        products,
                        comment: offerComment,
                        documents: addedDocuments.map((doc) => {
                            if(!doc.hasOwnProperty("existing")) {
                                doc["existing"] = true;
                            }
                            return doc;
                        })
                    }
                    try {
                        const response = await httpPost<any, any>('/offers/create?XDEBUG_SESSION_START=phpstorm', dataToSend);
                        setIsLoading(false);
                        if (response.message === "Success") {
                            props.onSidebarClose();
                            props.onConfirm();
                        } else {
                            showNotification(t_product.error_message, "danger");
                        }
                    } catch (error:any) {
                      console.error(error);
                      setIsLoading(false);
                    };
                } else {
                    setIsLoading(false);
                    if (Object.keys(newProducts)?.length === 0) {
                        showNotification(t_product.select_products, "danger");
                    }
                }
            });
        });
    }

    const onDokumentiChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
      
        reader.readAsDataURL(file);

        reader.onload = (e) => {
            if (e.target) {
                let filename = file.name;
                let dataFile = e.target.result as string;
                setAddedDocuments([
                    ...addedDocuments,
                    {
                        file_name: filename,
                        file_url: dataFile,
                        existing: false
                    }
                ]);
            }
        };
    };

    const onDokumentiRemove = (index) => {
        const newlyAddedDocuments = [...addedDocuments];
        if (newlyAddedDocuments[index]) {
            newlyAddedDocuments.splice(index, 1);
            setAddedDocuments(newlyAddedDocuments);
        }
    }

    return(
        <React.Fragment>
            <SlidingPane
                className={classes.modal_style}
                overlayClassName={classes.overlay_style}
                isOpen={props.isPaneOpen}
                title={t_product.offer}
                onRequestClose={props.onSidebarClose}
                from="right"
            >
                <div className={classes.body_wrapper}>
                    <div className={classes.products_wrapper}>
                        {(props?.products && (props?.products.length > 0)) && (
                            props.products.map((product, index) => {
                                return(
                                    <div key={product.id} className={classes.product_wrapper}>
                                        <div className={classes.checkbox_wrapper}>
                                            <label htmlFor={`check_product_${index}`}>
                                                <input
                                                    type="checkbox"
                                                    name={`product_${index}`}
                                                    id={`check_product_${index}`}
                                                    disabled={isLoading}
                                                    onChange={(e) => {
                                                        const newValues = {...prices};
                                                        if (newValues.hasOwnProperty(product?.id)) {
                                                            newValues[product.id] = {
                                                                ...newValues[product.id],
                                                                id: product.id,
                                                                checked: e.target.checked
                                                            }
                                                        } else {
                                                            newValues[product.id] = {
                                                                id: product.id,
                                                                value: "",
                                                                checked: e.target.checked
                                                            }
                                                        }
                                                        setPrices(newValues);
                                                        checkForErrors(newValues);
                                                    }}
                                                />
                                                <span className={classes.checkmark}></span>
                                            </label>
                                        </div>
                                        <div className={classes.product}>
                                            <div className={classes.title}>
                                                {product?.ime}
                                            </div>
                                            <div className={classes.category}>
                                                {product?.kategorija}
                                            </div>
                                            <div className={classes.description}>
                                                {product?.opis}
                                            </div>
                                            <div className={classes.quantity_price}>
                                                <div className={classes.quamtity_wrapper}>
                                                    <div className={classes.label}>
                                                        {t_product.quantity_requested}
                                                    </div>
                                                    <div className={classes.value}>
                                                        {product?.kolicina}
                                                    </div>
                                                </div>
                                                <div className={classes.price_wrapper}>
                                                    <div className={classes.label}>
                                                        {t_product.entered_price}
                                                    </div>
                                                    <div className={`${classes.value} ${errors[product?.id] ? classes.has_error : ""}`}>
                                                        <input
                                                            type="number"
                                                            name="price"
                                                            disabled={isLoading}
                                                            value={prices[product?.id]?.value || ""}
                                                            onChange={(e) => {
                                                                const newPrices = { ...prices };
                                                                if (newPrices.hasOwnProperty(product?.id)) {
                                                                    newPrices[product.id] = {
                                                                        ...newPrices[product.id],
                                                                        value: e.target.value
                                                                    };
                                                                } else {
                                                                    newPrices[product.id] = {
                                                                        id: product?.id,
                                                                        value: e.target.value,
                                                                        checked: false
                                                                    };
                                                                }
                                                                
                                                                setPrices(newPrices);
                                                                checkForErrors(newPrices);
                                                            }}
                                                        />
                                                        <div className={classes.error_text}>{errors[product?.id]}</div>
                                                    </div>
                                                    <div className={classes.currency}>
                                                        {t.currencies.MKD}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                    <div className={classes.additional_info_wrapper}>
                        <div className={classes.additional_docs_wrapper}>
                            <div className={classes.label}>
                                {t_product.additional_docs}
                            </div>
                            <label
                                htmlFor="additionl_doc"
                                className={`${classes.add_doc_btn} ${isLoading ? classes.disabled : ""}`}
                            >
                                <img src={PlusIcon} alt="Add document" />
                                {t_product.add_new_documents}
                                {!isLoading && (
                                    <input
                                        type="file"
                                        name="additionl_doc"
                                        id="additionl_doc"
                                        style={{display: "none"}}
                                        accept={REACT_APP_FILES_TYPE}
                                        onChange={(e) => onDokumentiChange(e)}
                                    />
                                )}
                            </label>
                            <div className={classes.docs_wrapper}>
                                {addedDocuments.map((doc, i) => {
                                    return(
                                        <div key={i} className={classes.doc_wrapper}>
                                            <img className={classes.file_icon} src={FileText} alt="File" />
                                            <div className={classes.file_name}>{doc.file_name}</div>
                                            <img className={classes.close_icon} src={CloseIcon} onClick={() => onDokumentiRemove(i)} alt="Close" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={classes.comment_wrapper}>
                            <div className={classes.label}>
                                {t_product.comment}
                            </div>
                            <label>{t_product.add_comment}</label>
                            <textarea
                                name="comment"
                                rows={5}
                                disabled={isLoading}
                                value={offerComment}
                                placeholder={t_product.comment}
                                onChange={(e) => setOfferComment(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </div>
                
                <div className={classes.footer_wrapper}>
                
                    <div
                        className={classes.close_btn}
                        onClick={props.onSidebarClose}
                    >
                        {t_global.cancel}
                    </div>
                    <Button
                        label={t_product.submit_offer}
                        onClick={handleOnSubmit}
                        loading={isLoading}
                        disabled={(Object.keys(errors).length > 0)}
                    />
                </div>
            </SlidingPane>
        </React.Fragment>
    )
}

export default SubmitOfferModal;