import React, { useEffect, useState } from "react";
import Page from "../../components/Layout/Page/Page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { httpGet } from "../../services/http";

import classes from "./CompanyInfo.module.scss";

const CompanyInfo = () => {
    
    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "company_info"
        ];
    });

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const company_id = searchParams.get("id");

    const [companyDetails, setCompanyDetaild]:any = useState({});

    const BackBtn = () => {
        return(
            <button
                type="button"
                className={classes["back_btn"]}
                onClick={() => navigate(-1)}
            >
                &#60; {translations["back"]}
            </button>
        );
    };

    const webpage = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        return url;
    }

    const fetchCompany = async (company_id) => {
        try {
            const response = await httpGet<any>(`/user/get?user_id=${company_id}`);
            setCompanyDetaild(response.data.user || {});
        } catch (error) {
            setCompanyDetaild({});
            console.error(error);
        };
    };

    useEffect(() => {
        if (company_id) {
            fetchCompany(company_id);
        }
    }, [company_id]);

    return (
        <React.Fragment>
            <Page showBackBtn={BackBtn}>
                <div className={classes.info_title}>
                    {translations["page_info_title"]}
                </div>
                <div className={classes.company_info_wrapper}>
                    <div className={classes.info_wrapper}>
                        <div className={classes.name_label}>
                            {translations["operator_name_label"]}
                        </div>
                        <div className={classes.name}>
                            {companyDetails?.naziv || companyDetails?.random_number_id}
                        </div>
                        {companyDetails?.web_location && (
                            <div className={classes.website}>
                                <a href={webpage(companyDetails?.web_location)} target="_blank" rel="noreferrer">{companyDetails?.web_location}</a>
                            </div>
                        )}
                        <div className={classes.email}>
                            {companyDetails?.company_email}
                        </div>
                        <div className={classes.address}>
                            {companyDetails?.address}
                        </div>
                        <div className={classes.phone}>
                            {companyDetails?.cell_phone_number || companyDetails?.phone_number}
                        </div>
                        <div className={classes.edb_label}>
                            {translations["edb_number_label"]}
                        </div>
                        <div className={classes.edb}>
                            {companyDetails?.edinstven_danocen_broj || ""}
                        </div>
                        <div className={classes.mbeo_label}>
                            {translations["mbeo_number_label"]}
                        </div>
                        <div className={classes.mbeo}>
                            {companyDetails?.maticen_broj_ekonomski_operator || ""}
                        </div>
                        <div className={classes.info_label}>
                            {translations["company_info_label"]}
                        </div>
                        <div className={classes.info}>
                            {companyDetails?.company_info || ""}
                        </div>
                    </div>
                    <div className={classes.image_wrapper}>
                        <div className={classes.image}>
                            <img src={companyDetails?.logo?.file_name} alt="Company img" />
                        </div>
                        <div className={classes.company_name}>
                            {companyDetails?.naziv || companyDetails?.random_number_id}
                        </div>
                    </div>
                </div>
            </Page>
        </React.Fragment>
    );
}

export default CompanyInfo;