import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import ModalComponent from "../../UI/ModalComponent/ModalComponent";
import { IPdfProps } from "./model";
import { RootState } from "../../../store";

Font.register({
    family: "Roboto",
    src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

dayjs.extend(customParseFormat);

const AuctionsPdf = (props: IPdfProps) => {

    const styles = StyleSheet.create({
        viewer: {
            width: "100%",
            height: "80vh"
        },
        page: {
            fontFamily: "Roboto",
            paddingBottom: "18px"
        },
        table: {
            width: "100%",
        },
        row: {
            display: "flex",
            flexDirection: "row"
        },
        header: {
            borderTop: "none",
            backgroundColor: "#FAFAFA",
            padding: "10px 5px",
            display: "flex",
            alignItems: "center"
        },
        header_column: {
            padding: "0 5px",
            borderRight: "1px solid #EBEBEB",
            fontSize: "13px",
            fontWeight: "bold"
        },
        body: {
            padding: "10px",
            fontSize: "10px",
            fontWeight: "normal",
            overflow: "hidden"
        },
        row1: {
            minWidth: (props?.activeTable && (props?.activeTable !== "my_auctions")) ? "85px" : "100px",
            maxWidth: (props?.activeTable && (props?.activeTable !== "my_auctions")) ? "85px" : "110px",
            borderBottom: "2px solid #FAFAFA"
        },
        row2: {
            minWidth: (props?.activeTable && (props?.activeTable !== "my_auctions")) ? "130px" : "170px",
            maxWidth: (props?.activeTable && (props?.activeTable !== "my_auctions")) ? "130px" : "170px",
            borderBottom: "2px solid #FAFAFA"
        },
        row3: {
            minWidth: "90px",
            maxWidth: "90px",
            borderBottom: "2px solid #FAFAFA"
        },
        row4: {
            minWidth: (props?.activeTable && (props?.activeTable !== "my_auctions")) ? "125px" : "160px",
            maxWidth: (props?.activeTable && (props?.activeTable !== "my_auctions")) ? "125px" : "160px",
            borderBottom: "2px solid #FAFAFA"
        },
        row5: {
            minWidth:"70px",
            maxWidth: "70px",
            borderBottom: "2px solid #FAFAFA"
        },
        row6: {
            minWidth: "85px",
            maxWidth: "85px",
            borderBottom: "2px solid #FAFAFA",
        },
        pageNumber: {
            position: "absolute",
            fontSize: 11,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#F9FAFC",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "40px",
            padding: "0 10px"
        }
    });

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const onCancelHandler = () => {
        props.onCancel();
    };
    
    const pdf = (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.table}>
                        <View style={[styles.row, styles.header]} wrap={false}>
                            <Text style={[styles.row1, styles.header_column]}>
                                {t.auctions.auction_number}
                            </Text>
                            <Text style={[styles.row2, styles.header_column]}>
                                {t.auctions.auction_name}
                            </Text>
                            {(props?.activeTable && (props?.activeTable !== "my_auctions")) && (
                                <Text style={[styles.row3, styles.header_column]}>
                                    {t.auctions.company}
                                </Text>
                            )}
                            <Text style={[styles.row4, styles.header_column]}>
                                {t.auctions.categories}
                            </Text>
                            <Text style={[styles.row5, styles.header_column]}>
                                {t.auctions.start}
                            </Text>
                            <Text style={[styles.row6, styles.header_column]}>
                                {t.auctions.closing}
                            </Text>
                        </View>
                        {props.data.map((auction, i) => {
                            return(
                                <View key={i} style={styles.row} wrap={true}>
                                    <Text style={[styles.body, styles.row1]}>
                                        {auction?.id}
                                    </Text>
                                    <Text style={[styles.body, styles.row2]}>
                                        {auction?.title}
                                    </Text>
                                    {(props?.activeTable && (props?.activeTable !== "my_auctions")) && (
                                        <Text style={[styles.body, styles.row3]}>
                                            {auction?.company}
                                        </Text>
                                    )}
                                    <Text style={[styles.body, styles.row4]}>
                                        {auction?.category}
                                    </Text>
                                    <View style={[styles.body, styles.row5]}>
                                        <Text>
                                            {(auction?.start_date_auction && dayjs(auction?.start_date_auction).isValid())
                                                ? dayjs(auction.start_date_auction).format("DD.MM.YYYY")
                                                : auction?.start_date_auction
                                            }
                                        </Text>
                                        <Text
                                            style={{
                                                fontSize: "10px",
                                                color: "#606060"
                                            }}
                                        >
                                            {(auction?.start_time_auction && dayjs(auction?.start_time_auction, "HH:mm:ss").isValid())
                                                ? dayjs(auction.start_time_auction, "HH:mm:ss").format("HH:mm")
                                                : auction?.start_time_auction
                                            }
                                        </Text>
                                    </View>
                                    <View style={[styles.body, styles.row6]}>
                                        {(auction?.end_time_auction && auction?.start_date_auction && dayjs(auction?.start_date_auction).isValid())
                                            ? (
                                                <>
                                                    <Text>{dayjs(auction.start_date_auction).format("DD.MM.YYYY")}</Text>
                                                    <Text
                                                        style={{
                                                            fontSize: "10px",
                                                            color: "#606060"
                                                        }}
                                                    >
                                                        {(auction?.end_time_auction && dayjs(auction?.end_time_auction, "HH:mm:ss").isValid())
                                                            ? dayjs(auction.end_time_auction, "HH:mm:ss").format("HH:mm")
                                                            : auction?.end_time_auction
                                                        }
                                                    </Text>
                                                </>
                                            )
                                            : <Text></Text>
                                        }
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                    <View style={styles.pageNumber} fixed>
                        <Text
                            style={{
                                color: "#9F9F9F"
                            }}
                        >
                            {t.global.footer_rights}
                        </Text>
                        <Text
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber}/${totalPages}`
                            )}
                        />
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );

    return (
        <>
            <ModalComponent
                show={true}
                title={t.global.document_overview}
                component={pdf}
                hideactions
                onCancel={onCancelHandler}
                onAccept={() => {}}
            />
        </>
    );
};

export default AuctionsPdf;
