import React from "react";
import { ITabProps } from "../model/tabs.model";
import classes from "./Tab.module.scss";

const Tab = (
    props: ITabProps
) => {
    const classList = [
        classes.Tab,
    ];

    if (props.active) {
        classList.push(
            classes.Active
        );
    }

    const onClickHandler =
        () => {
            if (props.active)
                return;
            props.onClick(
                props.id 
            );
        };

    return (
        <React.Fragment>
            <li
                className={classList.join(
                    " "
                )}
                onClick={
                    onClickHandler
                }
            >
                <span
                    className={
                        classes.Label
                    }
                >
                    {
                        props.label
                    }
                </span>
                {(props.active && props.labelBadge) && (
                    <span
                        className={
                            classes.Badge
                        }
                    >
                        {
                            props.labelBadge
                        }
                    </span>
                )}

                {props.forceBadge &&
                    !props.active && (
                        <span
                            className={
                                classes.ForcedBadge
                            }
                        >
                            (
                            {
                                props.labelBadge
                            }
                            )
                        </span>
                    )}
            </li>
        </React.Fragment>
    );
};

export default Tab;
