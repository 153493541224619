import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button/Button";

import Close from "./../../../assets/icons/Close.svg";

import classes from "./ModalComponent.module.scss";
import { IModal } from "./model/modal.model";

const ModalComponent = (props: IModal) => {
    const onCancelHandler = () => {
        props.onCancel && props.onCancel();
    };

    const onAcceptHandler = () => {
        props.onAccept();
    };

    return (
        <React.Fragment>
            <Modal
                {...props}
                title=""
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // style={{
                //     maxWidth: "860px",
                //     margin: "auto",
                // }}
            >
                <div className={classes.ModalHeader}>
                    <div className={classes.ModalTitle}>{props.title}</div>
                    <div className={classes.CloseAction}>
                        <button onClick={onCancelHandler}>
                            <img src={Close} alt={Close} />
                        </button>
                    </div>
                </div>
                {props.component && <Modal.Body>{props.component}</Modal.Body>}
                {props.text && (
                    <Modal.Body>
                        <div className="text-center">{props.text}</div>
                    </Modal.Body>
                )}
                {!props.hideactions && (
                    <Modal.Footer>
                        <div className="d-flex w-100">
                                <div className="flex-grow-1 text-end p-2">
                                    {props.onCancel && (
                                        <Button
                                            label="Откажи"
                                            class="accent"
                                            asLink
                                            onClick={onCancelHandler}
                                        />
                                    )}
                                </div>
                            
                            <div
                                className={`${classes.AcceptButton} flex-grow-1 text-start p-2`}
                            >
                                <Button
                                    label="Потврди"
                                    class="accent"
                                    onClick={onAcceptHandler}
                                />
                            </div>
                        </div>
                    </Modal.Footer>
                )}
                {props.footerinfo && (
                    <div className={classes.FooterInfo}>{props.footerinfo}</div>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default ModalComponent;
