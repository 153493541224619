import React from "react";
import classes from "./LoginText.module.scss";

import WhiteLogo from "../../../../assets/WhiteLogo.svg";

const LoginText = () => {
    return (
        <React.Fragment>
            <div className={`${classes.LoginTextContainer} text-center`}>
                <div className={classes.LoginText}>
                    <div className="flex-grow-1">
                        <img src={WhiteLogo} alt={WhiteLogo} />
                        <h1>Advertise your assets</h1>
                        <h1>Buy what you need.</h1>
                        <p>Largest Online Marketplace in Macedonia.</p>
                    </div>
                </div>
                <div className={classes.CopyrightText}>
                    Copyright Ⓒ 2021 Produced by{" "}
                    <a
                        href="https://echo.com.mk/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Echo
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoginText;
