import {
    onValue,
    ref,
} from "firebase/database";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
    MESSAGES,
    MESSAGES_STATUS,
} from "../../../api/config/routes";
import { baseHttp } from "../../../api/http/base";
import { RootState } from "../../../store";
import { IUser } from "../../../store/auth/auth.model";
import ChatFilter from "./ChatFilter/ChatFilter";
import ChatListItem from "./ChatListItem/ChatListItem";
import { IChatList, IChatListViewModel } from "./model/chat-list.model";
import { httpGet } from "../../../services/http";

import classes from "./ChatList.module.scss";

const ChatList = (props: IChatList) => {
    const [chatList, setChatList] = useState<IChatListViewModel[]>([]);
    const [filteredList, setFilteredList] = useState<IChatListViewModel[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [seenHash, setSeenHash] = useState<{ [key: string]: boolean }>({});
    const [loadingFilter, setLoadingFilter] = useState<boolean>(true);

    const { messageId } = useParams();
    const loggedUser:any = useSelector((state: RootState) => state.auth.user.user);
    const userId = parseInt(loggedUser.id);

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "global"
        ];
    });

    const fetchUser = (user_id) => {
        return new Promise(async(resolve) => {
            try {
                const response = await httpGet<any>(`/user/get?user_id=${user_id}`);
                resolve(response.data.user || {});
            } catch (error) {
                resolve({});
              console.error(error);
            };
        });
    };

    useEffect(() => {
        const triggerTimeout = setTimeout(() => {
            setLoadingFilter(true);
            const dbRef = baseHttp.getDatabase();
            const messages = ref(dbRef, MESSAGES);
            const query = baseHttp.query(messages);

            onValue(query, (snapshot) => {
                const allChats = snapshot.val();

                if (!allChats) return;

                let newChatList:any = Object.keys(allChats).map((chatId) => {
                    const msgs = Object.keys(allChats[chatId]).map(
                        (message) => allChats[chatId][message]
                    );
                    
                    return {
                        chatId,
                        messages: msgs,
                        receiver: {},
                        sender: {},
                        senderId: msgs[0].senderId,
                        receiverId: msgs[0].receiverId,
                    } as IChatListViewModel;
                })
                .filter(
                    (chat) =>
                        parseInt(chat.receiverId) === userId || parseInt(chat.senderId) === userId
                );

                newChatList = newChatList.map(async (chat) => {
                    const senderId: number = parseInt(chat.senderId);
                    const receiverId: number = parseInt(chat.receiverId);
                    if (senderId === userId) {
                        return await fetchUser(receiverId)
                            .then((user) => {
                                chat.receiver = user as IUser[] || null;
                                return chat;
                            });
                    } else if (receiverId === userId) {
                        return await fetchUser(senderId)
                            .then((user) => {
                                chat.receiver = user as IUser[] || null;
                                return chat;
                            });
                    }
                });

                Promise.all(newChatList).then((data) => {
                    if (searchValue.length > 0) {
                        const newChat = data.filter((chat) => {
                            return chat?.receiver?.naziv?.toLowerCase().includes(
                                searchValue.toLowerCase()
                            );
                        });
                        setChatList(newChat);
                    } else {
                        setChatList(data);
                    }
                    setLoadingFilter(false);
                });
            });
        }, 500);
        return () => clearTimeout(triggerTimeout);
    }, [userId]);

    useEffect(() => {
        const dbRef = baseHttp.getDatabase();
        const messages = ref(dbRef, MESSAGES_STATUS);
        const query = baseHttp.query(messages);
        
        onValue(query, (statusSnapshot) => {
            const messagesState = statusSnapshot.val();

            let hash = {};

            for (const messageId in messagesState) {
                // aku nekoja od porakite imat status za najaveniot korisnik
                if (messagesState[messageId].hasOwnProperty(userId)) {
                    const isSeen = messagesState[messageId][userId];
                    hash[messageId] = isSeen;
                }
            }

            setSeenHash(hash);
        });
    }, [userId]);

    useEffect(() => {
        setLoadingFilter(true);
        const triggerTimeout = setTimeout(() => {
            if (searchValue.length > 0) {
                const newChat = chatList.filter((chat) => {
                    return chat?.receiver?.naziv?.toLowerCase().includes(
                        searchValue.toLowerCase()
                    );
                });
                setFilteredList(newChat);
            } else {
                setFilteredList(chatList);
            }
            setLoadingFilter(false);
        }, 500);
        return () => clearTimeout(triggerTimeout);
    }, [searchValue]);

    const onFilterChangeHandler = (value: string) => {
        setSearchValue(value);
    };

    return (
        <React.Fragment>
            <div className={classes.Container}>
                <ChatFilter
                    onFilterChange={onFilterChangeHandler}
                />

                <div className={classes.List}>
                    {loadingFilter ? (
                        <div className={classes.loading}>
                            {t.loading}
                        </div>
                    ) : (searchValue.length > 0) ? (
                        (filteredList.length > 0) ? (
                            filteredList.map((chat:any, key) => {
                                if (parseInt(chat?.receiverId) && parseInt(chat.senderId)) {
                                    return (
                                        <ChatListItem
                                            key={key}
                                            chat={chat}
                                            seen={seenHash[chat.chatId]}
                                            isSelected={messageId === chat.chatId}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })
                        ) : (
                            <div className={classes.no_messages}>{t["no_messages"]}</div>
                        )
                    ) : (chatList.length > 0) ? (
                        chatList.map((chat:any, key) => {
                            if (parseInt(chat?.receiverId) && parseInt(chat.senderId)) {
                                return (
                                    <ChatListItem
                                        key={key}
                                        chat={chat}
                                        seen={seenHash[chat.chatId]}
                                        isSelected={messageId === chat.chatId}
                                    />
                                );
                            } else {
                                return null;
                            }
                        })
                    ) : (
                        <div className={classes.no_messages}>{t["no_messages"]}</div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ChatList;
