import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { mk, enGB, sq } from "date-fns/esm/locale";

import CustomPopover from "../../../../../UI/Popover/Popover";
import NotificationsPopover from "../../../../../UI/NotificationsPopover/NotificationsPopover";
import { RoutesEnum } from "../../../../../../enums/routes";
import { baseHttp } from "../../../../../../api/http/base";
import { onValue, ref } from "firebase/database";
import { ADVERTISEMENT } from "../../../../../../api/config/routes";
import { IAdvertisementModel } from "../../../../../../models/data/advertisement.model";
import { RootState } from "../../../../../../store";
import { translationsActions } from "../../../../../../store/translations/translations";

import Notification from "./../../../../../../assets/icons/Notification.svg";
import PaperGray from "./../../../../../../assets/icons/PaperGray.svg";
import MessageGrayIcon from "./../../../../../../assets/icons/MessageGrayIcon.svg";

import classes from "./GlobalActions.module.scss";

import "dayjs/locale/mk";
import "dayjs/locale/en";
import "dayjs/locale/sq";

registerLocale("mk", mk, "en", enGB, "al", sq);

const GlobalActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    navigate(RoutesEnum.Advertisements);
  };

  const [aktivniOglasi, setAktivniOglasi] = useState(0);

  const appLanguage = useSelector(
    (state: RootState) => state.translations.appLanguage
  );

  useEffect(() => {
    const dbRef = baseHttp.getDatabase();
    const advertisements = ref(dbRef, ADVERTISEMENT);
    setDayJsLanguage(appLanguage);
    setDefaultLocale(appLanguage);
    onValue(advertisements, (snapshot) => {
      const objectData = snapshot.val();
      let listData: IAdvertisementModel[] = [];

      if (objectData) {
        listData = Object.keys(objectData)
          .reduce((acc, curr) => {
            acc.push(objectData[curr]);
            return acc;
          }, [] as IAdvertisementModel[])
          .filter((advert) => {
            // proverka dali rok_na_traenje e pogolemo od momentalniot datum
            const now = new Date(dayjs().format("YYYY-MM-DD")).getTime();

            const rok_na_traenje = new Date(
              dayjs(advert.rok_na_traenje).format("YYYY-MM-DD")
            ).getTime();

            return now < rok_na_traenje;
          });
      }

      setAktivniOglasi((state) => listData.length);
    });
  }, []);

  const setDayJsLanguage = (lng) => {
    if (lng === "al") {
      dayjs.locale("sq");
    } else {
      dayjs.locale(lng);
    }
  };

  const onLanguageChangeHandler = () => {
    if (appLanguage === "mk") {
      dispatch(translationsActions.changeLanguage("en"));
      dayjs.locale("en");
      setDefaultLocale("en");
    }

    if (appLanguage === "en") {
      dispatch(translationsActions.changeLanguage("al"));
      dayjs.locale("sq");
      setDefaultLocale("al");
    }

    if (appLanguage === "al") {
      dispatch(translationsActions.changeLanguage("mk"));
      dayjs.locale("mk");
      setDefaultLocale("mk");
    }
  };

  return (
    <React.Fragment>
      <div className={classes.Actions}>
        <button className={classes.ActionButton} onClick={onClickHandler}>
          <img src={PaperGray} alt="paper" />
          <div className={classes.Badge}>{aktivniOglasi}</div>
        </button>
        {/* <button
                    className={classes.ActionButton}
                    onClick={() => navigate(RoutesEnum.Messages)}
                >
                    <img src={MessageGrayIcon} alt="message" />
                </button> */}
        {/* <CustomPopover
                    square
                    icon={Notification}
                    activeIcon={Notification}
                    overlay={<NotificationsPopover />}
                    badgeValue={0}
                /> */}
        <button
          className={classes.ActionButton}
          onClick={onLanguageChangeHandler}
          style={{
            overflow: "hidden",
            border: "4px solid white",
          }}
        >
          {appLanguage === "mk" && (
            <span className={`${classes.LanguageFlag} flag fi fi-mk`}></span>
          )}
          {appLanguage === "en" && (
            <span className={`${classes.LanguageFlag} flag fi fi-gb`}></span>
          )}
          {appLanguage === "al" && (
            <span className={`${classes.LanguageFlag} flag fi fi-al`}></span>
          )}
        </button>
      </div>
    </React.Fragment>
  );
};

export default GlobalActions;
