import { initializeApp } from 'firebase/app';

export const config = {
	firebaseConfig: {
		apiKey: "AIzaSyCwE0zZABQtw8w4OtuxXB6D62FtJNdafnY",
		authDomain: "tender-find.firebaseapp.com",
		databaseURL: "https://tender-find-default-rtdb.europe-west1.firebasedatabase.app",
		projectId: "tender-find",
		storageBucket: "tender-find.appspot.com",
		messagingSenderId: "451253979388",
		appId: "1:451253979388:web:574dbfe6d1e9f45ace6f79"
	}
}

export const initFirebase = () => {
	initializeApp(config.firebaseConfig);
}