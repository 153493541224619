import React from "react";
import Page from "../../../components/Layout/Page/Page";
import classes from "./Register.module.scss";
import { useNavigate } from "react-router-dom";

import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Footer from "./Footer/Footer";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import Confirm from "./Confirm/Confirm";
import { registerActions } from "../../../store/register/register";

import ModalComponent from "../../../components/UI/ModalComponent/ModalComponent";
import PageFooter from "../../../components/Layout/Page/PageFooter/PageFooter";
import axios from "axios";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { post } from "../../../api/http/post";
import { CURRENT_STATE, USERS } from "../../../api/config/routes";

import { v4 } from "uuid";
import { IUser } from "../../../store/auth/auth.model";
import { upload } from "../../../api/http/upload";
import { httpPost } from "../../../services/http";
import Cancel from "./Cancel/Cancel";

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formData = useSelector((state: RootState) => state.register.formData);
    
    const companyIndistries = useSelector((state: RootState) => {
        let checkboxes = state.register.moreInfoCheckboxes;
        let translations =
            state.translations.translations[state.translations.appLanguage][
                "categories"
            ];

        return checkboxes.map((cat) => {
            return {
                ...cat,
                displayLabel: translations[cat.label],
            };
        });
    });

    const activeStep = useSelector(
        (state: RootState) => state.register.activeStep
    );

    const modalOpen = useSelector(
        (state: RootState) => state.register.openModal
    );

    const onModalCancelHandler = () => {
        dispatch(registerActions.closeModal());
    };

    const openCancelModal = useSelector(
        (state: RootState) => state.register.openCancelModal
    );

    const closeCancelModal = () => {
        dispatch(registerActions.closeCancelModal());
    };

    const onModalAcceptHandler = async () => {
        const response = await httpPost<any, any>("/companies/create", formData).then((response) => {
            console.log(response);
        }).finally(() => {
            dispatch(registerActions.closeModal());
            dispatch(registerActions.reset());
			navigate("/login");
		}).catch((error) => {console.log(error)});
    }

    const onCloseModalAcceptHandler = async () => {
            dispatch(registerActions.closeCancelModal());
            dispatch(registerActions.reset());
			navigate("/login");

    }

    // const onModalAcceptHandler = () => {
    //     const { user_email, password } = formData.step2;

    //     const auth = getAuth();
    //     createUserWithEmailAndPassword(auth, user_email.value, password.value)
    //         .then((response) => {
    //             const formValues = {
    //                 ...Object.keys(formData.step1).reduce(
    //                     (acc, curr, ind, arr) => {
    //                         return {
    //                             ...acc,
    //                             [curr]: formData.step1[curr].value,
    //                         };
    //                     },
    //                     {}
    //                 ),
    //                 ...Object.keys(formData.step2).reduce(
    //                     (acc, curr, ind, arr) => {
    //                         return {
    //                             ...acc,
    //                             [curr]: formData.step2[curr].value,
    //                         };
    //                     },
    //                     {}
    //                 ),
    //                 company_industries: companyIndistries
    //                     .filter((industry) => industry.checked)
    //                     .map((industry) => industry.value),
    //             };
    //             console.log(formValues);
    //             const { user_email, password, ...restData } = formValues as any;

    //             const randomId = v4();
    //             const separatedId = randomId.split("-");
    //             const stringId = separatedId[separatedId.length - 1].substring(
    //                 0,
    //                 9
    //             );

    //             // 61cd3cbb3210
    //             const numberId = stringId
    //                 .split("")
    //                 .map((char) => {
    //                     if (!isNaN(+char)) return char;
    //                     if (char === "a") return 1;
    //                     if (char === "b") return 2;
    //                     if (char === "c") return 3;
    //                     if (char === "d") return 4;
    //                     if (char === "e") return 5;
    //                     if (char === "f") return 6;
    //                     if (char === "g") return 7;
    //                     if (char === "h") return 8;
    //                     if (char === "i") return 9;

    //                     return Math.round(Math.random() * 10);
    //                 })
    //                 .join("");

    //             const postData = {
    //                 ...restData,
    //                 random_number_id: numberId,
    //             } as IUser;

    //             return post<IUser>(
    //                 `${USERS}/${response.user.uid}`,
    //                 postData as IUser
    //             ).then(() => response.user.uid);
    //         })
    //         .then((userId) => {
    //             const file = formData.step1.document_file!.value![0];
    //             const logo = formData.step1.logo!.value!;

    //             return Promise.all([
    //                 upload(file, file.id)
    //                     .then(() => {
    //                         console.log("file uploaded");
    //                     })
    //                     .catch((err) => {
    //                         console.log("file error", err);
    //                     })
    //                     .finally(() => {
    //                         console.log("file finally");
    //                     }),
    //                 upload(logo, logo.id)
    //                     .then(() => {
    //                         console.log("logo uploaded");
    //                     })
    //                     .catch((err) => {
    //                         console.log("logo error", err);
    //                     })
    //                     .finally(() => {
    //                         console.log("logo finally");
    //                     }),
    //             ]);
    //         })
    //         .then(() => {
    //             navigate("/");
    //             dispatch(registerActions.reset());
    //         })
    //         .catch((error) => {});

    //     dispatch(registerActions.closeModal());
    // };

    let step = <Step1 />;

    if (activeStep !== 1) {
        step = <Step2 />;
    }

    return (
        <React.Fragment>
            <Page
                pageTitle="Регистрација на домашен економски оператор"
                hideSidebar
            >
                <div className={`${classes.RegisterContainer}`}>
                    <form>
                        {step}
                        <Footer />
                        
                        <ModalComponent
                            show={modalOpen}
                            title="Потврда"
                            footerinfo="(За активација на профилот проверете ја вашата електронска пошта.)"
                            component={<Confirm />}
                            onCancel={onModalCancelHandler}
                            onAccept={onModalAcceptHandler}
                        />

                        <ModalComponent
                            show={openCancelModal}
                            title="Потврда"
                            footerinfo="(Напомена: Внесените податоци не се запамтени и профилот не е креиран успешно)"
                            component={<Cancel />}
                            onCancel={closeCancelModal}
                            onAccept={onCloseModalAcceptHandler}
                        />

                    </form>
                </div>
            </Page>
            <PageFooter />
        </React.Fragment>
    );
};

export default Register;
