import React from "react";
import classes from "./RegisterNavbar.module.scss";
import Logo from "./../../../../../assets/Logo_register.svg";
import Phone from "./../../../../../assets/icons/Phone.svg";

import { useDispatch } from "react-redux";
import { sidebarActions } from "../../../../../store/sidebar/sidebar";
import Hamburger from "../../../../UI/Hamburger/Hamburger";
import { IPrimaryNavbar } from "../models/primary-navbar.model";

const RegisterNavbar = (props: IPrimaryNavbar) => {
    const dispath = useDispatch();

    const toggleSidebarHandler = () => {
        dispath(sidebarActions.toggle());
    };

    return (
        <React.Fragment>
            <div className={classes.PrimaryNavbar}>
                <div className="container h-100">
                    <div className={classes.PrimaryNavbarContent}>
                        <div className="flex-grow-1">
                            <a href="/login" >
                                <img
                                    className={classes.LogoShort}
                                    src={Logo}
                                    alt={Logo}
                                />
                            </a>
                        </div>
                        <div className="d-none d-md-block">
                            <img src={Phone} alt={Phone} />
                            <span className={classes.PhoneContact}>
                                +389111222333
                            </span>
                            <span className={classes.PhoneContact}>
                                +389712389001
                            </span>
                        </div>
                        {!props.hideSidebar && (
                            <div className="d-block d-md-none">
                                <Hamburger
                                    onClick={toggleSidebarHandler}
                                    mode={"open"}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default RegisterNavbar;
