export const MONTHS = [
	{ label: 'Јануари', month: 1 },
	{ label: 'Февруари', month: 2 },
	{ label: 'Март', month: 3 },
	{ label: 'Април', month: 4 },
	{ label: 'Мај', month: 5 },
	{ label: 'Јуни', month: 6 },
	{ label: 'Јули', month: 7 },
	{ label: 'Август', month: 8 },
	{ label: 'Септември', month: 9 },
	{ label: 'Октомври', month: 10 },
	{ label: 'Ноември', month: 11 },
	{ label: 'Декември', month: 12 },
]

export const DAYS = [
	'Нед',
	'Пон',
	'Вто',
	'Сре',
	'Чет',
	'Пет',
	'Саб',
]

export const daysHash = {
	0: "Пон",
	1: "Вто",
	2: "Сре",
	3: "Чет",
	4: "Пет",
	5: "Саб",
	6: "Нет",
};

export const YEARS = new Array(10).fill(2021).map((year, index) => year + index)