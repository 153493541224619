import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as FileSaver from "file-saver";

import Page from "../../../components/Layout/Page/Page";
import Header from "./components/Header/Header";
import ProductsList from "./components/ProductsList/ProductsList";
import { RootState } from "../../../store";
import EditOfferModal from "./components/EditOfferModal/EditOffer";
import { httpGet } from "../../../services/http";
import { IOfferModel } from "./offer-preview.model";

import CloseCircleRed from "../../../assets/icons/close_circle_red.svg";
import CheckmarkCircleGreen from "../../../assets/icons/checkmark_circle_green.svg";
import AuctionCircleBlue from "../../../assets/icons/auction_circle_blue.svg";
import ClockCircleYello from "../../../assets/icons/clock_circle_yello.svg";
import CheckmarkCircleBlue from "../../../assets/icons/checkmark_circle_blue.svg";
import SleshCircleGray from "../../../assets/icons/slesh_circle_gray.svg";

import classes from "./OfferPreview.module.scss";

dayjs.extend(customParseFormat);

const AdvertisementPreview = () => {
    const { id }:any = useParams();
    const navigate = useNavigate();

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const t_global = t.global;
    const t_offers = t.offers;

    const [offer, setOffer]:any = useState<IOfferModel | {}>({});

    const [openSidebar, setOpenSidebar] = useState<boolean>(false);
    const [showEditOffer, setShowEditOffer] = useState<boolean>(false);

    const fetchOffer = async () => {
        setOffer({});
        try {
            const response = await httpGet<any>(`offer/view/${id}?&XDEBUG_SESSION_START=phpstorm`);
            const offer = response?.data?.data;
            setOffer(offer || {});


            const offer_status = offer?.offer_status;
            const auction_date = offer?.datum_oddrzuvanje_aukcija;
            const auction_time = offer?.vreme_oddrzuvanje_aukcija_do;
            let auction:any = null;
            if (auction_date && auction_time && dayjs(`${auction_date} ${auction_time}:00`).isValid()) {
                auction = dayjs(`${auction_date} ${auction_time}:00`).format("YYYY-MM-DD HH:mm:ss");
            }

            if (offer_status && (offer_status === "1") && auction && dayjs(auction).isAfter(dayjs())) {
                setShowEditOffer(true);
            } else {
                setShowEditOffer(false);
            }
        } catch (error) {
            setShowEditOffer(false);
            console.error(error);
        };
    }

    useEffect(() => {
        fetchOffer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const BackBtn = () => {
        let status = "default";
        let icon = "";
        switch (offer?.offer_status) {
            case "1":
                status = "in_progress";
                icon = ClockCircleYello;
                break;
            case "2":
                status = "auction";
                icon = AuctionCircleBlue;
                break;
            case "3":
                status = "accepted";
                icon = CheckmarkCircleGreen;
                break;
            case "4":
                status = "declined";
                icon = CloseCircleRed;
                break;
            case "5":
                status = "canceled";
                icon = SleshCircleGray;
                break;
            case "6":
                status = "finished";
                icon = CheckmarkCircleBlue;
                break;
            default:
                break;
        }
        return(
            <div className={classes.header_content_container}>
                <button
                    type="button"
                    className={classes["back_btn"]}
                    onClick={() => navigate(-1)}
                >
                    &#60; {t_global["back"]}
                </button>
                {(status !== "default") && (
                    <div className={`${classes.offer_status} ${classes[status]}`}>
                        <div className={classes.image_wrapper}>
                            <img src={icon} alt="Status icon" />
                        </div>
                        {t_offers[`offer_${status}`]} {(status === "auction") && (
                            dayjs(offer?.datum_oddrzuvanje_aukcija).isValid()
                                ? dayjs(offer?.datum_oddrzuvanje_aukcija).format("DD.MM.YYYY")
                                : offer?.datum_oddrzuvanje_aukcija
                        )}
                    </div>
                )}
            </div>
        );
    };

    const downloadFile = (file, file_name) => {
        if (file && file_name) {
            FileSaver.saveAs(file, file_name);
        }
    };

    return (
        <Page showBackBtn={BackBtn}>
            <div
                className={classes.ad_preview_wrapper}
                style={showEditOffer ? {marginBottom: "13px"} : {}}
            >
                <React.Fragment>
                    <Header
                        offer_id={id}
                        ad_id={offer?.id}
                        date={offer?.offer_created || ""}
                        offer={offer || {}}
                        company={{
                            name: offer?.naziv,
                            web: offer?.web_location,
                            email: offer?.email,
                            phone: offer?.company_number,
                            location: `${offer?.company_address || ""} ${offer?.company_city || ""}`,
                            logo: offer?.logo
                        }}
                        offer_status={offer?.offer_status}
                    />
                    <div className={classes.contact_info_wrapper}>
                        <div className={classes.title}>
                            {t_offers.contact_person}
                        </div>
                        <div className={classes.info_wrapper}>
                            <div className={classes.name}>
                                {offer?.contact_person}
                            </div>
                            <div className={classes.email}>
                                {offer?.contact_email}
                            </div>
                            <div className={classes.phone}>
                                {offer?.contact_phone}
                            </div>
                        </div>
                    </div>
                    <div className={classes.ads_wrapper}>
                        <div className={classes.title}>
                            {t_offers.offer_title}
                        </div>
                        <ProductsList
                            products={offer?.products || []}
                            editOffer={!showEditOffer}
                        />
                    </div>
                    <div className={classes.time_criteria_wrapper}>
                        <div className={classes.title}>
                            {t_offers.time_criteria_title}
                        </div>
                        <div className={classes.content_wrapper}>
                            <div className={classes.date_wrapper}>
                                <div className={classes.title}>
                                    {t_offers.delivery_deadline}
                                </div>
                                <div className={classes.date}>
                                    {dayjs(offer?.datum_isporaka).isValid()
                                        ? dayjs(offer?.datum_isporaka).format("DD.MM.YYYY")
                                        : offer?.datum_isporaka
                                    }
                                </div>
                            </div>
                            <div className={classes.criteria_wrapper}>
                                <div className={classes.title}>
                                    {t_offers.criteria_title}
                                </div>
                                <div className={classes.criteria}>
                                    {t_offers[`criteria_${offer?.kriterium}`]}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.auction_wrapper}>
                        <div className={classes.title}>
                            {t_offers.auction}
                        </div>
                        <div className={classes.content_wrapper}>
                            <div className={classes.auction_container}>
                                <div className={classes.title}>
                                    {t_offers.auction}
                                </div>
                                <div className={classes.on_auction}>
                                    {(offer?.auction)
                                        ? t_global.yes
                                        : t_global.no
                                    }
                                </div>
                            </div>
                            <div className={classes.delivery_deadline_wrapper}>
                                <div className={classes.title}>
                                    {t_offers.auction_date}
                                </div>
                                <div className={classes.date_time}>
                                    {dayjs(offer?.datum_oddrzuvanje_aukcija).isValid()
                                        ? dayjs(offer?.datum_oddrzuvanje_aukcija).format("DD.MM.YYYY")
                                        : offer?.datum_oddrzuvanje_aukcija
                                    }
                                    <span className={classes.vertical_line}>|</span>
                                    {dayjs(offer?.vreme_oddrzuvanje_aukcija_od, "HH:mm:ss").isValid()
                                        ? dayjs(offer?.vreme_oddrzuvanje_aukcija_od, "HH:mm:ss").format("HH:mm")
                                        : offer?.vreme_oddrzuvanje_aukcija_od
                                     } - {dayjs(offer?.vreme_oddrzuvanje_aukcija_do, "HH:mm:ss").isValid()
                                        ? dayjs(offer?.vreme_oddrzuvanje_aukcija_do, "HH:mm:ss").format("HH:mm")
                                        : offer?.vreme_oddrzuvanje_aukcija_do
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.admin_info_wrapper}>
                        <div className={classes.title}>
                            {t_offers.administrative_info_title}
                        </div>
                        <div className={classes.content_wrapper}>
                            <div className={classes.info_container}>
                                <div className={classes.title}>
                                    {t_offers.offers_submit}
                                </div>
                                <div className={classes.date_time}>
                                    {dayjs(offer?.datum_za_dostavuvanje).isValid()
                                        ? dayjs(offer?.datum_za_dostavuvanje).format("DD.MM.YYYY")
                                        : offer?.datum_za_dostavuvanje
                                    }
                                    <span className={classes.vertical_line}>|</span>
                                    {dayjs(offer?.dostavuvanje_ponudi_vreme, "HH:mm:ss").isValid()
                                        ? dayjs(offer?.dostavuvanje_ponudi_vreme, "HH:mm:ss").format("HH:mm")
                                        : offer?.dostavuvanje_ponudi_vreme
                                    }
                                </div>
                            </div>
                            <div className={classes.questions_wrapper}>
                                <div className={classes.title}>
                                    {t_offers.questions_dedline}
                                </div>
                                <div className={classes.date_time}>
                                    {dayjs(offer?.datum_za_prasanja).isValid()
                                        ? dayjs(offer?.datum_za_prasanja).format("DD.MM.YYYY")
                                        : offer?.datum_za_prasanja
                                    }
                                    <span className={classes.vertical_line}>|</span>
                                    {dayjs(offer?.vreme_za_prasanja, "HH:mm:ss").isValid()
                                        ? dayjs(offer?.vreme_za_prasanja, "HH:mm:ss").format("HH:mm")
                                        : offer?.vreme_za_prasanja
                                    }
                                </div>
                            </div>
                            <div className={classes.answers_wrapper}>
                                <div className={classes.title}>
                                        {t_offers.answers_dedline}
                                    </div>
                                    <div className={classes.date_time}>
                                        {dayjs(offer?.datum_za_odgovori).isValid()
                                            ? dayjs(offer?.datum_za_odgovori).format("DD.MM.YYYY")
                                            : offer?.datum_za_odgovori
                                        }
                                        <span className={classes.vertical_line}>|</span>
                                        {dayjs(offer?.vreme_za_odgovori, "HH:mm:ss").isValid()
                                            ? dayjs(offer?.vreme_za_odgovori, "HH:mm:ss").format("HH:mm")
                                            : offer?.vreme_za_odgovori
                                        }
                                    </div>
                                </div>
                        </div>
                    </div>
                    {(offer?.pravni_dokumenti && (offer?.pravni_dokumenti?.length > 0)) && (
                        <div className={classes.tehnical_info_wrapper}>
                            <div className={classes.title}>
                                {t_offers.tehnical_info_title}
                            </div>
                            <div className={classes.docs_wrapper}>
                                {offer.pravni_dokumenti.map((doc, index) => {
                                    return(
                                        <div className={classes.doc_wrapper} key={index}>
                                            <div className={classes.icon}></div>
                                            <div
                                                className={classes.file_name}
                                                onClick={() => downloadFile(doc.file_url, doc.file_name)}
                                            >
                                                {doc?.file_name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {(offer?.dopolnitelni_dokumenti && (offer?.dopolnitelni_dokumenti?.length > 0)) && (
                        <div className={classes.additional_docs_wrapper}>
                            <div className={classes.title}>
                                {t_offers.additional_docs}
                            </div>
                            <div className={classes.docs_wrapper}>
                                {offer?.dopolnitelni_dokumenti.map((doc, index) => {
                                    return (
                                        <div key={index} className={classes.doc_wrapper}>
                                            <div className={classes.icon}></div>
                                            <div
                                                className={classes.file_name}
                                                onClick={() => downloadFile(doc.file_url, doc.file_name)}
                                            >
                                                {doc.file_name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            </div>
            {(showEditOffer) && (
                <div className={classes.submit_offer}>
                    <div
                        className={classes.cancel_btn}
                        onClick={() => setShowEditOffer(false)}
                    >
                        {t_global.cancel}
                    </div>
                    <div
                        className={classes.submit_btn}
                        onClick={() => { setOpenSidebar(true) }}
                    >
                        {t_offers.edit_offer}
                    </div>
                </div>
            )}
            <EditOfferModal
                isPaneOpen={openSidebar}
                onSidebarClose={() => setOpenSidebar(false)}
                products={offer?.products}
                offer_id={id}
                comment={offer?.comment}
            />
        </Page>
    );
};

export default AdvertisementPreview;
