import React, { useState } from "react";
import Input from "../../../../components/UI/Input/Input";
import classes from "./Step2.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { IRegister } from "../../../../store/register/register.model";
import { registerActions } from "../../../../store/register/register";

import * as _ from "lodash";
import { IBaseField } from "../../../../models/field-validation.model";
import Switch from "../../../../components/UI/Switch/Switch";
import PhoneInput from 'react-phone-number-input'
import FloatInput from "../../../../components/UI/FloatInput/FloatInput";
import ViewIcon from "./../../../../assets/icons/ViewIcon.svg";
import ViewOffIcon from "./../../../../assets/icons/ViewOffIcon.svg";

export const Step2 = () => {
    const dispatch = useDispatch();
    const [inputType, setInputType] = useState("password");
    const [inputConfirmType, setInputConfirmType] = useState("password");
    
    const toggleInputType = () => {
        setInputType((state) => {
            if (state === "password") return "text";
            else return "password";
        });
    };

    const toggleInputConfirmType = () => {
        setInputConfirmType((state) => {
            if (state === "password") return "text";
            else return "password";
        });
    };

    const isChecked = useSelector(
        (state: RootState) => state.register.formData.newsletter_email
    );

    const onNewsletterChangeHandler = () => {
        dispatch(registerActions.toggle());
    };

    const step2FormData = useSelector(
        (state: RootState) => state.register.formData.step2
    );
    const formData = _.cloneDeep(step2FormData);

    const passwordMatchedChecker = (name, value) => {
        onValueChangeHandler("confirm_password", value);
        
        if (formData['password'].value != value) {
            dispatch(
                registerActions.updateStep2FieldErrorMessage({
                    name,
                    errorMessage: "Шифрите не се исти",
                })
            );
        } else {
            dispatch(
                registerActions.updateStep2FieldErrorMessage({
                    name,
                    errorMessage: null,
                })
            );
        }
    }

    const onValueChangeHandler = (name, value) => {
        const field = formData[name] as IBaseField<any>;
        const truthy = !!value ?? true;

        if (field.validators?.required && !truthy) {
            dispatch(
                registerActions.updateStep2FieldErrorMessage({
                    name,
                    errorMessage: "Задолжително поле",
                })
            );
        } else {
            dispatch(
                registerActions.updateStep2FieldErrorMessage({
                    name,
                    errorMessage: null,
                })
            );
        }

        dispatch(
            registerActions.updateStep2FieldValue({
                name,
                value,
            })
        );
    };

    const onInputBlurHandler = (name: string) => {
        dispatch(registerActions.markStep2FieldAsTouched({ name }));
    };

    return (
        <React.Fragment>
            <div className={`${classes.StepSection1} mb-4`}>
                <h6 className={`${classes.StepTitle} pb-2 mb-4`}>
                    Регистрација на администратор
                </h6>
                <div className={classes.StepFormControls}>
                    <div className={`${classes.HeightSetter} row`}>
                        <div className="col-12 col-md-6">
                            <Input
                                label="Име"
                                placeholder="Име на администраторот"
                                name="first_name"
                                field={formData.first_name}
                                onValueChange={(value) =>
                                    onValueChangeHandler("first_name", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("first_name")
                                }
                            />
                            <Input
                                label="Презиме"
                                placeholder="Презиме на администраторот"
                                name="last_name"
                                field={formData.last_name}
                                onValueChange={(value) =>
                                    onValueChangeHandler("last_name", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("last_name")
                                }
                            />
                             <label>Број на телефон 
                                    <span className={classes.RequiredIndicator}>*</span> 
                                </label>
                                <PhoneInput
                                    className={formData.user_phone_number?.touched && formData.user_phone_number?.errorMessage ? classes.PhoneInputError : classes.PhoneInput}
                                    international
                                    countryCallingCodeEditable={false}
                                    name="user_phone_number"
                                    placeholder="Enter phone number"
                                    defaultCountry="MK"
                                    onChange={(value) =>
                                        onValueChangeHandler("user_phone_number", value)
                                    }
                                    onBlur={() =>
                                        onInputBlurHandler("user_phone_number")
                                    }
                                />
                                {formData.user_phone_number?.touched && formData.user_phone_number?.errorMessage && (
                                    <div id="phoneNumberHelp"className={`${classes.PhoneInputErrorText} form-text text-muted`}>
                                        Задолжително поле
                                    </div>
                                )}
                            {/* <Input
                                label="Мобилен телефон"
                                name="cell_phone_number"
                                field={formData.cell_phone_number}
                                onValueChange={(value) =>
                                    onValueChangeHandler(
                                        "cell_phone_number",
                                        value
                                    )
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("cell_phone_number")
                                }
                            /> */}
                            {/* <Input
                                label="ЕМБГ или 13 цифри"
                                name="ssn"
                                field={formData.ssn}
                                onInputChange={onInputChangeHandler}
                                onInputBlur={onInputBlur}
                            /> */}
                        </div>

                        <div className="col-12 col-md-6">
                            {/* <Input
                                label="Број на телефон"
                                name="phone_number"
                                field={formData.cell_phone_number}
                                onInputChange={onInputChangeHandler}
                                onInputBlur={onInputBlur}
                            /> */}
                            {/* <Input
                                label="Корисничко име"
                                placeholder="Корисничко име на администраторот"
                                name="username"
                                field={formData.username}
                                onInputChange={onInputChangeHandler}
                                onInputBlur={onInputBlur}
                            /> */}
                            <Input
                                label="Е-пошта"
                                name="user_email"
                                type="email"
                                field={formData.user_email}
                                onValueChange={(value) =>
                                    onValueChangeHandler("user_email", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("user_email")
                                }
                            />
                            <Input
                                label="Лозинка"
                                placeholder="Лозинка"
                                name="password"
                                type={inputType}
                                field={formData.password}
                                onValueChange={(value) =>
                                    onValueChangeHandler("password", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("password")
                                }
                                actionIcon={
                                    inputType === "password"
                                        ? ViewOffIcon
                                        : ViewIcon
                                }
                                onActionIconClick={toggleInputType}
                            />
                            <Input
                                label="Потврди лозинка"
                                placeholder="Потврди лозинка"
                                name="confirm_password"
                                type={inputConfirmType}
                                field={formData.confirm_password}
                                onValueChange={(value) =>
                                    passwordMatchedChecker("confirm_password", value)
                                }
                                onInputBlur={() =>
                                    onInputBlurHandler("confirm_password")
                                }
                                actionIcon={
                                    inputConfirmType === "password"
                                        ? ViewOffIcon
                                        : ViewIcon
                                }
                                onActionIconClick={toggleInputConfirmType}
                            />
                           
                        </div>
                        <hr/>
                        <div className="col-12">
                            <h6 className="pb-2 mb-4">
                                Инфо за нови огласи
                            </h6>
                            <Switch
                                label="Сакам да добивам известувања за сите нови огласи на мојот е-маил"
                                switchPosition="start"
                                name="newsletter_email"
                                checked={isChecked}
                                onChange={onNewsletterChangeHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Step2;
