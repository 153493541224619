import React from "react";
import classes from "./Checkbox.module.scss";
import { ICheckbox } from "./model/Checkbox.model";

const Checkbox = (props: ICheckbox) => {
    let classList = [classes.Check, "form-check-input"];

    if (props.class) {
        classList.push(classes[props.class]);
    }

    if (props.large) {
        classList.push(classes.Large);
    }

    if (props.lightText) {
        classList.push(classes.LightText);
    }

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(event.target.checked);
        console.log(event.target.value);
        props.onValueChange && props.onValueChange(event.target.value);
        props.onValueChangeBoolean &&
            props.onValueChangeBoolean(event.target.checked);
    };

    return (
        <React.Fragment>
            <div className={`${classes.Checkbox} form-check`}>
                <input
                    className={classList.join(" ")}
                    type="checkbox"
                    value={props.value}
                    checked={props.checked}
                    id={`${props.id}`}
                    onChange={onCheckboxChange}
                    style={props.style}
                />
                {props.label && (
                    <label
                        className={`${
                            props.large ? classes.LargeLabel : classes.Label
                        } form-check-label`}
                        htmlFor={props.id}
                    >
                        {props.label}
                    </label>
                )}

                {props.projectedLabel}
            </div>
        </React.Fragment>
    );
};

export default Checkbox;
