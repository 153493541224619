import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { RootState } from "../../../../store";
import ModalComponent from "../../ModalComponent/ModalComponent";
import InvalidFilesUpload from "./InvalidFilesUpload/InvalidFilesUpload";
import { IFile, IFileModel, IUploadButton } from "./model/UploadButton.model";
import classes from "./UploadButton.module.scss";

import UploadedFile from "./UploadedFileList/UploadedFile";

const UploadButton = (props: IUploadButton) => {
    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "components"
            ]
    );

    const fileList: any = props.uploadedFiles?.filter((e) => e).length
        ? props.uploadedFiles
              .filter((e) => e)
              .map(
                  (file) =>
                      new File([file.blob], file.name, { type: file.fileType })
              )
        : null;

    const [files, setFiles] = useState<FileList | null>(fileList);
    const [showModal, setShowModal] = useState(false);
    const [invalidFiles, setInvalidFiles] = useState<string[]>([]);
    const [previewLogo, setPreviewLogo] = useState<any>(null);

    useEffect(() => {
        const fileList: any = props.uploadedFiles?.filter((e) => e).length
            ? props.uploadedFiles
                  .filter((e) => e)
                  .map(
                      (file) =>
                          new File([file.blob], file.name, {
                              type: file.fileType,
                          })
                  )
            : null;

        if (props?.type && props?.uploadedFiles && (props?.uploadedFiles?.length > 0)) {
            const file:any = props?.uploadedFiles[0];
            if (file?.blob) {
                setPreviewLogo(file.blob);
            } else {
                setPreviewLogo(null);
            }
        } else {
            setPreviewLogo(null);
        }

        setFiles(fileList);
    }, [props.uploadedFiles]);

    useEffect(() => {
        if (invalidFiles.length) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [invalidFiles.length]);

    const uploadFileRef = useRef<HTMLInputElement>(null);
    let classList = [classes.Button, "btn"];
    classList.push(props.class ? classes[props.class] : classes.accent);
    let labelClasses: any = [];

    if (props.autoWidth) {
        classList.push(classes.AutoWidth);
    }

    if (props.icon) {
        labelClasses.push(classes.IconLabel);
    }

    if (props.hideLabelOnSmallScren) {
        labelClasses.push(classes.InnerLabel);
    }

    const onButtonClickHandler = () => {
        uploadFileRef.current?.click();
    };

    const fileToDataURL = (file: File) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onload = function (event) {
                resolve({
                    id: v4(),
                    parentId: "",
                    blob: event.target?.result,
                    name: file.name,
                    type: file.name.split(".").pop(),
                    fileType: file.type,
                    size: file.size,
                    File: file,
                } as IFileModel);
            };
            reader.readAsDataURL(file);
        });
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        const filesArray = Array.prototype.slice.call(files);

        // validiraj prifateni formati na file
        if (props.acceptedFormats?.length) {
            const invalidFilesList: string[] = filesArray
                .map((file: File) => {
                    // proveri dali file-ot e nevaliden
                    const fileType = file.name.split(".").pop();
                    const match = props.acceptedFormats?.find(
                        (format) => format === fileType
                    );

                    if (!match) {
                        return file.name;
                    }

                    return "";
                })
                .filter((fileName) => fileName !== "");

            if (invalidFilesList.length) {
                setInvalidFiles(invalidFilesList);
            }
        }

        Promise.all(filesArray.map(fileToDataURL)).then((res: any[]) => {
            if (!invalidFiles.length) {
                setFiles(files);
                props.onChange &&
                    props.onChange(
                        res.map((file) => {
                            return {
                                id: v4(),
                                ...file,
                            };
                        }) as IFile[]
                    );

                event.target.value = "";
            }
        });
    };

    const removeFileHandler = (index: number): void => {
        props.onRemove && props.onRemove(index);
    };

    const onModalCancelHandler = () => {
        setShowModal(false);
    };

    const onModalAcceptHandler = () => {
        setShowModal(false);
    };

    return (
        <React.Fragment>
            <div className={classes.UploadButton}>
                <div className="button-container">
                    {props.labelAbove && (
                        <label className={classes.Label}>
                            {props.labelAbove}{" "}
                            {props.required && (
                                <span className={classes.RequiredAsterisk}>
                                    *
                                </span>
                            )}
                        </label>
                    )}
                    {!props.hideButton && (
                        <React.Fragment>
                            {(previewLogo) && (
                                <div className={classes.icon_preview_wrapper}>
                                    <img
                                        src={previewLogo}
                                        alt="Logo"
                                        className={classes.icon_preview_image}
                                    />
                                </div>
                            )}
                            <button
                                type="button"
                                className={classList.join(" ")}
                                onClick={onButtonClickHandler}
                                style={props.style}
                            >
                                {props.icon && !props.iconPosition && (
                                    <img src={props.icon} alt={props.icon} />
                                )}
                                <span className={labelClasses.join(" ")}>
                                    {props.label}
                                </span>
                                {props.icon && props.iconPosition === "right" && (
                                    <img src={props.icon} alt={props.icon} />
                                )}
                            </button>
                        </React.Fragment>
                    )}

                    {props.errorMessage && props.touched && (
                        <React.Fragment>
                            <div className={classes.ErrorMessage}>
                                {translations[props.errorMessage]}
                            </div>
                        </React.Fragment>
                    )}
                </div>
                {files && (
                    <div
                        className={`${classes.FileList} ${
                            props.multiple && classes.FileListMultiple
                        }`}
                    >
                        {props.uploadedFiles?.map((file, index) => {
                            return (
                                <UploadedFile
                                    file={file}
                                    key={index}
                                    multiple={props.multiple}
                                    oneLine={props.oneLine}
                                    removeFile={() => removeFileHandler(index)}
                                />
                            );
                        })}
                    </div>
                )}
                <input
                    type="file"
                    ref={uploadFileRef}
                    multiple={props.multiple}
                    onChange={onChangeHandler}
                    accept={
                        props.acceptedFormats?.length
                            ? props.acceptedFormats
                                  .map((format) => ` .${format}`)
                                  .join(",")
                                  .trim()
                            : "*"
                    }
                />
            </div>
            <ModalComponent
                show={showModal}
                title="Невалиден формат на фајл"
                component={<InvalidFilesUpload fileNames={invalidFiles} />}
                onCancel={onModalCancelHandler}
                onAccept={onModalAcceptHandler}
            />
        </React.Fragment>
    );
};

export default UploadButton;
