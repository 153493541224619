import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import CompanyInfo from "./Components/CompanyInfo/CompanyInfo";
import BaseInfo from "./Components/BaseInfo/BaseInfo";
import AdHeader from "./Components/Header/AdHeader";
import Page from "../../components/Layout/Page/Page";
import { httpGet } from "../../services/http";
import { RootState } from "../../store";

import classes from "./AdvertisementEdit.module.scss";

const AdvertisementEdit = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const [advertisement, setAdvertisement]:any = useState([]);

    useEffect(() => {
        const fetchAdById = async() => {
            try {
                const response = await httpGet<any>(`/tender/get?id=${id}&XDEBUG_SESSION_START=phpstorm`);
                if (response?.data?.data.length > 0) {
                    setAdvertisement(response.data.data[0]);
                }
            } catch (error) {
              console.error(error);
            };
        };

        fetchAdById();
    }, [id]);

    if (!advertisement?.products) {
        setAdvertisement({
            ...advertisement,
            products: [{
                title: "",
                category: "",
                quantity: 0,
                description: "",
                images: [],
            }]
        });
    }

    const BackBtn = () => {
        return(
            <div className={classes.header_content_container}>
                <button
                    type="button"
                    className={classes["back_btn"]}
                    onClick={() => navigate(-1)}
                >
                    &#60; {t.global["back"]}
                </button>
            </div>
        );
    };
    
    return (
        <React.Fragment>
            <Page showBackBtn={BackBtn}>
                <AdHeader
                    adInfo={{
                        date: advertisement?.created,
                        number: advertisement?.id
                    }}
                />
                <CompanyInfo />
                <div
                    className={classes.Card}
                >
                    <BaseInfo
                        advertisement={advertisement}
                    />
                </div>
            </Page>
        </React.Fragment>
    );
};

export default AdvertisementEdit;