import React from "react";
import dayjs from "dayjs";
import * as FileSaver from "file-saver";
import { useSelector } from "react-redux";
import { convertFromHTML } from "draft-js";
import { useParams } from "react-router-dom";

import CustomPopover from "../../../../components/UI/Popover/Popover";
import Avatar from "../../components/Avatar/Avatar";
import MoreVertical from "./../../../../assets/icons/MoreVertical.svg";
import { IMessageProps } from "./model/message.model";
import { daysHash } from "../../../../components/UI/Datepicker/constants/constants";
import { RootState } from "../../../../store";

import ChatDocument from "./../../../../assets/icons/ChatDocument.svg";
import DownloadWhite from "./../../../../assets/icons/DownloadWhite.svg";
import CopySimple from "./../../../../assets/icons/CopySimple.svg";
import ReplayIcon from "./../../../../assets/icons/ReplayIcon.svg";
import TrashIcon from "./../../../../assets/icons/TrashIcon.svg";

import classes from "./Message.module.scss";

const MoreActions = ({copy_text, remove_message, replay, showCopiedMessage}) => {
    const t = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "global"
            ]
    );
    return (
        <div className={classes.more_actions_wrapper}>
            {["copy", "replay", "delete"].map((action, index) => {
                let icon = "";
                let func = () => {};
                switch (action) {
                    case "replay":
                        icon = ReplayIcon;
                        func = () => {
                            replay();
                            document.body.click();
                        }
                        break;
                    case "delete":
                        icon = TrashIcon;
                        func = remove_message;
                        break;
                    default:
                        icon = CopySimple;
                        func = () => {
                            navigator.clipboard.writeText(copy_text);
                            document.body.click();
                            setTimeout(() => {
                                showCopiedMessage();
                            }, 200);
                        }
                        break;
                }
                return (
                    <div
                        key={index}
                        className={classes.action}
                        onClick={func}
                    >
                        <img src={icon} alt={action} />
                        {t[action]}
                    </div>
                )
            })}
        </div>
    )
}

const Message = (props: IMessageProps) => {
    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "components"
            ]
    );

    let classList = [classes.Container];

    if (props.sent) {
        classList.push(classes.Sent);
    } else {
        classList.push(classes.Received);
    }

    const messageTime = dayjs(new Date(props.message.created_at)).format(
        "HH:mm"
    );

    const today = dayjs().format("DD.MM.YYYY");
    const messageToday = dayjs(new Date(props.message.created_at)).format(
        "DD.MM.YYYY"
    );

    const isSentToday = today === messageToday;

    const messageDay = isSentToday
        ? translations["Денес"]
        : daysHash[dayjs(new Date(props.message.created_at)).format("d")];

    const downloadFile = (file: any) => {
        FileSaver.saveAs(file.blob, file.name);
    };

    const convertHTMLToText = (text) => {
        const blocks = convertFromHTML(text)?.contentBlocks;
        if (blocks && (blocks.length > 0)) {
            let text = "";
            blocks.forEach((block:any) => {
                text += block.text + " ";
            });
            return text;
        } else {
            return "";
        }
    }

    const customMessageElement = (message) => {
        if (message.replay && (message.replay.length > 0)) {
            return (
                <div className={classes.replay_wrapper}>
                    <div
                        className={classes.replay}
                        dangerouslySetInnerHTML={{
                            __html: message.replay
                        }}
                    ></div>
                    <div dangerouslySetInnerHTML={{__html: message.text}}></div>
                </div>
            )
        } else {
            return <div dangerouslySetInnerHTML={{__html: message.text}}></div>;
        }
    }

    return (
        <React.Fragment>
            <div className={classList.join(" ")}>
                <div className={classes.Header}>
                    <div className={classes.Avatar}>
                        <Avatar
                            initials={props?.initials || ""}
                            customClass={classes.chat_user_avatar}
                        />
                    </div>
                    <div className={classes.Time}>
                        {messageTime}, {translations[messageDay]}
                    </div>
                </div>

                <div className={classes.ContentContainer}>
                    <div className={classes.Content}>
                        <div
                            className={`${
                                props.message.text &&
                                props.message.files &&
                                props.message.files.length > 0
                                    ? classes.Separator
                                    : ""
                            }`}
                        >
                            {customMessageElement(props?.message)}
                        </div>
                        {(props.message.files && (props.message.files.length > 0)) && (
                            <div className={classes.FilesContent}>
                                {props.message.files.map((file, index) => {
                                    return (
                                        <div
                                            className={classes.FileContainer}
                                            onClick={() => downloadFile(file)}
                                            key={index}
                                        >
                                            <img
                                                className={
                                                    classes.DownloadWhite
                                                }
                                                src={DownloadWhite}
                                                alt="DownloadWhite"
                                            />
                                            <img
                                                className={classes.ChatDocument}
                                                src={ChatDocument}
                                                alt="ChatDocument"
                                            />
                                            <div className={classes.FileName}>
                                                {file.name}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className={classes.Actions}>
                        <CustomPopover
                            icon={MoreVertical}
                            activeIcon={MoreVertical}
                            overlay={
                                <MoreActions
                                    copy_text={convertHTMLToText(props.message.text)}
                                    remove_message={() => props.removeMessage(props.message?.messageId || "")}
                                    replay={() => {
                                        props?.replayOnMessage(props?.message?.text);
                                        setTimeout(() => {
                                            const el = document.getElementById("chat-container");
                                            el?.scrollTo(0, el.scrollHeight);
                                        }, 100);
                                    }}
                                    showCopiedMessage={props.showCopiedMessage}
                                />
                            }
                            square
                            borderlessTransparent
                            placement={props.sent ? "left-start" : "right-start"}
                            popoverCustomClass={`${classes.more_actions_popover} ${props.sent ? classes.message_send : ""}`}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Message;
