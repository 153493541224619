import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Page from "../../components/Layout/Page/Page";
import { RootState } from "../../store";
import classes from "./Chat.module.scss";
// import ChatFilter from "./ChatList/ChatFilter/ChatFilter";
import ChatList from "./ChatList/ChatList";
import ChatPreview from "./ChatPreview/ChatPreview";

const Chat = () => {
    const { messageId } = useParams();

    const sidebarOpen = useSelector((state: RootState) => state.sidebar.isOpen);

    return (
        <React.Fragment>
            <Page hideSecondaryNav>
                <div
                    className={`${classes.ChatContainer} ${
                        sidebarOpen ? classes.Open : ""
                    }`}
                >
                    <ChatList />
                    {messageId && <ChatPreview />}
                </div>
            </Page>
        </React.Fragment>
    );
};

export default Chat;
