export const countries = [
  {"label": "Авганистан", "value": "AF"},
  {"label": "Оландски Острови", "value": "AX"},
  {"label": "Албанија", "value": "AL"},
  {"label": "Алжир", "value": "DZ"},
  {"label": "Американска Самоа", "value": "AS"},
  {"label": "Андора", "value": "AD"},
  {"label": "Ангола", "value": "AO"},
  {"label": "Ангвила", "value": "AI"},
  {"label": "Антарктика", "value": "AQ"},
  {"label": "Антигва и Барбуда", "value": "AG"},
  {"label": "Аргентина", "value": "AR"},
  {"label": "Арменија", "value": "AM"},
  {"label": "Аруба", "value": "AW"},
  {"label": "Австралија", "value": "AU"},
  {"label": "Австрија", "value": "AT"},
  {"label": "Азербејџан", "value": "AZ"},
  {"label": "Бахами", "value": "BS"},
  {"label": "Бахреин", "value": "BH"},
  {"label": "Бангладеш", "value": "BD"},
  {"label": "Барбадос", "value": "BB"},
  {"label": "Белорусија", "value": "BY"},
  {"label": "Белгија", "value": "BE"},
  {"label": "Белизе", "value": "BZ"},
  {"label": "Бенин", "value": "BJ"},
  {"label": "Бермуди", "value": "BM"},
  {"label": "Бутан", "value": "BT"},
  {"label": "Боливија", "value": "BO"},
  {"label": "Босна и Херцеговина", "value": "BA"},
  {"label": "Боцвана", "value": "BW"},
  {"label": "Буветски Остров", "value": "BV"},
  {"label": "Бразил", "value": "BR"},
  {"label": "Британска Индиска Океанска Територија", "value": "IO"},
  {"label": "Брунеј", "value": "BN"},
  {"label": "Бугарија", "value": "BG"},
  {"label": "Буркина Фасо", "value": "BF"},
  {"label": "Бурунди", "value": "BI"},
  {"label": "Камбоџа", "value": "KH"},
  {"label": "Камерун", "value": "CM"},
  {"label": "Канада", "value": "CA"},
  {"label": "Зелен ’Рт", "value": "CV"},
  {"label": "Кајмански Острови", "value": "KY"},
  {"label": "Централноафриканска Република", "value": "CF"},
  {"label": "Чад", "value": "TD"},
  {"label": "Чиле", "value": "CL"},
  {"label": "Кина", "value": "CN"},
  {"label": "Остров Кристмас", "value": "CX"},
  {"label": "Кокосови (Килинг) Острови", "value": "CC"},
  {"label": "Колумбија", "value": "CO"},
  {"label": "Комори", "value": "KM"},
  {"label": "Конго", "value": "CG"},
  {"label": "Демократска Република Конго", "value": "CD"},
  {"label": "Кукски Острови", "value": "CK"},
  {"label": "Костарика", "value": "CR"},
  {"label": "Обала на Слоновата Коска", "value": "CI"},
  {"label": "Хрватска", "value": "HR"},
  {"label": "Куба", "value": "CU"},
  {"label": "Кипар", "value": "CY"},
  {"label": "Чешка Република", "value": "CZ"},
  {"label": "Данска", "value": "DK"},
  {"label": "Џибути", "value": "DJ"},
  {"label": "Доминика", "value": "DM"},
  {"label": "Доминиканска Република", "value": "DO"},
  {"label": "Еквадор", "value": "EC"},
  {"label": "Египет", "value": "EG"},
  {"label": "Ел Салвадор", "value": "SV"},
  {"label": "Екваторска Гвинеја", "value": "GQ"},
  {"label": "Еритреја", "value": "ER"},
  {"label": "Естонија", "value": "EE"},
  {"label": "Етиопија", "value": "ET"},
  {"label": "Фолкландски Острови (Малвински Острови)", "value": "FK"},
  {"label": "Фарски Острови", "value": "FO"},
  {"label": "Фиџи", "value": "FJ"},
  {"label": "Финска", "value": "FI"},
  {"label": "Франција", "value": "FR"},
  {"label": "Француска Гвајана", "value": "GF"},
  {"label": "Француска Полинезија", "value": "PF"},
  {"label": "Француски Јужни Територии", "value": "TF"},
  {"label": "Габон", "value": "GA"},
  {"label": "Гамбија", "value": "GM"},
  {"label": "Грузија", "value": "GE"},
  {"label": "Германија", "value": "DE"},
  {"label": "Гана", "value": "GH"},
  {"label": "Гибралтар", "value": "GI"},
  {"label": "Грција", "value": "GR"},
  {"label": "Гренланд", "value": "GL"},
  {"label": "Гренада", "value": "GD"},
  {"label": "Гваделупе", "value": "GP"},
  {"label": "Гуам", "value": "GU"},
  {"label": "Гватемала", "value": "GT"},
  {"label": "Гернси", "value": "GG"},
  {"label": "Гвинеја", "value": "GN"},
  {"label": "Гвинеја-Бисау", "value": "GW"},
  {"label": "Гвајана", "value": "GY"},
  {"label": "Хаити", "value": "HT"},
  {"label": "Остров Херд и Мекдоналдски Острови", "value": "HM"},
  {"label": "Света Престолна (Ватиканска Држава)", "value": "VA"},
  {"label": "Хондурас", "value": "HN"},
  {"label": "Хонг Конг", "value": "HK"},
  {"label": "Унгарија", "value": "HU"},
  {"label": "Исланд", "value": "IS"},
  {"label": "Индија", "value": "IN"},
  {"label": "Индонезија", "value": "ID"},
  {"label": "Иран, Исламска Република", "value": "IR"},
  {"label": "Ирак", "value": "IQ"},
  {"label": "Ирска", "value": "IE"},
  {"label": "Остров Ман", "value": "IM"},
  {"label": "Израел", "value": "IL"},
  {"label": "Италија", "value": "IT"},
  {"label": "Јамајка", "value": "JM"},
  {"label": "Јапонија", "value": "JP"},
  {"label": "Џерси", "value": "JE"},
  {"label": "Јордан", "value": "JO"},
  {"label": "Казахстан", "value": "KZ"},
  {"label": "Кенија", "value": "KE"},
  {"label": "Кирибати", "value": "KI"},
  {"label": "Кореја, Демократска Народна Република", "value": "KP"},
  {"label": "Кореја, Република", "value": "KR"},
  {"label": "Кувајт", "value": "KW"},
  {"label": "Киргизстан", "value": "KG"},
  {"label": "Лаос, Демократска Народна Република", "value": "LA"},
  {"label": "Летонија", "value": "LV"},
  {"label": "Либан", "value": "LB"},
  {"label": "Лесото", "value": "LS"},
  {"label": "Либерија", "value": "LR"},
  {"label": "Либија", "value": "LY"},
  {"label": "Лихтенштајн", "value": "LI"},
  {"label": "Литванија", "value": "LT"},
  {"label": "Луксембург", "value": "LU"},
  {"label": "Макао", "value": "MO"},
  {"label": "Македонија", "value": "MK"},
  {"label": "Мадагаскар", "value": "MG"},
  {"label": "Малави", "value": "MW"},
  {"label": "Малезија", "value": "MY"},
  {"label": "Малдиви", "value": "MV"},
  {"label": "Мали", "value": "ML"},
  {"label": "Малта", "value": "MT"},
  {"label": "Маршалски Острови", "value": "MH"},
  {"label": "Мартиник", "value": "MQ"},
  {"label": "Мавританија", "value": "MR"},
  {"label": "Маврициус", "value": "MU"},
  {"label": "Мајот", "value": "YT"},
  {"label": "Мексико", "value": "MX"},
  {"label": "Микронезија, Федеративни Држави на", "value": "FM"},
  {"label": "Молдавија, Република", "value": "MD"},
  {"label": "Монако", "value": "MC"},
  {"label": "Монголија", "value": "MN"},
  {"label": "Монсерат", "value": "MS"},
  {"label": "Мароко", "value": "MA"},
  {"label": "Мозамбик", "value": "MZ"},
  {"label": "Мјанмар", "value": "MM"},
  {"label": "Намибија", "value": "NA"},
  {"label": "Науру", "value": "NR"},
  {"label": "Непал", "value": "NP"},
  {"label": "Холандија", "value": "NL"},
  {"label": "Холандски Антили", "value": "AN"},
  {"label": "Нова Каледонија", "value": "NC"},
  {"label": "Нов Зеланд", "value": "NZ"},
  {"label": "Никарагва", "value": "NI"},
  {"label": "Нигер", "value": "NE"},
  {"label": "Нигерија", "value": "NG"},
  {"label": "Ниуе", "value": "NU"},
  {"label": "Норфолшки Остров", "value": "NF"},
  {"label": "Северни Маријански Острови", "value": "MP"},
  {"label": "Норвешка", "value": "NO"},
  {"label": "Оман", "value": "OM"},
  {"label": "Пакистан", "value": "PK"},
  {"label": "Палау", "value": "PW"},
  {"label": "Палестинска Територија, Окупирана", "value": "PS"},
  {"label": "Панама", "value": "PA"},
  {"label": "Папуа Нова Гвинеја", "value": "PG"},
  {"label": "Парагвај", "value": "PY"},
  {"label": "Перу", "value": "PE"},
  {"label": "Филипини", "value": "PH"},
  {"label": "Питкерн", "value": "PN"},
  {"label": "Полска", "value": "PL"},
  {"label": "Португалија", "value": "PT"},
  {"label": "Порто Рико", "value": "PR"},
  {"label": "Катар", "value": "QA"},
  {"label": "Реунион", "value": "RE"},
  {"label": "Романија", "value": "RO"},
  {"label": "Руска Федерација", "value": "RU"},
  {"label": "Руанда", "value": "RW"},
  {"label": "Света Јелена", "value": "SH"},
  {"label": "Свети Кристофер и Невис", "value": "KN"},
  {"label": "Света Лучија", "value": "LC"},
  {"label": "Свети Пјер и Микелон", "value": "PM"},
  {"label": "Свети Винсент и Гренадини", "value": "VC"},
  {"label": "Самоа", "value": "WS"},
  {"label": "Сан Марино", "value": "SM"},
  {"label": "Сао Томе и Принципе", "value": "ST"},
  {"label": "Саудиска Арабија", "value": "SA"},
  {"label": "Сенегал", "value": "SN"},
  {"label": "Србија и Црна Гора", "value": "CS"},
  {"label": "Сејшели", "value": "SC"},
  {"label": "Сиера Леоне", "value": "SL"},
  {"label": "Сингапур", "value": "SG"},
  {"label": "Словачка", "value": "SK"},
  {"label": "Словенија", "value": "SI"},
  {"label": "Соломонски Острови", "value": "SB"},
  {"label": "Сомалија", "value": "SO"},
  {"label": "Јужна Африка", "value": "ZA"},
  {"label": "Јужна Джорџија и Јужни Сендвички Острови", "value": "GS"},
  {"label": "Шпанија", "value": "ES"},
  {"label": "Шри Ланка", "value": "LK"},
  {"label": "Судан", "value": "SD"},
  {"label": "Суринам", "value": "SR"},
  {"label": "Свалбард и Јан Мејен", "value": "SJ"},
  {"label": "Свазиленд", "value": "SZ"},
  {"label": "Шведска", "value": "SE"},
  {"label": "Швајцарија", "value": "CH"},
  {"label": "Сиријска Арабска Република", "value": "SY"},
  {"label": "Тајван, Покраина на Кина", "value": "TW"},
  {"label": "Таџикистан", "value": "TJ"},
  {"label": "Танзанија, Обединета Република", "value": "TZ"},
  {"label": "Тајланд", "value": "TH"},
  {"label": "Тимор-Лесте", "value": "TL"},
  {"label": "Того", "value": "TG"},
  {"label": "Токелау", "value": "TK"},
  {"label": "Тонга", "value": "TO"},
  {"label": "Тринидад и Тобаго", "value": "TT"},
  {"label": "Тунис", "value": "TN"},
  {"label": "Турција", "value": "TR"},
  {"label": "Туркменистан", "value": "TM"},
  {"label": "Острови Туркс и Каикос", "value": "TC"},
  {"label": "Тувалу", "value": "TV"},
  {"label": "Уганда", "value": "UG"},
  {"label": "Украина", "value": "UA"},
  {"label": "Обединети Арапски Емирати", "value": "AE"},
  {"label": "Обединето Кралство", "value": "GB"},
  {"label": "Соединети Американски Држави", "value": "US"},
  {"label": "Соединети Американски Мањи Острови", "value": "UM"},
  {"label": "Уругвај", "value": "UY"},
  {"label": "Узбекистан", "value": "UZ"},
  {"label": "Вануату", "value": "VU"},
  {"label": "Венецуела", "value": "VE"},
  {"label": "Виетнам", "value": "VN"},
  {"label": "Девствени Острови, Британски", "value": "VG"},
  {"label": "Девствени Острови, С.А.Д.", "value": "VI"},
  {"label": "Уолис и Футуна", "value": "WF"},
  {"label": "Западна Сахара", "value": "EH"},
  {"label": "Јемен", "value": "YE"},
  {"label": "Замбија", "value": "ZM"},
  {"label": "Зимбабве", "value": "ZW"}
];