import React from "react";
import classes from "./Sidemenu.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { sidebarActions } from "../../../store/sidebar/sidebar";

import Logo from "./../../../assets/Logo.svg";
import UserInfo from "./UserInfo/UserInfo";
import MenuActions from "./MenuActions/MenuActions";
import MenuItems from "./MenuItems/MenuItems";
import Hamburger from "../../UI/Hamburger/Hamburger";
import MenuChart from "./MenuChart/MenuChart";

export const Sidemenu = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state: RootState) => state.sidebar.isOpen);

    const classList = [classes.Sidemenu];
    const containerClasses = ["sidebar", classes.SideMenuContainer];

    if (!isOpen) {
        // classList.push(classes.Hidden);
        containerClasses.push(classes.Hidden);
    }

    const toggleSidemenuHandler = () => {
        dispatch(sidebarActions.toggle());
    };

    return (
        <React.Fragment>
            <div className={containerClasses.join(" ")}>
                <div
                    className={classes.Overlay}
                    onClick={toggleSidemenuHandler}
                ></div>
                <div className={classList.join(" ")}>
                    <div className={classes.LogoContainer}>
                        <div className={classes.Logo}>
                            <img
                                className={classes.LogoShort}
                                src={Logo}
                                alt={Logo}
                            />
                        </div>
                        <div className="d-xl-none">
                            <Hamburger
                                onClick={toggleSidemenuHandler}
                                mode={"close"}
                                theme={"Accent"}
                            />
                        </div>
                    </div>
                    <UserInfo />
                    <MenuActions />
                    <MenuItems />
                    <MenuChart />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Sidemenu;
