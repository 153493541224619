import React from "react";
import classes from "./Cancel.module.scss";

const Cancel = () => {
    return (
        <React.Fragment>
            <div className={`${classes.CancelContainer} text-center`}>
                <p>Дали сте сигурни дека сакате да го прекинете процесот на регистрација ?</p>
                {/* <p className="m-0">Напомена: Внесените податоци не се запамтени и профилот не е креиран успешно</p> */}
            </div>
        </React.Fragment>
    );
};

export default Cancel;
