import React, { useEffect } from "react";
import classes from "./Login.module.scss";
import LoginForm from "./LoginForm/LoginForm";

import LoginBackground from "./LoginBackground/LoginBackground";
import { useDispatch, useSelector } from "react-redux";
import ModalComponent from "../../../components/UI/ModalComponent/ModalComponent";
import { RootState } from "../../../store";
import Cancel from "../Register/Cancel/Cancel";
import { authActions } from "../../../store/auth/auth";
import Verification from "../Register/Verification/Verification";

const Login = (props: any) => {
	const dispatch = useDispatch();

    // On dispatch reset the store
	// useEffect(() => {
	// 	dispatch({ type: "RESET_STORE" });
	// }, [dispatch])

    const openModal = useSelector(
        (state: RootState) => state.auth.openModal
        
    );

    const closeModal = () => {
        dispatch(authActions.closeModal());
    };

    return (
        <React.Fragment>
            <div className={classes.Login}>
                <div className={classes.LoginContainer}>
                    <LoginBackground />
                    <LoginForm />
                    
                    <ModalComponent
                        show={openModal}
                        title="Верификација"
                        // footerinfo="Профилот е успешно верификуван. Продолжете со најава"
                        component={<Verification />}
                        // onCancel={closeModal}
                        onAccept={closeModal}
                    />

                    <ModalComponent
                        show={openModal}
                        title="Верификација"
                        // footerinfo="Профилот е успешно верификуван. Продолжете со најава"
                        component={<Verification />}
                        // onCancel={closeModal}
                        onAccept={closeModal}
                    />

                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
