import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IActiveTab } from "../../components/UI/Tabs/model/tabs.model";
import { IAddNewAd, IListing, IRemoveListing } from "./listing.model";
import { v4 as uuidv4 } from 'uuid';
import { IAdvertisement, IProduct } from "../../models/data/advertisement.model";
import { getRandomProducts } from "../helper/helper-methods";

const initialState: IListing = {
	filters: {
		searchValue: '',
		date: '',
		latest: ''
	},
	tabs: [
		{
			id: 1,
			label: 'Тековни',
			active: true,
		},
		{
			id: 2,
			label: 'Завршени',
			active: false,
		},
		{
			id: 3,
			label: 'Сите',
			active: false,
		}
	],
	advertisements: new Array(10).fill({}).map((e) => ({
		id: uuidv4() as string,
		status: Math.random() > 0.5 ? 1 : 2,
		brojOglas: String(Math.floor(100000 + Math.random() * 900000)),
		dogovorenOrgan: `Компанија #${Math.floor(
			1000 + Math.random() * 9000
		)}`,
		imeNaOglas: "Име на оглас",
		pocetokAukcija: (() => {
			let start = new Date(2021, 0, 1);
			let end = new Date(2022, 10, 10);

			let result = new Date(
				start.getTime() +
				Math.random() * (end.getTime() - start.getTime())
			).toLocaleString();

			return result.split(",")[0].split("/").reverse().join("-");
		})(),
		zatovranjeAukcija: (() => {
			let start = new Date(2021, 0, 1);
			let end = new Date(2022, 10, 10);

			let result = new Date(
				start.getTime() +
				Math.random() * (end.getTime() - start.getTime())
			).toLocaleString();

			return result.split(",")[0].split("/").reverse().join("-");
		})(),
		products: getRandomProducts()
	}))
}

const listingSlice = createSlice({
	name: 'listing',
	initialState: initialState,
	reducers: {
		setActiveTab: (state, action: PayloadAction<IActiveTab>) => {
			state.tabs = state.tabs.map(tab => ({
				...tab,
				active: tab.id === action.payload.tabId
			}));
		},
		removeListing: (state, action: PayloadAction<IRemoveListing>) => {
			state.advertisements = state.advertisements.filter(ad => ad.id !== action.payload.listingId);
		},
		sendEmail: (state, action: PayloadAction<IAdvertisement>) => { },
		postListingSuccess: (state, action: PayloadAction<IAddNewAd>) => {
			state.advertisements.push({
				id: uuidv4(),
				brojOglas: `${Math.round(Math.random() + 9999)}`,
				dogovorenOrgan: 'ECHO',
				imeNaOglas: action.payload.header,
				pocetokAukcija: '2022-01-01',
				status: 1,
				zatovranjeAukcija: '2022-08-08',
				products: action.payload.products
			})
		}
	}
});

export const listingActions = listingSlice.actions;
export default listingSlice.reducer;