import React, { useState } from "react";
import Button from "../../components/UI/Button/Button";
import IconButton from "../../components/UI/IconButton/IconButton";
import Input from "../../components/UI/Input/Input";
import { IBaseField } from "../../models/field-validation.model";
import classes from "./ResetPassword.module.scss";

import UnlockIcon from "./../../assets/icons/Unlock.svg";

import LoginLogo from "./../../assets/LoginLogo.svg";
import EmailIcon from "./../../assets/icons/Email.svg";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../enums/routes";
import FloatInput from "../../components/UI/FloatInput/FloatInput";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const ResetPassword = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState({
        // value: "",
        value: "",
        status: {
            $touched: false,
            $error: null,
        },
    });

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [failedLogin, setFailedLogin] = useState(false);

    const emailField: IBaseField<string> = {
        value: email,
        validators: {
            required: true,
        },
        errorMessage: "Задолжително поле",
    };

    const isLoading = useSelector((state: RootState) => state.auth.isLoading);

    const onValueChangeHandler = (value: string) => {
        setEmail((state) => value);

        if (value) {
            setError((state) => "");
        } else {
            setError((state) => "Задолжително поле");
        }
    };

    const onLoginHandler = () => {};

    const onBackHandler = () => {
        navigate({
            pathname: RoutesEnum.Login,
        });
    };

    const onUsernameChange = (value: string) => {
        setUsername((prevState) => {
            return {
                ...prevState,
                value: value,
                status: {
                    ...prevState.status,
                    $touched: true,
                },
            };
        });
    };

    return (
        <React.Fragment>
            <div className={classes.LoginForm}>
                <h3 className="text-center pt-3 pb-5">Добредојде!</h3>
                <form>
                    <FloatInput
                        label="Емаил"
                        icon={EmailIcon}
                        value={username.value}
                        onChange={onUsernameChange}
                        error={username.status.$error}
                    />

                    {failedLogin && (
                        <div
                            className={`${classes.FailedLogin} error-text text-center`}
                        >
                            Испратен е емаил за ресетирање на лозинка
                        </div>
                    )}

                    <Button
                        label="Најави се"
                        icon={UnlockIcon}
                        fullWidth
                        onClick={onLoginHandler}
                        loading={isLoading}
                    />
                </form>

                <div className={classes.LoginLogo}>
                    <img src={LoginLogo} alt={LoginLogo} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
