import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import imagePlaceholder from "../../../../assets/imgPlaceholder.png";

import classes from "./CompanyInfo.module.scss";

const CompanyInfo = () => {
  const user = useSelector((state: RootState) => state.auth.user.user);
  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage][
      "create_ad"
    ];
  });

  return (
    <div className={classes.company_info_wrapper}>
      <div className={classes.image_wrapper}>
        <img
          src={user?.logo ? user?.logo : imagePlaceholder}
          alt="Company img"
        />
      </div>
      <div className={classes.info_wrapper}>
        <label>{t.contracting_authority}</label>
        <div className={classes.name}>{user?.naziv}</div>
        <div className={classes.website}>{user?.web_location}</div>
        <div className={classes.email}>{user?.company_email}</div>
        <div className={classes.address}>{user?.address}</div>
        <div className={classes.phone}>{user?.phone_number}</div>
      </div>
    </div>
  );
};

export default CompanyInfo;
