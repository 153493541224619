import React from "react";
import Page from "../../components/Layout/Page/Page";
// import classes from "./NewAd.module.scss";
import NewAdForm from "./NewAdForm/NewAdForm";

const NewAd = () => {
    return (
        <React.Fragment>
            <Page borderlessWithMarginTop>
                <NewAdForm />
            </Page>
        </React.Fragment>
    );
};

export default NewAd;
