import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../../../../store";
import { RoutesEnum } from "../../../../../enums/routes";

import MessageIcon from "../../../../../assets/icons/Message.svg";
import NoImageIcon from "../../../../../assets/icons/No-image-icon.svg";

import classes from "./ConfirmModalCompany.module.scss";

interface IConfirmModal {
    show: boolean,
    confirmBtnText?: string | null,
    hideFooterBtns?: boolean,
    offer: {
        offered_price: number | string,
        company_logo: {
            file_name: string
        },
        company_name: string,
        company_web: string,
        company_email: string,
        company_address: string,
        company_phone: string,
        contact_name: string,
        contact_email: string,
        contact_phone: string,
        comment: string,
        documents: [{file_name: string}]
    },
    onHandleClose: () => void,
    onHandleConfirm: (action:string) => void
};

const ConfirmModalCompany = (props:IConfirmModal) => {

    const navigate = useNavigate();

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    return(
        <React.Fragment>
            <Modal
                show={props.show}
                className={classes.modal_wrapper}
                centered
                size="lg"
                onHide={props.onHandleClose}
            >
                <Modal.Header className={classes.custom_header} closeButton>
                    <Modal.Title>{t.my_adds_offers.company}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={classes.custom_body}>
                    <div className={classes.company_info_wrapper}>
                        <div className={classes.image_wrapper}>
                            <div className={classes.image_container}>
                                <img
                                    src={props?.offer?.company_logo?.file_name}
                                    alt="company logo"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoImageIcon;
                                    }}
                                />
                            </div>
                            <div className={classes.company_name}>
                                {props?.offer?.company_name}
                            </div>
                        </div>
                        <div className={classes.company_info}>
                            <div className={classes.company_name}>
                                {props?.offer?.company_name}
                            </div>
                            <div className={classes.website}>
                                {props?.offer?.company_web}
                            </div>
                            <div className={classes.email}>
                                {props?.offer?.company_email}
                            </div>
                            <div className={classes.address}>
                                {props?.offer?.company_address}
                            </div>
                            <div className={classes.phone}>
                                {props?.offer?.company_phone}
                            </div>
                        </div>
                        <div className={classes.message_icon}>
                            <div
                                className={classes.image_wrapper}
                                onClick={() => navigate(RoutesEnum.Messages)}
                            >
                                <img src={MessageIcon} alt="message icon" />
                            </div>
                        </div>
                    </div>
                    <div className={classes.contact_wrapper}>
                        <div className={classes.title}>
                            {t.my_adds_offers.contact_person}
                        </div>
                        <div className={classes.contact_info_wrapper}>
                            <div className={classes.name}>
                                {props?.offer?.contact_name}
                            </div>
                            <div className={classes.email}>
                                {props?.offer?.contact_email}
                            </div>
                            <div className={classes.phone}>
                                {props?.offer?.contact_email}
                            </div>
                        </div>
                    </div>
                    <div className={classes.comment_wrapper}>
                        <div className={classes.title}>
                            {t.my_adds_offers.comment}
                        </div>
                        <textarea
                            name="comment"
                            placeholder={t.my_adds_offers.comment}
                            rows={4}
                            value={props?.offer?.comment}
                            disabled={true}
                        ></textarea>
                    </div>
                    {(props?.offer?.documents && (props?.offer?.documents?.length > 0)) && (
                        <div className={classes.additional_docs_wrapper}>
                            <div className={classes.title}>
                                {t.my_adds_offers.additional_docs}
                            </div>
                            <div className={classes.docs_wrapper}>
                                {props?.offer?.documents.map((doc, i) => {
                                        return(
                                            <div className={classes.doc} key={i}>
                                                <div className={classes.icon}></div>
                                                {doc?.file_name}
                                            </div>
                                        )
                                })}
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className={classes.custom_footer}>
                    <div className={classes.price_wrapper}>
                        <div className={classes.label}>
                            {t.my_adds_offers.offered_price}:
                        </div>
                        <div className={classes.price}>
                            {props?.offer?.offered_price}
                        </div>
                    </div>
                    {!props?.hideFooterBtns && (
                        <div className={classes.buttons_wrapper}>
                            <button
                                className={classes.refuse_btn}
                                onClick={() => props.onHandleConfirm("refuse")}
                            >
                                <span className={classes.icon}></span>
                                {t.my_adds_offers.reject_offer}
                            </button>
                            <button
                                className={classes.accept_btn}
                                onClick={() => props.onHandleConfirm("accept")}
                            >
                                <span className={classes.icon}></span>
                                {props?.confirmBtnText || t.my_adds_offers.accept_offer}
                            </button>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ConfirmModalCompany;