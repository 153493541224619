import React from "react";
import classes from "./Input.module.scss";
import { IInput } from "./model/input.model";

import NumberUp from "./../../../assets/icons/NumberUp.svg";
import NumberDown from "./../../../assets/icons/NumberDown.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const Input = (props: IInput) => {
    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "components"
            ]
    );

    const onInputChangeHandler = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        let value = event.target.value;

        if (typeof value === "string") value = value.trimStart();

        props.onInputChange?.(props.name, value);
        props.onValueChange?.(value);
    };

    const onInputFocusHandler = () => {
        if (props.onInputFocus) {
            props.onInputFocus(props.name);
        }
    };

    const onInputBlurHandler = () => {
        if (props.onInputBlur) {
            props.onInputBlur(props.name);
        }
    };

    let classList = [classes.Input];
    if (props.noMargins) {
        classList.push(classes.NoMargins);
    }

    const incrementValue = () => {

        const value = +props.field?.value + 1;

        props.onInputChange && props.onInputChange(props.name, `${value}`);
        props.onValueChange && props.onValueChange(`${value}`);
    };

    const decrementValue = () => {
        const value = +props.field?.value - 1;

        if (value < 0) return;

        props.onInputChange && props.onInputChange(props.name, `${value}`);
        props.onValueChange && props.onValueChange(`${value}`);
    };

    const onActionClick = () => {
        props.onActionIconClick && props.onActionIconClick();
    };

    let inputComponent = (
        <div
            className={`${classList.join(" ")} ${
                props.field?.touched && props.field?.errorMessage
                    ? classes.ErrorInput
                    : null
            } form-group`}
        >
            <label>
                {props.label}{" "}
                <span className={classes.RequiredIndicator}>
                    {props.field?.validators?.required && "*"}
                </span>{" "}
            </label>
            {props.type !== "textarea" && (
                <input
                    type={props.type ?? "text"}
                    className="form-control"
                    id={props.name}
                    aria-describedby={`${props.name}Help`}
                    autoComplete="off"
                    placeholder={props.placeholder || props.label}
                    value={props.field?.value}
                    onChange={onInputChangeHandler}
                    onFocus={onInputFocusHandler}
                    onBlur={onInputBlurHandler}
                    maxLength={props.maxLength}
                    max={props.max}
                    pattern={props.pattern ?? ""}
                />
            )}

            {props.actionIcon && (
                <div
                    className={`${classes.FloatInput}` } onClick={onActionClick}
                    >
                    <img src={props.actionIcon} alt={props.actionIcon}/>
                </div>
            )}
            
            {/* {props.type === "number" && (
                <div className={classes.NumberArrows}>
                    <img
                        src={NumberUp}
                        alt="Increment"
                        onClick={incrementValue}
                    />
                    <img
                        src={NumberDown}
                        alt="Decrement"
                        onClick={decrementValue}
                    />
                </div>
            )} */}
            {props.type === "textarea" && (
                <textarea
                    rows={props.rows ?? 5}
                    cols={props.cols ?? 10}
                    className="form-control"
                    id={props.name}
                    aria-describedby={`${props.name}Help`}
                    autoComplete="off"
                    placeholder={props.placeholder || props.label}
                    onFocus={onInputFocusHandler}
                    onBlur={onInputBlurHandler}
                >
                    {props.field?.value}
                </textarea>
            )}
            {props.field?.touched && props.field?.errorMessage && (
                <small
                    id="emailHelp"
                    className={`${classes.ErrorText} form-text text-muted`}
                >
                    {translations[props.field.errorMessage]}
                </small>
            )}
        </div>
    );
    if (props.switchable) {
        classList.push(classes.Switchable);

        inputComponent = (
            <div
                className={`${classList.join(" ")} ${
                    props.field?.touched && props.field?.errorMessage
                        ? classes.ErrorInput
                        : null
                } form-group`}
            >
                {props.switchable.readonly && (
                    <div style={props.switchable.labelStyle}>
                        {props.field?.value ? props.field?.value : props.label}
                    </div>
                )}
                {!props.switchable.readonly && (
                    <input
                        type={props.type ?? "text"}
                        className="form-control"
                        id={props.name}
                        aria-describedby={`${props.name}Help`}
                        autoComplete="new-password"
                        placeholder={props.placeholder || props.label}
                        value={props.field?.value}
                        onChange={onInputChangeHandler}
                        onFocus={onInputFocusHandler}
                        onBlur={onInputBlurHandler}
                    />
                )}

                {props.field?.touched && props.field?.errorMessage && (
                    <small
                        id="emailHelp"
                        className={`${classes.ErrorText} form-text text-muted`}
                        style={{
                            position: "absolute",
                            bottom: "-1rem",
                        }}
                    >
                        {translations[props.field.errorMessage]}
                    </small>
                )}
            </div>
        );
    }

    return <React.Fragment>{inputComponent}</React.Fragment>;
};

export default Input;
