import React, { useState } from "react";
import classes from "./ChatFilter.module.scss";
import { IChatFilter } from "./model/chat-filter.model";

import Search from "./../../../../assets/icons/Search.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

const ChatFilter = (props: IChatFilter) => {
    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "chat"
            ]
    );
    const [search, setSearch] = useState("");

    const onSearchChange = (event) => {
        const value = event.target.value;

        setSearch(value);
        props.onFilterChange(value);
    };

    return (
        <React.Fragment>
            <div className={classes.Container}>
                <div className={classes.InputContainer}>
                    <div className={classes.Icon}>
                        <img src={Search} alt="Search" />
                    </div>
                    <div className={classes.Input}>
                        <input
                            type="text"
                            placeholder={translations["Пребарувај..."]}
                            value={search}
                            onChange={onSearchChange}
                            disabled={props.disabled}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ChatFilter;
