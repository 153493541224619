import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Checkbox from "../../../../components/UI/Checkbox/Checkbox";
import Button from "../../../../components/UI/Button/Button";
import { authActions } from "../../../../store/auth/auth";
import { IStateUser, IUser } from "../../../../store/auth/auth.model";
import { RoutesEnum } from "../../../../enums/routes";
import { httpGet, httpPost } from "../../../../services/http";
import FloatInput from "../../../../components/UI/FloatInput/FloatInput";
import { RootState } from "../../../../store";
import axios from "axios";

import LoginLogo from "./../../../../assets/LoginLogo.svg";
import EmailIcon from "./../../../../assets/icons/Email.svg";
import LockIcon from "./../../../../assets/icons/Lock.svg";
import UnlockIcon from "./../../../../assets/icons/Unlock.svg";
import ViewIcon from "./../../../../assets/icons/ViewIcon.svg";
import ViewOffIcon from "./../../../../assets/icons/ViewOffIcon.svg";

import classes from "./LoginForm.module.scss";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { get } from "lodash";
import { USERS } from "../../../../api/config/routes";
import { baseHttp } from "../../../../api/http/base";
// import { authActions } from "../../../../store/auth/auth";

const LoginForm = () => {
    const dispatch = useDispatch();

    const t = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage]
    );

    const t_auth = t.auth;
    const t_global = t.global;

    const dispath = useDispatch();
    const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false)

    const [isLogin, setIsLogin] = useState(true);

    const [companyId, setCompanyId] = useState();

    let { token } = useParams();
    
    const [resetPasswordMessage, setResetPasswordMessage] = useState('');
    const [changePasswordMessage, setChangePasswordMessage] = useState('');

    const [resetUsername, setResetUsername] = useState({
        value: "",
        status: {
            $touched: false,
            $error: null,
        },
    });

    const [changePassword, setChangePassword] = useState({
        value: "",
        status: {
            $touched: false,
            $error: null,
        },
    });

    const [username, setUsername] = useState({
        value: "",
        status: {
            $touched: false,
            $error: null,
        },
    });
    const [password, setPassword] = useState({
        value: "",
        status: {
            $touched: false,
            $error: null,
        },
    });

    const [failedLogin, setFailedLogin] = useState(false);
    const [failedLoginText, setFailedLoginText] = useState("");

    // If the user if coming from the verification URL (from the mail)
    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const verification_url = queryParameters.get("verification")
        const verificationData = {
            verification_url: verification_url,
        }
        
        if (verification_url) {
            const response = httpPost<any, any>("/user/verify", verificationData).then((response) => {
                if (response.status === true) {
                    dispatch(authActions.openModal());
                }
            }).catch((error) => {
                if (error.code) {
                    setFailedLogin(true);
                    setFailedLoginText(error.errors.email);
                }
            }).finally(() => {
                setIsLoading(state => false)
            });
        }
    }, []);

    async function onLoginHandler() { 
        const credentials = {
            email: username,
            password: password
        }
        let companyId = "";
        // axios
        setIsLoading(state => true)
        const response = await httpPost<any, any>("/user/login", credentials).then((response) => {
            companyId = response.user.company_id;

            return {
                userId: response.user.id,
                user: response.user,
                access_token: response.token,
            } as IStateUser; 
        }).then(async (stateUser: IStateUser) => {
            // let url = "";
            let content = {
                company_id: companyId
            }

            const logoResponse = await httpPost<any, any>("/user/logo", content).then((response) => {
                return response;
            }).catch((error) => {
                // if (error.code) {
                //  setFailedLogin(true);
                // }
            }).finally(() => {
                // setIsLoading(state => false)
            });

            // TODO: Need to take a look at the avatar 
            // Since the logic is new not using firebase
            // where do we store the images ?
            // console.log(stateUser);
            // const storage = baseHttp.getStorage();
            // const imageRef = baseHttp.storageRef(
            //     storage,
            //     `${stateUser.user?.logo.id}`
            // );

            // const storage = baseHttp.getStorage();
            //     const imageRef = baseHttp.storageRef(
            //         storage,
            //         `${stateUser.user?.logo.id}`
            //     );

            // const url = await baseHttp.getDownloadURL(imageRef);
            // return baseHttp.getDownloadURL(imageRef).then((url) => {
            //     return {
            //         ...stateUser,
            //         user: {
            //             ...stateUser.user,
            //             logo: {
            //                 ...stateUser.user?.logo,
            //                 src: url,
            //             },
            //         },
            //     };
            // });
            
            return {
                ...stateUser,
                user: {
                    ...stateUser.user,
                    logo: logoResponse
                },
            };
        }).then((user: any) => {
            dispath(authActions.putUserInState(user));
            navigate({
                pathname: RoutesEnum.Advertisements,
            });
        }).catch((error) => { 
                setFailedLogin(true);
                setFailedLoginText(error.errors.email);
        }).finally(() => {
			setIsLoading(state => false)
		});
        // if (response.message === "Success") {
        //     navigate({
        //         pathname: RoutesEnum.Advertisements,
        //     });
        // } else {
        //     navigate({
        //         pathname: RoutesEnum.Advertisements,
        //     });
        // }
        // const response = await httpGet<any>('/tender/get-active');
        // .post('http://tenderfinder/api/user/login', credentials)
        // .then((data) => {
        //     navigate({
        //         pathname: RoutesEnum.Advertisements,
        //     });
        // })
        // .catch((e) => {
        //     // hideLoader();
        //     console.log(e);
        // });
        // if (!username || !password) {
        //     return;
        // }




		// setIsLoading(state => true)
        // const auth = getAuth();
        // signInWithEmailAndPassword(auth, username.value, password.value)
            // .then((response) => {
            //     return get<IUser>(`${USERS}/${response.user.uid}`).then(
            //         (user) => {
            //             return {
            //                 userId: response.user.uid,
            //                 user,
            //             } as IStateUser;
            //         }
            //     );
            // })
        //     .then((stateUser: IStateUser) => {
        //         const storage = baseHttp.getStorage();
        //         const imageRef = baseHttp.storageRef(
        //             storage,
        //             `${stateUser.user?.logo.id}`
        //         );

                // return baseHttp.getDownloadURL(imageRef).then((url) => {
                //     return {
                //         ...stateUser,
                //         user: {
                //             ...stateUser.user,
                //             logo: {
                //                 ...stateUser.user?.logo,
                //                 src: url,
                //             },
                //         },
                //     };
                // });
        //     })
        //     .then((user: any) => {
        //         dispath(authActions.putUserInState(user));
        //         navigate({
        //             pathname: RoutesEnum.Advertisements,
        //         });
        //     })
        //     .catch((error) => {
        //         if (error.code) {
        //             setFailedLogin(true);
        //         }
        //     })
		// 	.finally(() => {
		// 		setIsLoading(state => false)
		// 	});
    }

    const onUsernameChange = (value: string) => {
        setUsername((prevState) => {
            return {
                ...prevState,
                value: value,
                status: {
                    ...prevState.status,
                    $touched: true,
                },
            };
        });
    };

    const onResetUsernameChange = (value: string) => {
        setResetUsername((prevState) => {
            return {
                ...prevState,
                value: value,
                status: {
                    ...prevState.status,
                    $touched: true,
                },
            };
        });
    };

    const onChangePassword = (value: string) => {
        setChangePassword((prevState) => {
            return {
                ...prevState,
                value: value,
                status: {
                    ...prevState.status,
                    $touched: true,
                },
            };
        });
    };

    const onPasswordChange = (value: string) => {
        setPassword((prevState) => {
            return {
                ...prevState,
                value: value,
                status: {
                    ...prevState.status,
                    $touched: true,
                },
            };
        });
    };

    const onResetPasswordHandler = async () => { 
		setIsLoading(true)
        try {
            const response = await httpPost<any, any>(`/reset-password`, {
                'username': resetUsername.value
            });

            setTimeout(() => {
                setIsLogin(true);
                setResetUsername((prevState) => {
                    return {
                        ...prevState,
                        value: "",
                        status: {
                            ...prevState.status,
                            $touched: true,
                        },
                    };
                });
            }, 5000);
            
            setResetPasswordMessage(response.message);

        } catch (error:any) {
            var message = error.message;
            switch (message) {
                case "Email is required":
                    message = t_auth.email_required;
                    break;
                case `User ${resetUsername.value} does not exist`:
                    message = `${t_auth.user} ${resetUsername.value} ${t_auth.non_existing}`;
                    break;
                case "Error occurred":
                    message = t_auth.error_occurred;
                    break;
                default:
                    break;
            }
          setResetPasswordMessage(message);
        } finally {
            setIsLoading(false)
        }
    };

    const onChangePasswordHandler = async () => {
		setIsLoading(true)
        
        try {
            const response = await httpPost<any, any>(`/change-password?XDEBUG_SESSION_START=phpstorm`, {
                'token': token,
                'new_password': changePassword.value
            });

            setTimeout(() => {
                setIsLogin(true);
            }, 5000);
            
            setChangePasswordMessage(response.message);

        } catch (error:any) {
            setChangePasswordMessage(error.message);
        } finally {
            setIsLoading(false)
        }
    };

    const toggleForm = () => { 
        setIsLogin((state) => !state);
    };

    const toggleFormChangePassword = () => { 
        navigate({
            pathname: RoutesEnum.Login,
        });
    };

    const [inputType, setInputType] = useState("password");
    const toggleInputType = () => {
        setInputType((state) => {
            if (state === "password") return "text";
            else return "password";
        });
    };

    return (
        <React.Fragment>
            <div className={classes.LoginForm}>
                <h3 className="text-center pt-3 pb-5">
                    {token !== undefined ? t_auth.change_password : isLogin ? t_auth.welcome : t_auth.reset_password}
                </h3>
                {token !== undefined ? (
                    <form>
                        <FloatInput
                                label={t_auth.new_password}
                                icon={LockIcon}
                                type={inputType}
                                value={changePassword.value}
                                onChange={onChangePassword}
                                error={changePassword.status.$error}
                                actionIcon={
                                    inputType === "password"
                                        ? ViewOffIcon
                                        : ViewIcon
                                }
                                onActionIconClick={toggleInputType}
                            />

                        {changePasswordMessage && (
                            <div
                                className={`${classes.InfoLogin} accent-text text-center`}
                            >
                                {changePasswordMessage}
                            </div>
                         )}

                        <div className={classes.ResetButtons}>
                            <Button
                                label={t_global.cancel}
                                class="light-accent"
                                onClick={toggleFormChangePassword}
                                borderless
                            />
                            <Button
                                label={t_auth.change_password}
                                icon={UnlockIcon}
                                fullWidth
                                onClick={onChangePasswordHandler}
                                loading={isLoading}
                            />
                        </div>
                    </form>
                ) : (
                    isLogin ? (
                        <form>
                            <div className="mb-4">
                                <FloatInput
                                    label={t_auth.email}
                                    icon={EmailIcon}
                                    value={username.value}
                                    onChange={onUsernameChange}
                                    onKeyUp={(e) => {
                                        if (e && ((e.key === "Enter") || (e.keyCode === 13))) {
                                            onLoginHandler();
                                        }
                                    }}
                                    error={username.status.$error}
                                />
                            </div>
                            <FloatInput
                                label={t_auth.password}
                                icon={LockIcon}
                                type={inputType}
                                value={password.value}
                                onChange={onPasswordChange}
                                onKeyUp={(e) => {
                                    if (e && ((e.key === "Enter") || (e.keyCode === 13))) {
                                        onLoginHandler();
                                    }
                                }}
                                error={password.status.$error}
                                actionIcon={
                                    inputType === "password"
                                        ? ViewOffIcon
                                        : ViewIcon
                                }
                                onActionIconClick={toggleInputType}
                            />

                            {failedLogin && (
                                <div
                                    className={`${classes.FailedLogin} error-text text-center`}
                                >
                                    {failedLoginText}
                                </div>
                            )}

                            <div
                                className="dimmed d-flex pt-2 pb-2"
                                style={{ alignItems: "center" }}
                            >
                                <div className="flex-grow-1">
                                    <Checkbox
                                        label={t_auth.remember_me}
                                        class="Accent"
                                        id={"remember_me"}
                                        large
                                        value={1}
                                        lightText
                                    />
                                </div>
                                <div
                                    className={`${classes.Pointer} flex-grow-1 text-end`}
                                    onClick={toggleForm}
                                    style={{
                                        paddingTop: "2px",
                                    }}
                                >
                                    {t_auth.forgotten_password}
                                </div>
                            </div>

                            <Button
                                label={t_auth.login}
                                icon={UnlockIcon}
                                fullWidth
                                onClick={onLoginHandler}
                                loading={isLoading}
                            />

                            <div
                                className={`${classes.NotRegistered} ${
                                    isLoading ? classes.DisabledLink : null
                                } text-center`}
                            >
                                <div
                                    className={`${classes.PointerDefault} semi-dimmed pt-3`}
                                >
                                    {t_auth.not_registered}
                                </div>
                                <Link to={RoutesEnum.Register}>
                                    <span className="fw-bold accent-text">
                                        {t_auth.register}
                                    </span>
                                </Link>
                            </div>
                        </form>
                    ) : (
                        <form>
                            <FloatInput
                                label={t_auth.email}
                                icon={EmailIcon}
                                value={resetUsername.value}
                                onChange={onResetUsernameChange}
                                error={resetUsername.status.$error}
                            />

                            {resetPasswordMessage && (
                                <div
                                    className={`${classes.InfoLogin} accent-text text-center`}
                                >
                                    {resetPasswordMessage}
                                </div>
                            )}

                            <div className={classes.ResetButtons}>
                                <Button
                                    label={t_global.cancel}
                                    class="light-accent"
                                    onClick={toggleForm}
                                    borderless
                                />
                                <Button
                                    label={t_auth.reset_password}
                                    icon={UnlockIcon}
                                    fullWidth
                                    onClick={onResetPasswordHandler}
                                    loading={isLoading}
                                />
                            </div>
                        </form>
                    )
                )}

                <div className={classes.LoginLogo}>
                    <img src={LoginLogo} alt={LoginLogo} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoginForm;
