import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../../store";

import NoImageIcon from "../../../../../../assets/icons/No-image-icon.svg";
import PlusIcon from "../../../../../../assets/icons/Plus.svg";

import classes from "./AttachImages.module.scss";

const AttachImagesModal = (props) => {

    const {
        REACT_APP_MAX_PRODUCT_IMAGES,
        REACT_APP_PRODUCT_IMAGES_TYPES
    }:any = process.env;

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const [numOfImages, setNumOfImages] = useState<number>(3);
    const [selectedImages, setSelectedImages]:any = useState([]);

    useEffect(() => {
        if (props?.images && (props?.images?.length > 0)) {
            setSelectedImages(props.images);
            if (props.images.length > 3) {
                setNumOfImages(props.images.length);
            }
        }
    }, [props?.images])

    const addMoreImages = () => {
        if (numOfImages < REACT_APP_MAX_PRODUCT_IMAGES) {
            setNumOfImages(numOfImages + 1);
        }
    }

    const onImageChange = (e, index) => {
        const file = e.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (e) => {
            if (e.target) {
                const images = [...selectedImages]
                if (typeof images[index] !== "undefined") {
                    images[index] = {
                        ...images[index],
                        file_url: e.target.result,
                        existing: false,
                        upload_type: 1,
                        uploaded: true
                    };
                } else {
                    images.push({
                        file_url: e.target.result,
                        existing: false,
                        upload_type: 1,
                        uploaded: true
                    });
                }
                setSelectedImages(images);
                const el:any = document.getElementById(`link_${index}`);
                if (el) {
                    el.value = "";
                }
            }
        };
        
        reader.readAsDataURL(file);
    }

    const onImageLinkChange = (e, index) => {
        const newFormValues = [...selectedImages];
        if (e.target) {
            if (e.target.value.length > 0) {
                let checkURL = e.target.value.match(/(http(s)?:)/g);
                let upload_type = 1;
                if (checkURL) {
                    upload_type = 2;
                }
                if (typeof newFormValues[index] !== "undefined") {
                    newFormValues[index] = {
                        ...newFormValues[index],
                        file_url: e.target.value,
                        existing: false,
                        upload_type,
                        uploaded: false
                    }
                } else {
                    newFormValues.push({
                        file_url: e.target.value,
                        existing: false,
                        upload_type,
                        uploaded: false
                    });
                };
            } else {
                newFormValues[index] = "";
            }
            setSelectedImages(newFormValues);
        }
    }

    const onImagesAttach = () => {
        const newImages:any = [];
        selectedImages.forEach((img) => {
            if (typeof img === "object") {
                newImages.push(img);
            }
        });
        props.confirm(props.index, newImages);
        setSelectedImages([]);
    }

    const onRemoveImage = (index) => {
        const newFormValues = [...selectedImages];
        if (typeof newFormValues[index] !== "undefined") {
            newFormValues[index] = "";
            setSelectedImages(newFormValues);
            const el:any = document.getElementById(`link_${index}`);
            if (el) {
                el.value = "";
            }
        }
    }
    
    return(
        <React.Fragment>
            <Modal show={props.show} className={classes.modal_wrapper} centered>
                <Modal.Header className={classes.custom_header}>
                    <Modal.Title>{t.create_ad.attach_images_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={classes.custom_body}>
                    <div className={classes.attach_images_wrapper}>
                        {[...Array(numOfImages)].map((n, index) => {
                            const images = selectedImages[index];
                            return(
                                <div className={classes.attach_wrapper} key={index}>
                                    <div className={`${classes.image_wrapper} ${(typeof images === "undefined") ? classes.no_image : (images?.length === 0) ? classes.no_image : ""}`}>
                                        <label htmlFor={`${index}_image`}>
                                            <img
                                                src={
                                                    ((typeof images !== "undefined") && images?.hasOwnProperty("file_url"))
                                                        ? images?.file_url
                                                        : NoImageIcon
                                                }
                                                alt="img icon"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null;
                                                    currentTarget.src = NoImageIcon;
                                                }}
                                            />
                                        </label>
                                        <div
                                            className={classes.remove_image}
                                            onClick={() => onRemoveImage(index)}
                                        >
                                            <span className={classes.icon}></span>
                                        </div>
                                        <input
                                            type="file"
                                            name={`${index}_image`}
                                            style={{display: "none"}}
                                            onChange={(e) => onImageChange(e, index)}
                                            id={`${index}_image`}
                                            accept={REACT_APP_PRODUCT_IMAGES_TYPES}
                                        />
                                    </div>
                                    <div className={classes.link_wrapper}>
                                        <div className={classes.text}>
                                            {t.create_ad.or_add_link_title}
                                        </div>
                                        <div className={classes.link_input}>
                                            <input
                                                type="text"
                                                id={`link_${index}`}
                                                name={`link_${index}`}
                                                value={((typeof images !== "undefined") && (images?.uploaded === false)) ? images.file_url : ""}
                                                onChange={(e) =>onImageLinkChange(e, index)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {(numOfImages < REACT_APP_MAX_PRODUCT_IMAGES) && (
                        <div
                            className={classes.add_more_btn_wrapper}
                            onClick={addMoreImages}
                        >
                            <div className={classes.icon_wrapper}>
                                <img src={PlusIcon} alt="Add more" />
                            </div>
                            {t.create_ad.add_image}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className={classes.custom_footer}>
                    <button className={classes.cancel_btn} onClick={props.toggleModal}>
                        {t.global.cancel}
                    </button>
                    <button
                        className={classes.confirm_btn}
                        onClick={onImagesAttach}
                    >
                        {t.global.attach}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AttachImagesModal;