import React from "react";
import classes from "./RadioButton.module.scss";

import { IRadioButton } from "./model/radiobutton.model";

const RadioButton = (props: IRadioButton) => {

    return (
        <React.Fragment>
            <div className={`${classes.CustomRadioWrapper} ${props.customClasses ? props.customClasses : ""}`}>
                <label>
                    <input 
                        className={classes.CustomRadioButton}
                        type="radio"
                        name={props.name}
                        value={props.value}
                        onChange={props.onChange}
                        checked={props.checked}
                        required={props.required}
                    />
                    {props.label}
                </label>
            </div>
        </React.Fragment>
    );
};

export default RadioButton;
