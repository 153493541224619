import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ReactNotifications } from 'react-notifications-component';

import { RootState } from "./store";
import { RoutesEnum } from "./enums/routes";
import { initFirebase } from "./api/config/firebase";
import GlobalLoader from "./components/UI/GlobalLoader/GlobalLoader";
// import { httpActions } from "./store/http/http";
// import ModalErrorComponent from "./components/UI/ModalErrorComponent/ModalErrorComponent";

import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import Advertisements from "./pages/Advertisements/Advertisements";
import Chat from "./pages/Chat/Chat";
import NewAd from "./pages/NewAd/NewAd";
import CurrentAdvertisements from "./pages/CurrentAdvertisements/ListAdvertisements/CurrentAdvertisements";
import TermsAndServices from "./pages/TermsAndServices/TermsAndServices";
import ProfilePage from "./pages/Profile/ProfilePage";
import AdvertisementPreview from "./pages/AdvertisementPreview/AdvertisementPreview";
import Auction from "./pages/Auction/Auctions/Auctions";
import NotificationsPage from "./pages/NotificationsPage/NotificationsPage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import CompanyInfo from "./pages/CompanyInfo/CompanyInfo";
import AuctionBidding from "./pages/AuctionBidding/AuctionBidding";
import AdvertisementEdit from "./pages/AdvertisementEdit/AdvertisementEdit";
import OffersPreview from "./pages/OffersPreview/OffersPreview";

// My Offers
import MyOffers from "./pages/MyOffers/OffersList/Offers";
import MyOfferPreview from "./pages/MyOffers/OfferPreview/OfferPreview";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    Filler,
} from "chart.js";
// import axios from "axios";

import translationsMk from "./assets/i18n/mk.json";
import translationsEn from "./assets/i18n/en.json";
import translationsAl from "./assets/i18n/al.json";
import { translationsActions } from "./store/translations/translations";

import "react-notifications-component/dist/theme.css";

initFirebase();

function App() {
    const dispatch = useDispatch();
    const isLogged = useSelector((state: RootState) => state.auth.isLogged);
    const token = useSelector((state: RootState) => state.auth.token);

    const isLoading = useSelector((state: RootState) => state.http.isLoading);

    // useEffect({
    //     window.ResizeObserver = null;
    // },[]);
    // const showErrorModal = useSelector(
    //     (state: RootState) => state.http.error.show
    // );
    // const errorMessage = useSelector(
    //     (state: RootState) => state.http.error.message
    // );

    // const onModalCancelHandler = () => {
    //     dispatch(httpActions.hideError());
    // };

    useEffect(() => {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            BarElement,
            Title,
            Tooltip,
            Legend,
            Filler
        );
    });

    useEffect(() => {
        const translations = {
            mk: translationsMk,
            en: translationsEn,
            al: translationsAl,
        };

        dispatch(translationsActions.loadTranslations(translations));
    }, [dispatch]);

    // this fixes (somehow) an error -> auth fail on page reload
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/firebase.User
            // const uid = user.uid;
            // ...
        } else {
            // User is signed out
            // ...
        }
    });

    return (
        <React.Fragment>
            {isLoading && <GlobalLoader />}
            <ReactNotifications/>
            {/* <ModalErrorComponent
                show={showErrorModal}
                title="Системска грешка"
                text={errorMessage}
                onCancel={onModalCancelHandler}
            /> */}

            <Routes>
                <Route path="/" element={<Navigate to={RoutesEnum.Login} />} />
                <Route path={RoutesEnum.Login} element={<Login />} />
                <Route path="/reset-password/:token" element={<Login />} />
                <Route path={RoutesEnum.Register} element={<Register />} />
                <Route
                    path={RoutesEnum.ResetPassword}
                    element={<ResetPassword />}
                />
                {(isLogged || token) && (
                    <React.Fragment>
                        <Route path={RoutesEnum.Messages} element={<Chat />} />
                        <Route
                            path={RoutesEnum.MessageView}
                            element={<Chat />}
                        />
                        <Route
                            path={RoutesEnum.Advertisements}
                            element={<Advertisements />}
                        />
                        <Route
                            path={RoutesEnum.AdvertisementPreview}
                            element={<AdvertisementEdit />}
                        />
                        <Route
                            path={RoutesEnum.OffersPreview}
                            element={<OffersPreview />}
                        />
                        <Route
                            path={RoutesEnum.CurrentAdvertisements}
                            element={<CurrentAdvertisements />}
                        />
                        <Route
                            path={RoutesEnum.CurrentAdvertisementPreview}
                            element={<AdvertisementPreview />}
                        />
                        <Route
                            path={RoutesEnum.TermsOfService}
                            element={<TermsAndServices />}
                        />
                        <Route
                            path={RoutesEnum.Auction}
                            element={<Auction />}
                        />
                        <Route
                            path={RoutesEnum.AuctionPreview}
                            element={<AuctionBidding />}
                        />
                        <Route
                            path={RoutesEnum.Notifications}
                            element={<NotificationsPage />}
                        />
                        <Route
                            path={RoutesEnum.Profile}
                            element={<ProfilePage />}
                        />
                        <Route path={RoutesEnum.New} element={<NewAd />} />
                        <Route path={RoutesEnum.CompanyInfo} element={<CompanyInfo/>} />
                        <Route path={RoutesEnum.AuctionBidding} element={<AuctionBidding/>} />
                        
                        {/* My Offers */}
                        <Route path={RoutesEnum.MyOffers} element={<MyOffers />} />
                        <Route path={RoutesEnum.MyOfferPreview} element={<MyOfferPreview/>} />
                        <Route path={RoutesEnum.MyOffersAuction} element={<AuctionBidding/>} />
                    </React.Fragment>
                )}
                <Route path="*" element={<Navigate to={RoutesEnum.Advertisements} />} />
            </Routes>
        </React.Fragment>
    );
}

export default App;
