import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../store";

import ConfirmModal from "../Modals/ConfirmRemoveModal/ConfirmModal";

import NumberUp from "../../../../../assets/icons/NumberUp.svg";
import NumberDown from "../../../../../assets/icons/NumberDown.svg";

import classes from "./AdvertisementForm.module.scss";
import AttachImagesModal from "../Modals/AttachImages/AttachImages";

const AdvertisementForm = (props) => {
    const index = props?.formIndex;
    const values = props?.values;
    const errors = props?.errors;

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [showAttachImageModal, setShowAttachImageModal] = useState(false);

    return (
        <React.Fragment>
            <form className={classes.ad_form}>
                <div
                    className={`${classes.input_wrapper} ${(errors?.title?.length > 0) ? classes.has_error : ""}`}
                >
                    <label>{t.create_ad.ad_title}</label>
                    <input
                        type="text"
                        name="title"
                        value={values?.title || ""}
                        required
                        onChange={(e) => {props.onChangeHandler(e, index)}}
                    />
                    <div className={classes.error}>{errors?.title}</div>
                </div>
                <div className={classes.inputs_wrapper}>
                    <div
                        className={`${classes.input_wrapper} ${(errors?.category_id?.length > 0) ? classes.has_error : ""}`}
                    >
                        <label>{t.create_ad.category_title}</label>
                        <Form.Select name="category_id" value={values.category_id} onChange={(e) => {props.onChangeHandler(e, index)}} required>
                            {(props?.categories?.length > 0)
                                ? (
                                    <>
                                        <option value="">{t.create_ad.choose_category}</option>
                                        {props.categories.map((value, i) => {
                                            return (
                                                <option value={value.id} key={i}>{value.name}</option>
                                            )
                                        })}
                                    </>
                                )
                                : (
                                    <option>{t.create_ad.no_categories}</option>
                                )
                            }
                        </Form.Select>
                        <div className={classes.error}>{errors?.category_id}</div>
                    </div>
                    <div
                        className={`${classes.input_wrapper} ${(errors?.quantity?.length > 0) ? classes.has_error : ""}`}
                    >
                        <label>{t.create_ad.quantity_title}</label>
                        <div className={classes.input_container}>
                            <input
                                type="number"
                                name="quantity"
                                min={0}
                                value={values?.quantity}
                                required
                                onChange={(e) => {props.onChangeHandler(e, index)}}
                            />
                            <div className={classes.icons_wrapper}>
                                <img
                                    src={NumberUp}
                                    alt="Increment"
                                    onClick={() => props.onChangeHandler({target: {
                                        value: (values.quantity === "") ? 1 : parseInt(values.quantity) + 1,
                                        name: "quantity",
                                        required: true
                                    }}, index)}
                                />
                                <img
                                    src={NumberDown}
                                    alt="Decrement"
                                    onClick={() => props.onChangeHandler({target: {
                                        value: ((values.quantity === "") || (values.quantity === 0)) ? 0 : parseInt(values.quantity) - 1,
                                        name: "quantity",
                                        required: true
                                    }}, index)}
                                />
                            </div>
                        </div>
                        <div className={classes.error}>{errors?.quantity}</div>
                    </div>
                </div>
                <div
                    className={`${classes.input_wrapper} ${(errors?.description?.length > 0) ? classes.has_error : ""}`}
                >
                    <label>{t.create_ad.description_title}</label>
                    <div className={classes.input_container}>
                        <textarea
                            name="description"
                            value={values?.description || ""}
                            required
                            onChange={(e) => {props.onChangeHandler(e, index)}}
                            rows={4}
                        />
                        <div className={classes.count_characters}><span>{values?.description?.length || 0}</span> / 1000 {t.create_ad.characters}</div>
                    </div>
                    <div className={classes.error}>{errors?.description}</div>
                </div>
                <div className={classes.attach_images_wrapper}>
                    <div className={classes.images_wrapper}>
                        {values.images && (
                            values.images.map((value, i) => {
                                return(
                                    <div className={classes.image_wrapper} key={i}>
                                        <img src={value?.file_url} alt="" />
                                        <div className={classes.icon_wrapper}>
                                            <div className={classes.icon} onClick={() => props.onImageRemove(index, i)}></div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                    <div className={classes.attach_btn_wrapper}>
                        <label>{t.create_ad.attach_photos_desc}</label>
                        <button type="button" onClick={() => setShowAttachImageModal(true)} className={classes.attach_button}>
                            <div className={classes.icon}></div>
                            {t.global.attach}
                        </button>
                    </div>
                </div>
                <div className={classes.horizontal_line}></div>
                <div className={classes.action_btns_wrapper}>
                    {(props.forms > 1) && (
                        <button type="button" className={classes.remove} onClick={() => setShowConfirmModal(!showConfirmModal)}>
                            {t.global.delete}
                        </button>
                    )}
                    <button type="button" className={classes.add_more} onClick={props.addMore}>
                        <div className={classes.icon}></div>
                        {t.create_ad.add_new_ad}
                    </button>
                </div>
            </form>

            <ConfirmModal
                show={showConfirmModal}
                toggleModal={() => setShowConfirmModal(!showConfirmModal)}
                confirm={() => {
                    setShowConfirmModal(!showConfirmModal);
                    props.removeForm(index);
                }}
            />

            {showAttachImageModal && (
                <AttachImagesModal
                    show={showAttachImageModal}
                    images={props?.values?.images}
                    index={index}
                    toggleModal={() => {
                        setShowAttachImageModal(false);
                    }}
                    confirm={(formIndex, images) => {
                        props?.confirmAttachments(formIndex, images);
                        setShowAttachImageModal(false);
                    }}
                />
            )}

        </React.Fragment>
    );
}

export default AdvertisementForm;