import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import TimePicker from "react-bootstrap-time-picker";
import { useSelector } from "react-redux";

import AdvertisementForm from "./AdvertisementForm/AdvertisementForm";
import Switch from "../../../../components/UI/Switch/Switch";
import { IBaseInfo, IAdvertisement } from "./models/form.models";
import RadioButton from "../../../../components/UI/RadioButton/RadioButton";
import Datepicker from "../../../../components/UI/Datepicker/Datepicker";
import { RootState } from "../../../../store";
import { httpPost, httpGet } from "../../../../services/http";
import ConfirmModal from "./Modals/ConfirmRemoveModal/ConfirmModal";
import GlobalLoader from "../../../../components/UI/GlobalLoader/GlobalLoader";
import {
  showNotification,
  hideNotifications,
} from "../../../../notification_functions/notifications_functions";

import ClockIcon from "../../../../assets/icons/Clock-icon.svg";

import classes from "./BaseInfo.module.scss";

const BaseInfo = (props: IBaseInfo) => {
  const navigate = useNavigate();

  const {
    REACT_APP_MAX_LEGAL_DOCUMENTS,
    REACT_APP_MAX_ADDITIONAL_DOCUMENTS,
    REACT_APP_FILES_TYPE,
  }: any = process.env;

  const [advertisement, setAdvertisement]: any = useState<IAdvertisement | any>(
    props?.advertisement
  );
  const [isOnAuction, setIsOnAuction] = useState<boolean>(false);

  const [categories, setCategories]: any = useState([]);

  const [errors, setErrors]: any = useState({
    products: [
      {
        title: "",
        category_id: "",
        quantity: "",
        description: "",
      },
    ],
  });

  useEffect(() => {
    setAdvertisement(props?.advertisement);
    if (props?.advertisement?.hasOwnProperty("aukcija")) {
      setIsOnAuction(props?.advertisement?.aukcija);
    }
  }, [props.advertisement]);

  const fetchCategories = async () => {
    try {
      const response = await httpGet<any>(
        "/industry/get?XDEBUG_SESSION_START=phpstorm"
      );
      if (response?.data?.data.length > 0) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    return () => hideNotifications();
  }, []);

  useEffect(() => {
    const products = props?.advertisement?.products;
    let err: any = [...errors.products];
    if (products && products.length > 0) {
      products.forEach((product, index) => {
        Object.keys(product).forEach((key) => {
          if (
            key === "category" ||
            key === "title" ||
            key === "category_id" ||
            key === "quantity" ||
            key === "description"
          ) {
            if (err[index] !== undefined) {
              err[index][key] = "";
            } else {
              err.push({
                [key]: "",
              });
            }
          }
        });
      });
      setErrors({
        ...errors,
        products: err,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.advertisement?.products]);

  const translations = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage][
      "create_ad"
    ];
  });

  const t = useSelector((state: RootState) => {
    return state.translations.translations[state.translations.appLanguage][
      "global"
    ];
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const addMoreAdvertisements = async () => {
    const products = advertisement?.products;
    const product = products[products.length - 1];
    await setAdvertisement({
      ...advertisement,
      products: [
        ...advertisement.products,
        {
          title: products[products.length - 1].title,
          category_id: products[products.length - 1].category_id,
          quantity: "",
          description: "",
          images: [],
        },
      ],
    });
    await setErrors({
      ...errors,
      products: [
        ...errors.products,
        {
          title: product.title.length > 0 ? "" : translations.input_required,
          category_id:
            product.category_id.length > 0 ? "" : translations.input_required,
          quantity: translations.input_required,
          description: translations.input_required,
        },
      ],
    });
  };

  const RemoveAdForm = (index) => {
    if (index !== undefined) {
      const adsForm: any = { ...advertisement };
      adsForm.products.splice(index, 1);
      setAdvertisement(adsForm);
      const adsErrors = { ...errors };
      adsErrors.products.splice(index, 1);
      setErrors(adsErrors);
    }
  };

  const onPersonalInfoChange = (e) => {
    let { name, value } = e.target;
    if (value === undefined) {
      value = "";
    }

    const newErrors = { ...errors };

    setAdvertisement({
      ...advertisement,
      [name]: value,
    });

    validatePersonalInfoField(name, value, newErrors);
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  }

  const validatePersonalInfoField = (name, value, newErrors) => {
    switch (true) {
      case name === "name" || name === "contact_person":
        newErrors.name =
          value && value.length > 0 ? "" : translations.input_required;
        break;
      case name === "email" || name === "contact_email":
        if (value && value.length > 0) {
          const emailValid = value.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
          );
          newErrors.email = emailValid ? "" : translations.invalid_email;
        } else {
          newErrors.email = translations.input_required;
        }
        break;
      case name === "phone" || name === "contact_phone":
        newErrors.phone =
          value && value.length > 0
            ? isValidPhoneNumber(value)
              ? ""
              : translations.invalid_phone_number
            : translations.input_required;
        break;
      default:
        break;
    }

    setErrors(newErrors);
    return newErrors;
  };

  const confirmAttachedImages = (form_index, images) => {
    const newFormValues: any = [...advertisement.products];
    if (newFormValues[form_index]) {
      newFormValues[form_index]["images"] = images;
      setAdvertisement({
        ...advertisement,
        products: newFormValues,
      });
    }
  };

  const onPravniDokumentiChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (e) => {
      if (e.target) {
        let filename = file.name;
        let dataFile = e.target.result;
        setAdvertisement({
          ...advertisement,
          pravni_dokumenti: [
            ...advertisement.pravni_dokumenti,
            {
              file_name: filename,
              file_url: dataFile,
              existing: false,
            },
          ],
        });
      }
    };
  };

  const onPravniDokumentiRemove = (index) => {
    const pravniDokumenti = [...advertisement.pravni_dokumenti];
    if (pravniDokumenti[index]) {
      pravniDokumenti.splice(index, 1);
      setAdvertisement({
        ...advertisement,
        pravni_dokumenti: pravniDokumenti,
      });
    }
  };

  const onDopolnitelniDokumentiChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (e) => {
      if (e.target) {
        let filename = file.name;
        let dataFile = e.target.result;
        setAdvertisement({
          ...advertisement,
          dopolnitelni_dokumenti: [
            ...advertisement.dopolnitelni_dokumenti,
            {
              file_name: filename,
              file_url: dataFile,
              existing: false,
            },
          ],
        });
      }
    };
  };

  const onDopolnitelniDokumentiRemove = (index) => {
    const dopolnitelniDokumenti = [...advertisement.dopolnitelni_dokumenti];
    if (dopolnitelniDokumenti[index]) {
      dopolnitelniDokumenti.splice(index, 1);
      setAdvertisement({
        ...advertisement,
        dopolnitelni_dokumenti: dopolnitelniDokumenti,
      });
    }
  };

  const removeAdImage = (form_index, index) => {
    const newFormValues = [...advertisement.products];
    if (newFormValues[form_index]) {
      newFormValues[form_index].images.splice(index, 1);
      setAdvertisement({
        ...advertisement,
        products: newFormValues,
      });
    }
  };

  const onAdsFormValueChange = (e, form_index) => {
    const { name, value, required } = e.target;
    const newFormValues = [...advertisement.products];
    if (newFormValues[form_index]) {
      if (name === "description") {
        if (value.length <= 1000) {
          newFormValues[form_index][name] = value;
        }
      } else {
        newFormValues[form_index][name] = value;
      }
    } else {
      newFormValues.push({
        [name]: value,
      });
    }
    if (required) {
      AdsFormValidation(name, value, form_index);
    }

    setAdvertisement({
      ...advertisement,
      products: newFormValues,
    });
  };

  const AdsFormValidation = (name, value, form_index, newErrors = null) => {
    const formErrors = newErrors ? newErrors : [...errors.products];
    let hasChange = false;

    switch (true) {
      case name === "quantity" && (value === 0 || value === ""):
        if (formErrors[form_index] !== undefined) {
          if (value === "") {
            formErrors[form_index][name] = translations.input_required;
          } else if (value === 0) {
            formErrors[form_index][name] =
              translations.error_value_grater_then_0;
          }
        } else {
          formErrors.push({
            [name]:
              value === 0
                ? translations.error_value_grater_then_0
                : translations.input_required,
          });
        }
        hasChange = true;
        break;
      case typeof value === "string" && value.length === 0:
        if (formErrors[form_index] !== undefined) {
          formErrors[form_index][name] = translations.input_required;
        } else {
          formErrors.push({
            [name]: translations.input_required,
          });
        }
        hasChange = true;
        break;
      default:
        if (formErrors[form_index] !== undefined) {
          if (formErrors[form_index].hasOwnProperty(name)) {
            delete formErrors[form_index][name];
            hasChange = true;
          }
        } else {
          formErrors.push({});
          hasChange = true;
        }
        break;
    }

    if (hasChange) {
      if (!newErrors) {
        setErrors({
          ...errors,
          products: formErrors,
        });
      }
    }
    return formErrors;
  };

  const onDatumOddrzuvanjeAukcija = (date) => {
    setAdvertisement((state) => {
      return {
        ...state,
        datum_oddrzuvanje_aukcija: date,
      };
    });
    if (date) {
      setErrors({
        ...errors,
        datum_oddrzuvanje_aukcija: "",
      });
    } else {
      setErrors({
        ...errors,
        datum_oddrzuvanje_aukcija: translations.input_required,
      });
    }
  };

  const onDatumZaDostavuvanje = (date) => {
    setAdvertisement((state) => {
      return {
        ...state,
        datum_za_dostavuvanje: date,
      };
    });
    if (date) {
      setErrors({
        ...errors,
        datum_za_dostavuvanje: "",
      });
    } else {
      setErrors({
        ...errors,
        datum_za_dostavuvanje: translations.input_required,
      });
    }
  };

  const checkRequiredFields = (data) => {
    let newErrors = { ...errors };

    function checkAuctionFileds(auction, name, value) {
      if (auction === true) {
        if (value === null || value.length === 0) {
          newErrors[name] = translations.input_required;
        } else {
          newErrors[name] = "";
        }
      } else {
        newErrors[name] = "";
      }
    }

    function checkFieldsValues(name, value) {
      if (value && value.length > 0) {
        newErrors[name] = "";
      } else {
        newErrors[name] = translations.input_required;
      }
    }

    return new Promise((resolve) => {
      if (data) {
        Object.keys(data).forEach((dataKey) => {
          switch (dataKey) {
            case "contactPerson":
              if (data[dataKey]) {
                Object.keys(data[dataKey]).forEach((key) => {
                  newErrors = validatePersonalInfoField(
                    key,
                    data[dataKey][key],
                    newErrors
                  );
                });
              }
              break;
            case "adsFormValues":
              if (data[dataKey] && data[dataKey].length > 0) {
                data[dataKey].forEach((adsForm, form_index) => {
                  Object.keys(adsForm).forEach((name) => {
                    const value = adsForm[name];
                    if (
                      name === "title" ||
                      name === "category" ||
                      name === "description"
                    ) {
                      if (value && value.toString().length > 0) {
                        newErrors.products[form_index][name] = "";
                      } else {
                        newErrors.products[form_index][name] =
                          translations.input_required;
                      }
                    } else if (name === "quantity") {
                      if (value === "") {
                        newErrors.products[form_index][name] =
                          translations.input_required;
                      } else if (value === 0) {
                        newErrors.products[form_index][name] =
                          translations.error_value_grater_then_0;
                      }
                    }
                  });
                });
              }
              break;
            case "datum_oddrzuvanje_aukcija":
              checkAuctionFileds(data.aukcija, dataKey, data[dataKey]);
              break;
            case "od_vreme_oddrzuvanje_aukcija":
              checkAuctionFileds(data.aukcija, dataKey, data[dataKey]);
              break;
            case "do_vreme_oddrzuvanje_aukcija":
              checkAuctionFileds(data.aukcija, dataKey, data[dataKey]);
              break;
            case "datum_za_dostavuvanje":
              checkFieldsValues(dataKey, data[dataKey]);
              break;
            case "dostavuvanje_ponudi_vreme":
              checkFieldsValues(dataKey, data[dataKey]);
              break;
            case "pravni_dokumenti":
              checkFieldsValues(dataKey, data[dataKey]);
              break;
            default:
              break;
          }
        });
      }
      setErrors(newErrors);
      resolve(newErrors);
    });
  };

  const checkForErrors = (new_errors) => {
    let has_error = false;
    function errors_check(newErrors) {
      Object.keys(newErrors).forEach((key) => {
        if (typeof newErrors[key] === "object") {
          errors_check(newErrors[key]);
        } else {
          if (newErrors[key].length > 0) {
            has_error = true;
          }
        }
      });
    }
    errors_check(new_errors);
    return has_error;
  };

  const saveData = async () => {
    const dataToSend = JSON.parse(JSON.stringify(advertisement));
    dataToSend.contactPerson = {};

    Object.keys(dataToSend).forEach((key) => {
      switch (key) {
        case "contact_email":
          dataToSend.contactPerson.email = dataToSend[key];
          delete dataToSend[key];
          break;
        case "contact_person":
          dataToSend.contactPerson.name = dataToSend[key];
          delete dataToSend[key];
          break;
        case "contact_phone":
          dataToSend.contactPerson.phone = dataToSend[key];
          delete dataToSend[key];
          break;
        case "products":
          dataToSend.adsFormValues = dataToSend[key].map((prod) => {
            prod.product_id = prod.id;
            prod.category = prod.category_id;
            if (prod?.images && prod?.images?.length > 0) {
              prod.images = prod.images.map((img) => {
                if (!img.hasOwnProperty("existing")) {
                  img["existing"] = true;
                  img["upload_type"] = 2;
                }
                if (img.hasOwnProperty("uploaded")) {
                  delete img.uploaded;
                }
                return img;
              });
            }
            delete prod.id;
            return prod;
          });
          delete dataToSend[key];
          break;
        case "auction":
          delete dataToSend[key];
          break;
        case "id":
          dataToSend.ad_id = dataToSend[key];
          delete dataToSend[key];
          break;
        case "vreme_oddrzuvanje_aukcija_od":
          dataToSend.od_vreme_oddrzuvanje_aukcija = dataToSend[key];
          delete dataToSend[key];
          break;
        case "vreme_oddrzuvanje_aukcija_do":
          dataToSend.do_vreme_oddruzvenje_aukcija = dataToSend[key];
          delete dataToSend[key];
          break;
        case "dopolnitelni_dokumenti":
        case "pravni_dokumenti":
          if (dataToSend[key]?.length > 0) {
            dataToSend[key] = dataToSend[key].map((doc) => {
              if (!doc.hasOwnProperty("existing")) {
                doc["existing"] = true;
              }
              return doc;
            });
          }
          break;
        default:
          break;
      }
    });

    checkRequiredFields(dataToSend).then(async (newErrors) => {
      const hasErrors = checkForErrors(newErrors);
      if (hasErrors) {
        showNotification(translations.invalid_fields, "danger");
      } else {
        setIsLoading(true);
        try {
          const response = await httpPost<any, any>(
            "/tender/update?XDEBUG_SESSION_START=phpstorm",
            dataToSend
          );
          if (response.message === "Success") {
            navigate("/my-advertisements");
          } else {
            setIsLoading(false);
            showNotification(t.error_message, "danger");
          }
        } catch (error) {
          setIsLoading(false);
          showNotification(t.error_message, "danger");
          console.error(error);
        }
      }
    });
  };

  const confirmAdDelete = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const response = await httpPost<any, any>(
        "/tender/delete?XDEBUG_SESSION_START=phpstorm",
        { ad_id: advertisement.id }
      );
      if (response.message === "Success") {
        navigate("/my-advertisements");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const onAuctionHandler = (auction) => {
    const adData = { ...advertisement };
    const newErrors = { ...errors };
    if (auction === false) {
      adData.datum_oddrzuvanje_aukcija = "";
      adData.vreme_oddrzuvanje_aukcija_od = "";
      adData.vreme_oddrzuvanje_aukcija_do = "";
      delete newErrors.datum_oddrzuvanje_aukcija;
      delete newErrors.od_vreme_oddrzuvanje_aukcija;
      delete newErrors.do_vreme_oddrzuvanje_aukcija;
      setErrors(newErrors);
    } else {
      setErrors((state) => {
        return {
          ...state,
          datum_oddrzuvanje_aukcija: translations.input_required,
          od_vreme_oddrzuvanje_aukcija: translations.input_required,
          do_vreme_oddrzuvanje_aukcija: translations.input_required,
        };
      });
    }
    adData.auction = auction;
    adData.aukcija = auction;
    setIsOnAuction(auction);
    setAdvertisement(adData);
  };

  return (
    <React.Fragment>
      <div className={classes.contact_person_wrapper}>
        <div className={classes.label_title}>{translations.contact_person}</div>
        <form className={classes.contact_person_form}>
          <div className={classes.col_one}>
            <div
              className={`${classes.input_wrapper} ${
                errors?.name?.length > 0 ? classes.has_error : ""
              }`}
            >
              <label>{translations.name_surname}</label>
              <input
                type="text"
                name="contact_person"
                value={advertisement?.contact_person}
                required
                onChange={(e) => onPersonalInfoChange(e)}
              />
              <div className={classes.error}>{errors?.name}</div>
            </div>

            <div
              className={`${classes.input_wrapper} ${
                errors?.phone?.length > 0 ? classes.has_error : ""
              }`}
            >
              <label>{translations.phone_number}</label>
              <PhoneInput
                name="contact_phone"
                required
                value={advertisement?.contact_phone}
                onChange={(value) =>
                  onPersonalInfoChange({
                    target: { value, name: "contact_phone", required: true },
                  })
                }
              />
              <div className={classes.error}>{errors?.phone}</div>
            </div>
          </div>
          <div className={classes.col_two}>
            <div
              className={`${classes.input_wrapper} ${
                errors?.email?.length > 0 ? classes.has_error : ""
              }`}
            >
              <label>{translations.email}</label>
              <input
                type="email"
                name="contact_email"
                required
                value={advertisement?.contact_email}
                onChange={(e) => onPersonalInfoChange(e)}
              />
              <div className={classes.error}>{errors?.email}</div>
            </div>
          </div>
        </form>
      </div>

      <div className={classes.ads_wrapper}>
        <div className={classes.label_title}>{translations.advertisement}</div>
        <div id="ads_form_wrapper">
          {advertisement?.products &&
            advertisement?.products.map((values, index) => {
              return (
                <AdvertisementForm
                  addMore={addMoreAdvertisements}
                  key={index}
                  formIndex={index}
                  removeForm={RemoveAdForm}
                  onChangeHandler={onAdsFormValueChange}
                  values={values}
                  errors={errors.products[index]}
                  forms={advertisement?.products.length}
                  onImageRemove={removeAdImage}
                  categories={categories}
                  confirmAttachments={confirmAttachedImages}
                />
              );
            })}
        </div>
      </div>

      <div className={classes.ad_time_and_criteria_wrapper}>
        <div className={classes.label_title}>
          {translations.date_and_criteria_title}
        </div>
        <form className={classes.form_wrapper}>
          <div className={classes.input_wrapper}>
            <label>{translations.delivery_date_title}</label>
            <Datepicker
              field={{
                value: advertisement?.datum_isporaka,
                name: "datum_isporaka",
              }}
              onInputChange={(date) => {
                setAdvertisement({
                  ...advertisement,
                  datum_isporaka: date,
                });
              }}
              placeholder=""
              preventPreviousDates={true}
              customClasses={classes.data_picker_wrapper}
              required={true}
              containerStyle={{
                maxWidth: "360px",
              }}
            />
          </div>
          <div className={classes.input_wrapper}>
            <label>{translations.criteria_title}</label>
            <div className={classes.radio_buttons_wrapper}>
              <RadioButton
                label={translations.no_criteria}
                name="kriterium"
                value="0"
                customClasses={classes.criteria_wrapper}
                onChange={() =>
                  setAdvertisement({
                    ...advertisement,
                    kriterium: "0",
                  })
                }
                required={true}
                checked={
                  advertisement?.kriterium === "0" ||
                  advertisement?.kriterium === null
                }
              />
              <RadioButton
                label={translations.lowest_price}
                name="kriterium"
                value="1"
                customClasses={classes.criteria_wrapper}
                onChange={() =>
                  setAdvertisement({
                    ...advertisement,
                    kriterium: "1",
                  })
                }
                required={true}
                checked={advertisement?.kriterium === "1"}
              />
              <RadioButton
                label={translations.quality}
                name="kriterium"
                value="2"
                customClasses={classes.criteria_wrapper}
                onChange={() =>
                  setAdvertisement({
                    ...advertisement,
                    kriterium: "2",
                  })
                }
                required={true}
                checked={advertisement?.kriterium === "2"}
              />
            </div>
          </div>
        </form>
      </div>

      <div className={classes.auction_wrapper}>
        <div className={classes.label_title}>{translations.auction_title}</div>
        <div className={classes.inputs_wrapper}>
          <div className={classes.switch_wrapper}>
            <label>{translations.auction_title}</label>
            <div className={classes.switch}>
              <label>{t.no}</label>
              <Switch
                label=""
                name="auction"
                onChange={(value) => {
                  onAuctionHandler(value);
                }}
                customClasses={classes.custom_switch}
                checked={advertisement?.auction}
              />
              <label className={classes.active}>{t.yes}</label>
            </div>
          </div>
          <div className={classes.auction_date}>
            <div
              className={`${classes.date_wrapper} ${
                errors?.datum_oddrzuvanje_aukcija?.length > 0
                  ? classes.has_error
                  : ""
              }`}
            >
              <label>{translations.auction_date}</label>
              <Datepicker
                field={{
                  value: advertisement?.datum_oddrzuvanje_aukcija,
                  name: "datum_aukcija",
                }}
                onInputChange={onDatumOddrzuvanjeAukcija}
                placeholder=""
                preventPreviousDates={true}
                customClasses={classes.data_picker_wrapper}
                required={true}
                disabled={!isOnAuction}
                containerClass={!isOnAuction ? classes.auctionDateDisabled : ""}
                hasError={errors?.datum_oddrzuvanje_aukcija?.length > 0}
              />
              <div className={classes.error}>
                {errors?.datum_oddrzuvanje_aukcija}
              </div>
            </div>
            <div
              className={`${classes.time_from_wrapper} ${
                errors?.od_vreme_oddrzuvanje_aukcija?.length > 0
                  ? classes.has_error
                  : ""
              }`}
            >
              <label>{t.from}:</label>
              <div className={classes.input_wrapper}>
                <TimePicker
                  step={30}
                  start="00:00"
                  end="23:30"
                  value={advertisement?.vreme_oddrzuvanje_aukcija_od}
                  onChange={(time) => {
                    setAdvertisement({
                      ...advertisement,
                      vreme_oddrzuvanje_aukcija_od: formatTime(time),
                    });
                    setErrors({
                      ...errors,
                      od_vreme_oddrzuvanje_aukcija: "",
                    });
                  }}
                  format={24}
                  disabled={!isOnAuction}
                />
                <img src={ClockIcon} alt="Clock" />
              </div>
              <div className={classes.error}>
                {errors?.od_vreme_oddrzuvanje_aukcija}
              </div>
            </div>
            <div
              className={`${classes.time_to_wrapper} ${
                errors?.do_vreme_oddrzuvanje_aukcija?.length > 0
                  ? classes.has_error
                  : ""
              }`}
            >
              <label>{t.to}:</label>
              <div className={classes.input_wrapper}>
                <TimePicker
                  step={30}
                  start="00:00"
                  end="23:30"
                  value={advertisement?.vreme_oddrzuvanje_aukcija_do}
                  onChange={(time) => {
                    setAdvertisement({
                      ...advertisement,
                      vreme_oddrzuvanje_aukcija_do: formatTime(time),
                    });
                    setErrors({
                      ...errors,
                      do_vreme_oddrzuvanje_aukcija: "",
                    });
                  }}
                  format={24}
                  disabled={!isOnAuction}
                />
                <img src={ClockIcon} alt="Clock" />
              </div>
              <div className={classes.error}>
                {errors?.do_vreme_oddrzuvanje_aukcija}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.administrative_info_wrapper}>
        <div className={classes.label_title}>
          {translations.administrative_info_title}
        </div>
        <div className={classes.administrative_info}>
          <div className={classes.row_one}>
            <label>{translations.submission_last_date}</label>
            <div className={classes.inputs_wrapper}>
              <div
                className={`${classes.input_wrapper} ${
                  errors?.datum_za_dostavuvanje?.length > 0
                    ? classes.has_error
                    : ""
                }`}
              >
                <Datepicker
                  field={{
                    value: advertisement?.datum_za_dostavuvanje,
                    name: "datum_za_dostavuvanje",
                  }}
                  onInputChange={onDatumZaDostavuvanje}
                  placeholder=""
                  preventPreviousDates={true}
                  customClasses={classes.data_picker_wrapper}
                  required={true}
                  hasError={errors?.datum_za_dostavuvanje?.length > 0}
                />
                <div className={classes.error}>
                  {errors?.datum_za_dostavuvanje}
                </div>
              </div>
              <div
                className={`${classes.input_wrapper} ${
                  errors?.dostavuvanje_ponudi_vreme?.length > 0
                    ? classes.has_error
                    : ""
                }`}
              >
                <TimePicker
                  step={30}
                  start="00:00"
                  end="23:30"
                  value={advertisement?.dostavuvanje_ponudi_vreme}
                  onChange={(time) => {
                    setAdvertisement({
                      ...advertisement,
                      dostavuvanje_ponudi_vreme: formatTime(time),
                    });
                    setErrors({
                      ...errors,
                      dostavuvanje_ponudi_vreme: "",
                    });
                  }}
                  format={24}
                />
                <img src={ClockIcon} alt="Clock" />
                <div className={classes.error}>
                  {errors?.dostavuvanje_ponudi_vreme}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.row_two}>
            <div className={classes.inputs_wrapper}>
              <label>{translations.deadline_for_questions}</label>
              <div className={classes.inputs_container}>
                <div className={classes.input_wrapper}>
                  <Datepicker
                    field={{
                      value: advertisement?.datum_za_prasanja,
                      name: "datum_za_prasanja",
                    }}
                    onInputChange={(date) => {
                      setAdvertisement({
                        ...advertisement,
                        datum_za_prasanja: date,
                      });
                    }}
                    placeholder=""
                    preventPreviousDates={true}
                    customClasses={classes.data_picker_wrapper}
                    required={true}
                  />
                </div>
                <div className={classes.input_wrapper}>
                  <TimePicker
                    step={30}
                    start="00:00"
                    end="23:30"
                    value={advertisement?.vreme_za_prasanja}
                    onChange={(time) => {
                      setAdvertisement({
                        ...advertisement,
                        vreme_za_prasanja: formatTime(time),
                      });
                    }}
                    format={24}
                  />
                  <img src={ClockIcon} alt="Clock" />
                </div>
              </div>
            </div>
            <div className={classes.inputs_wrapper}>
              <label>{translations.deadline_for_answers}</label>
              <div className={classes.inputs_container}>
                <div className={classes.input_wrapper}>
                  <Datepicker
                    field={{
                      value: advertisement?.datum_za_odgovori,
                      name: "datum_za_odgovori",
                    }}
                    onInputChange={(date) => {
                      setAdvertisement({
                        ...advertisement,
                        datum_za_odgovori: date,
                      });
                    }}
                    placeholder=""
                    preventPreviousDates={true}
                    customClasses={classes.data_picker_wrapper}
                    required={true}
                  />
                </div>
                <div className={classes.input_wrapper}>
                  <TimePicker
                    step={30}
                    start="00:00"
                    end="23:30"
                    value={advertisement?.vreme_za_odgovori}
                    onChange={(time) => {
                      setAdvertisement({
                        ...advertisement,
                        vreme_za_odgovori: formatTime(time),
                      });
                    }}
                    format={24}
                  />
                  <img src={ClockIcon} alt="Clock" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.technical_info_wrapper}>
        <div className={classes.label_title}>
          {translations.technical_info_title}
        </div>
        <div className={classes.technical_info}>
          <label>{translations.registered_activity}</label>
          {advertisement?.pravni_dokumenti && (
            <div className={classes.uploaded_docs}>
              {advertisement.pravni_dokumenti.map((value, i) => {
                return (
                  <div className={classes.uploaded_doc} key={i}>
                    <div className={classes.document_icon}></div>
                    <div className={classes.file_name}>{value.file_name}</div>
                    <div
                      className={classes.close_icon}
                      onClick={() => onPravniDokumentiRemove(i)}
                    ></div>
                  </div>
                );
              })}
            </div>
          )}
          <div
            className={`${classes.upload_doc} ${
              errors?.pravni_dokumenti?.length > 0 ? classes.has_error : ""
            }`}
          >
            {advertisement?.pravni_dokumenti?.length <
              REACT_APP_MAX_LEGAL_DOCUMENTS && (
              <>
                <div
                  className={classes.attach_wrapper}
                  onClick={() => {
                    document.getElementById("add_document")?.click();
                  }}
                >
                  <div className={classes.icon}></div>
                  <div className={classes.text}>
                    {translations.add_document}
                  </div>
                </div>
                <div className={classes.error}>{errors?.pravni_dokumenti}</div>
              </>
            )}
            <input
              type="file"
              accept={REACT_APP_FILES_TYPE}
              style={{ display: "none" }}
              onChange={(e) => onPravniDokumentiChange(e)}
              name="add_document"
              id="add_document"
            />
          </div>
        </div>
      </div>

      <div className={classes.additional_documents_wrapper}>
        <div className={classes.label_title}>
          {translations.additional_docs}&nbsp;
          <span>{translations.max_docs_attach}</span>
        </div>
        <div className={classes.uploaded_docs}>
          {advertisement?.dopolnitelni_dokumenti &&
            advertisement.dopolnitelni_dokumenti.map((value, i) => {
              return (
                <div className={classes.uploaded_doc} key={i}>
                  <div className={classes.document_icon}></div>
                  <div className={classes.file_name}>{value.file_name}</div>
                  <div
                    className={classes.close_icon}
                    onClick={() => onDopolnitelniDokumentiRemove(i)}
                  ></div>
                </div>
              );
            })}
        </div>
        <div className={classes.button_wrapper}>
          {advertisement?.dopolnitelni_dokumenti?.length <
            REACT_APP_MAX_ADDITIONAL_DOCUMENTS && (
            <div
              className={classes.add_additional_docs_btn}
              onClick={() => {
                document.getElementById("additional_docs")?.click();
              }}
            >
              <div className={classes.icon}></div>
              <div className={classes.text}>{translations.add_new_docs}</div>
            </div>
          )}
          <input
            type="file"
            name="additional_docs"
            id="additional_docs"
            onChange={(e) => onDopolnitelniDokumentiChange(e)}
            accept={REACT_APP_FILES_TYPE}
            style={{ display: "none" }}
          />
        </div>
      </div>

      <div className={classes.footer_wrapper}>
        <button
          type="button"
          className={classes.delete_btn}
          onClick={() => setShowConfirmModal(true)}
        >
          {t.delete}
        </button>
        <div className={classes.buttons_wrapper}>
          <button
            type="button"
            className={classes.cancel_btn}
            onClick={() => navigate("/my-advertisements")}
          >
            {t.cancel}
          </button>
          <button type="button" className={classes.save_btn} onClick={saveData}>
            {t.save}
          </button>
        </div>
      </div>
      <ConfirmModal
        show={showConfirmModal}
        toggleModal={() => setShowConfirmModal(false)}
        confirm={confirmAdDelete}
      />
      <GlobalLoader show={isLoading} />
    </React.Fragment>
  );
};

export default BaseInfo;
