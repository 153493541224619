export enum RoutesEnum {
	Login = '/login',
	Register = '/register',
	ResetPassword = '/reset-password',
	
	Messages = '/messages',
	MessageView = '/messages/:messageId',
	OffersPreview = "/my-advertisements/offers/:id",
	Advertisements = '/my-advertisements',
	AdvertisementPreview = '/my-advertisements/:id',
	CurrentAdvertisements = '/advertisements',
	CurrentAdvertisementPreview = '/advertisements/:id',
	TermsOfService = '/terms-of-service',
	Auction = '/auction',
	AuctionPreview = '/auction/:id',
	Notifications = '/notifications',
	New = '/new',
	CompanyInfo = "/company",
	AuctionBidding = "/auction/auction-bidding/:id",
	Profile = "/profile",

	MyOffers = "/my-offers",
	MyOfferPreview = "/my-offers/preview/:id",
	MyOffersAuction = "/my-offers/auction/:id"
}