import React from "react";
import classes from "./Avatar.module.scss";

import LockLight from "./../../../../assets/icons/LockLight.svg";

export interface IAvatar {
    initials: string;
    customClass?: string;
    userImg?: string;
}

const Avatar = (props: IAvatar) => {
    return (
        <React.Fragment>
            <div className={`${classes.Avatar} ${props?.customClass || ""}`}>
                <img
                    className={classes.LockShade}
                    src={props.userImg ? props.userImg : LockLight}
                    alt="lock light"
                />
                <div className={classes.Initials}>{props.initials}</div>
            </div>
        </React.Fragment>
    );
};

export default Avatar;
