import { AxiosRequestConfig } from "axios";
import axiosInstance from "./instance";
import { LocalStorageEnum } from "../../enums/local-storage-keys";

const httpGet = <T>(url: string, contentType = 'application/json') => {
	let token = localStorage.getItem(LocalStorageEnum.UserToken);
	const requestConfig: AxiosRequestConfig = {
		headers: {
			'Content-Type': contentType,
			'Authorization': `Bearer ${token}`
		}
	}
	return axiosInstance.get<T>(url, requestConfig)
}

export default httpGet;