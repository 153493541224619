import React, { useState, useEffect } from "react";
import SlidingPane from "react-sliding-pane";
import { useSelector } from "react-redux";

import { RootState } from "../../../../../store";
import { httpPost } from "../../../../../services/http";

import { IEditOffer, IOffers, IDocument } from "./edit-offer.model";

import FileText from "../../../../../assets/icons/FileText.svg";
import CloseIcon from "../../../../../assets/icons/CloseAccent.svg";
import PlusIcon from "../../../../../assets/icons/Plus.svg";

import "react-sliding-pane/dist/react-sliding-pane.css";
import classes from "./EditOffer.module.scss";

const EditOfferModal = (props:IEditOffer) => {

    const { REACT_APP_FILES_TYPE }:any = process.env;

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const t_global = t.global;
    const t_offers = t.offers;

    const [offers, setOffers]:any = useState<IOffers[]>([]);
    const [comment, setComment] = useState<string>("");
    const [documents, setDocuments]:any = useState<IDocument[]>([]);

    useEffect(() => {
        if (props?.products && (props?.products?.length > 0)) {
            props.products.forEach((prod) => {
                setOffers(prevState => ([
                    ...prevState,
                    { price: prod?.offer_price || "" }
                ]));
            });

        }
    }, [props?.products]);

    useEffect(() => {
        setComment(props?.comment);
    }, [props?.comment]);

    const additionalDocsAdd = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
      
        reader.readAsDataURL(file);

        reader.onload = (e) => {
            if (e.target) {
                let filename = file.name;
                let dataFile = e.target.result;
                setDocuments(prevState => ([
                    ...prevState,
                    {
                        file_name: filename,
                        file_url: dataFile,
                        existing: false
                    }
                ]));
            }
        };
    };

    const additionalDocsRemove = (index) => {
        const additionlDocs = [...documents];
        if (additionlDocs[index]) {
            additionlDocs.splice(index, 1);
            setDocuments(additionlDocs);
        }
    }

    const SaveOffer = async () => {
        const offer_id = parseInt(props?.offer_id);
        const dataToSend = {};
        dataToSend["products"] = props?.products.map((product, index) => {
            return {
                offer_id,
                product_id: product.id,
                offer_price: offers[index]?.price || ""
            }
        });
        dataToSend["offer_id"] = offer_id;
        dataToSend["comment"] = comment;
        dataToSend["documents"] = documents.map((doc) => {
            if(!doc.hasOwnProperty("existing")) {
                doc["existing"] = true;
            }
            return doc;
        });
        
        try {
            const response = await httpPost<any, any>(`offer/update}?&XDEBUG_SESSION_START=phpstorm`, dataToSend);
            console.log(response);
            // TODO: Handle status or message to show notification to user
            // 1. onSidebarClose() -> close modal on success and show success notification
            // 2. Show error notification on error
        } catch (error) {
            // Show error notification on error
            console.error(error);
        };
    }
    

    return(
        <React.Fragment>
            <SlidingPane
                className={classes.modal_style}
                overlayClassName={classes.overlay_style}
                isOpen={props.isPaneOpen}
                title={t_offers.edit_offer}
                onRequestClose={props.onSidebarClose}
                from="right"
            >
                <div className={classes.body_wrapper}>
                    <div className={classes.products_wrapper}>
                        {(props?.products && (props?.products?.length > 0)) && (
                            props.products.map((product, index) => {
                                return(
                                    <div className={classes.product_wrapper} key={index}>
                                        {/* <div className={classes.checkbox_wrapper}>
                                            <label htmlFor={`check_product_${index}`}>
                                                <input type="checkbox" name={`product_${index}`} id={`check_product_${index}`} />
                                                <span className={classes.checkmark}></span>
                                            </label>
                                        </div> */}
                                        <div className={classes.product}>
                                            <div className={classes.title}>
                                                {product?.title}
                                            </div>
                                            <div className={classes.category}>
                                                {product?.category}
                                            </div>
                                            <div className={classes.description}>
                                                {product?.description}
                                            </div>
                                            <div className={classes.quantity_price}>
                                                <div className={classes.quamtity_wrapper}>
                                                    <div className={classes.label}>
                                                        {t_offers.quantity_requested}
                                                    </div>
                                                    <div className={classes.value}>
                                                        {product?.quantity}
                                                    </div>
                                                </div>
                                                <div className={classes.price_wrapper}>
                                                    <div className={classes.label}>
                                                        {t_offers.offered_price}:
                                                    </div>
                                                    <div className={classes.value}>
                                                        <input
                                                            type="number"
                                                            value={offers[index]?.price}
                                                            name="price"
                                                            onChange={(e) => {
                                                                const newOffers = [...offers];
                                                                if (newOffers[index] && newOffers[index]?.price) {
                                                                    newOffers[index].price = e.target.value;
                                                                } else {
                                                                    newOffers[index] = { price: e.target.value }
                                                                }
                                                                setOffers(newOffers);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={classes.currency}>
                                                        {t_global.currency_MKD}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                    <div className={classes.additional_info_wrapper}>
                        <div className={classes.additional_docs_wrapper}>
                            <div className={classes.label}>
                                {t_offers.additional_docs}
                            </div>
                            <label htmlFor="additionl_doc" className={classes.add_doc_btn}>
                                <img src={PlusIcon} alt="Add document" />
                                {t_offers.add_new_documents}
                                <input
                                    type="file"
                                    name="additionl_doc"
                                    id="additionl_doc"
                                    accept={REACT_APP_FILES_TYPE}
                                    style={{display: "none"}}
                                    multiple={true}
                                    onChange={additionalDocsAdd}
                                />
                            </label>
                            <div className={classes.docs_wrapper}>
                                {documents.map((doc, i) => {
                                    return(
                                        <div className={classes.doc_wrapper} key={i}>
                                            <img className={classes.file_icon} src={FileText} alt="File" />
                                            <div className={classes.file_name}>{doc.file_name}</div>
                                            <img
                                                className={classes.close_icon}
                                                src={CloseIcon}
                                                alt="Close"
                                                onClick={() => additionalDocsRemove(i)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={classes.comment_wrapper}>
                            <div className={classes.label}>
                                {t_offers.comment}
                            </div>
                            <label>{t_offers.add_comment}</label>
                            <textarea
                                name="comment"rows={5}
                                placeholder={t_offers.comment}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className={classes.footer_wrapper}>
                    <div
                        className={classes.close_btn}
                        onClick={props.onSidebarClose}
                    >
                        {t_global.cancel}
                    </div>
                    <div
                        className={classes.submit_btn}
                        onClick={SaveOffer}
                    >
                        {t_offers.edit_offer}
                    </div>
                </div>
            </SlidingPane>
        </React.Fragment>
    )
}

export default EditOfferModal;