import dayjs from "dayjs";
import { v4 } from "uuid";
import { ICurrency } from "../../../../../../models/data/currencies";
import { IBaseField } from "../../../../../../models/field-validation.model";
import { ICompanyIndustry } from "../../../../../../store/register/register.model";

export const categories = [
    { value: "Kategorija1", label: "Категорија 1" },
    { value: "Kategorija2", label: "Категорија 2" },
    { value: "Kategorija3", label: "Категорија 3" },
];

export const currencies = [
    { value: "MKD", label: "MKD" },
    { value: "EUR", label: "EUR" },
    { value: "DOL", label: "DOL" },
    { value: "AUD", label: "AUD" },
    { value: "LEK", label: "LEK" },
    { value: "LIR", label: "LIR" },
];

export const categoryField: IBaseField<{ value: string; label: string }> = {
    value: categories[0],
    name: "category",
};

export const rokNaTraenje: IBaseField<Date> = {
    value: new Date(),
    name: "rok_na_traenje",
};
export const rokNaPlakjanje: IBaseField<Date> = {
    value: new Date(),
    name: "rok_na_plakjanje",
};
export const rokNaIsporaka: IBaseField<Date> = {
    value: new Date(),
    name: "rok_na_isporaka",
};

export const imeProizvod: IBaseField<string> = {
    value: "",
    name: "ime_proizvod",
};

export const kolicinaProizvod: IBaseField<string> = {
    value: "",
    name: "kolicina_proizvod",
};

export const opisProizvod: IBaseField<string> = {
    value: "",
    name: "opis_proizvod",
};

export const rangeIndicator: IBaseField<[number, number]> = {
    value: [0, 120000],
    name: "range_indicator",
};

export const priceMin: IBaseField<number> = {
    value: 0,
    name: "price_min",
};

export const priceMax: IBaseField<number> = {
    value: 6000,
    name: "price_max",
};

export const kriterium: IBaseField<number> = {
    value: 0,
    name: "kriterium",
};

export const currencyField: IBaseField<{ value: string; label: string }> = {
    value: currencies[0],
    name: "currency",
};

export const termsAndServices: IBaseField<boolean> = {
    value: false,
};

export const cenaBezLimit: IBaseField<boolean> = {
    value: false,
    name: "unlimited_price",
};

// *********************************************
// *********************************************

const ID: IBaseField<string> = {
    value: v4(),
    name: "create_date",
    skipValidation: true,
};

const CREATE_DATE: IBaseField<string> = {
    value: dayjs().format("YYYY-MM-DD"),
    name: "create_date",
    skipValidation: true,
};

const DOGOVOREN_ORGAN: IBaseField<string> = {
    value: "",
    name: "dogovoren_organ",
    skipValidation: true,
};

const ROK_NA_TRAENJE: IBaseField<string> = {
    // value: dayjs().format("YYYY-MM-DD"),
    value: "",
    name: "rok_na_traenje",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const ROK_NA_PLAKJANJE: IBaseField<string> = {
    // value: dayjs().format("YYYY-MM-DD"),
    value: "",
    name: "rok_na_plakjanje",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const ROK_NA_ISPORAKA: IBaseField<string> = {
    // value: dayjs().format("YYYY-MM-DD"),
    value: "",
    name: "rok_na_isporaka",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const IME: IBaseField<string> = {
    value: "",
    name: "ime",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const KOLICINA: IBaseField<number | string> = {
    value: "",
    name: "kolicina",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const KATEGORIJA: IBaseField<ICompanyIndustry | undefined> = {
    value: undefined,
    name: "kategorija",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const CENA_MIN: IBaseField<number> = {
    value: 1000,
    name: "cena_min",
    validators: {
        required: true,
    },
    // errorMessage: 'Задолжително поле'
};

const VALUTA: IBaseField<ICurrency | undefined> = {
    value: undefined,
    name: "valuta",
};

const BEZ_LIMIT: IBaseField<boolean> = {
    value: false,
    name: "bez_limit",
    skipValidation: true,
};

const KRITERIUM: IBaseField<number> = {
    value: 0,
    name: "kriterium",
    skipValidation: true,
};

const OPSEG_NA_CENA: IBaseField<[number, number]> = {
    value: [1000, 60000],
    name: "opseg_na_cena",
};

const CENA_MAX: IBaseField<number> = {
    value: 60000,
    name: "cena_max",
    validators: {
        required: true,
    },
    // errorMessage: 'Задолжително поле'
};

const OPIS: IBaseField<string> = {
    value: "",
    name: "opis",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const DOKUMENTI: IBaseField<any[]> = {
    value: [],
    name: "dokumenti",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

const IMAGE: IBaseField<any> = {
    value: undefined,
    name: "image",
    validators: {
        required: true,
    },
    errorMessage: "Задолжително поле",
};

export const newAdFields = {
    id: ID,
    dogovoren_organ: DOGOVOREN_ORGAN,
    create_date: CREATE_DATE,
    rok_na_traenje: ROK_NA_TRAENJE,
    rok_na_plakjanje: ROK_NA_PLAKJANJE,
    rok_na_isporaka: ROK_NA_ISPORAKA,
    ime: IME,
    kolicina: KOLICINA,
    kategorija: KATEGORIJA,
    cena_min: CENA_MIN,
    valuta: VALUTA,
    bez_limit: BEZ_LIMIT,
    opseg_na_cena: OPSEG_NA_CENA,
    cena_max: CENA_MAX,
    opis: OPIS,
    dokumenti: DOKUMENTI,
    image: IMAGE,
    kriterium: KRITERIUM,
};
