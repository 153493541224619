export const USERS = 'users';
export const CURRENT_STATE = 'current-state';

export const PRODUCT_IMAGE = 'product-image';
export const PRODUCT_DOCUMENT = 'product-document';

export const ADVERTISEMENT = 'advertisements';
export const PRODUCTS = 'products';

export const MESSAGES = 'messages';
export const MESSAGES_STATUS = 'messages-status';

export const NEWSLETTER = 'newsletter';

export const OFFERS = 'offers';
export const OFFERS_STATUS = 'offers-status';