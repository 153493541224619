import React from "react";
import Button from "../../../UI/Button/Button";
import classes from "./MenuActions.module.scss";

import CloseStroke from "./../../../../assets/icons/CloseStroke.svg";
import Switch from "../../../UI/Switch/Switch";

import { useDispatch, useSelector } from "react-redux";
import { sidebarActions } from "../../../../store/sidebar/sidebar";
import { RootState } from "../../../../store";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../../../enums/routes";

const MenuActions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isChecked = useSelector(
        (state: RootState) => state.sidebar.anonymousCreate
    );

    const onAnonymousChangeHandler = () => {
        dispatch(sidebarActions.toggleAnonymous());
    };

    const navigateToNewAd = () => {
        navigate({
            pathname: RoutesEnum.New,
        });
        dispatch(sidebarActions.toggle());
    };

    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "sidemenu"
        ];
    });

    return (
        <React.Fragment>
            <div className={classes.MenuActionsContainer}>
                <Button
                    label={translations["Креирај оглас"]}
                    icon={CloseStroke}
                    onClick={navigateToNewAd}
                    style={{
                        width: "220px",
                    }}
                />
                {/* <Switch
                    label={translations["Постирајте анонимно (опционално)"]}
                    name="post_anon"
                    checked={isChecked}
                    type="only_switch_clicable"
                    onChange={onAnonymousChangeHandler}
                /> */}
            </div>
        </React.Fragment>
    );
};

export default MenuActions;
