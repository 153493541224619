import React from "react";
import classes from "./SecondaryNavbar.module.scss";

// import NavBarBackground from "./../../../assets/SecondaryNavBarBackground.svg";

const SecondaryNavbar = (props: any) => {
    return (
        <React.Fragment>
            <div className={`${classes.SecondaryNavbar} secondary-nav`}>
                {props.showBackBtn && (
                    <div className="container page-content">
                        {props.showBackBtn()}
                    </div>
                )}
                {/* <img src={NavBarBackground} alt={NavBarBackground} /> */}
            </div>
        </React.Fragment>
    );
};

export default SecondaryNavbar;
