import { ref, onValue /*, onChildRemoved*/ } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { OFFERS, OFFERS_STATUS, USERS } from "../../../api/config/routes";
import { baseHttp } from "../../../api/http/base";
import { RootState } from "../../../store";
import { IAuctionOffer } from "../../../store/auction/auction.model";
// import { IUser } from "../../../store/auth/auth.model";
import { INotificationsList } from "./model";
import NotificationItem from "./NotificationItem/NotificationItem";
import classes from "./NotificationsList.module.scss";

const NotificationsList = (props: INotificationsList) => {
    const [finalOffers, setFinalOffers] = useState<IAuctionOffer[]>([]);
    const [cachedFinalOffers, setCachedFinalOffers] = useState<IAuctionOffer[]>(
        []
    );

    const userId = useSelector((state: RootState) => state.auth.user.userId);

    useEffect(() => {
        const dbRef = baseHttp.getDatabase();
        const offersRef = ref(dbRef, `${OFFERS_STATUS}/${userId}`);
        const query = baseHttp.query(offersRef);

        onValue(query, (statusSnapshot) => {
            const statusData = statusSnapshot.val();

            if (!statusData) {
                setFinalOffers((state) => []);
                setCachedFinalOffers((state) => []);

                return;
            }

            let statusHash = {};
            for (const productId in statusData) {
                for (const userId in statusData[productId]) {
                    for (const offerId in statusData[productId][userId]) {
                        statusHash[offerId] =
                            statusData[productId][userId][offerId];
                    }
                }
            }

            // get offers by product id
            const offersByProductId = Object.keys(statusData).map((productId) =>
                baseHttp.get(ref(dbRef, `${OFFERS}/${productId}`))
            );

            Promise.all(offersByProductId).then((offersSnapshots) => {
                const offersData: IAuctionOffer[] = offersSnapshots.map(
                    (snapshot) => {
                        let data = snapshot.val();
                        for (const offerId in data) {
                            data[offerId]["product_id"] = snapshot.key;
                        }

                        return {
                            ...data,
                        };
                    }
                );

                let offersArray: any = [];

                offersData.forEach((offerItem) => {
                    Object.keys(offerItem).forEach((offerId) => {
                        offersArray.push({
                            ...offerItem[offerId],
                            offer_id: offerId,
                        });
                    });
                });

                const ponuduvaciRefList = offersArray.map((offer) =>
                    baseHttp.get(ref(dbRef, `${USERS}/${offer.ponuduvac_id}`))
                );

                Promise.all(ponuduvaciRefList).then((usersSnapshots) => {
                    const usersData = usersSnapshots.map((userSnap) => {
                        return {
                            ...userSnap.val(),
                            id: userSnap.key,
                        };
                    });

                    offersArray = offersArray.map((offer) => {
                        return {
                            ...offer,
                            ponuduvac: usersData.find(
                                (user) => user.id === offer.ponuduvac_id
                            ),
                            seen: statusHash[offer.offer_id]
                                ? statusHash[offer.offer_id].seen
                                : true,
                        };
                    });

                    offersArray.sort((a, b) => {
                        return b.created_at - a.created_at;
                    });

                    setFinalOffers((state) => offersArray);
                    setCachedFinalOffers((state) => offersArray);
                });
            });
        });
    }, [userId]);

    useEffect(() => {
        if (!props.searchValue) {
            setFinalOffers((state) => cachedFinalOffers);
        }

        cachedFinalOffers.sort((a, b) => {
            return b.created_at - a.created_at;
        });

        const filteredOffers = cachedFinalOffers.filter((offer) => {
            return `${offer.ponuduvac?.random_number_id}`.includes(
                props.searchValue
            );
        });

        setFinalOffers((state) => filteredOffers);
    }, [cachedFinalOffers, props.searchValue]);

    const onDeleteHandler = (offer: IAuctionOffer) => {};

    return (
        <React.Fragment>
            <div className={classes.Container}>
                {finalOffers.length > 0 &&
                    finalOffers.map((offer, index) => {
                        return (
                            <NotificationItem
                                key={index}
                                offer={offer}
                                users={{}}
                                unread={!offer.seen!}
                                onDelete={() => onDeleteHandler(offer)}
                            />
                        );
                    })}
            </div>
        </React.Fragment>
    );
};

export default NotificationsList;
