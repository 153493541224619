import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";

import Page from "../../components/Layout/Page/Page";
import Filters from "./Filters/Filters";
import {
    ISearchParameters,
    IPaginationParameters,
    ISortingParameters
} from "./Filters/model";
import { httpGet } from "../../services/http";
import { RootState } from "../../store";
import Countdown from "../../components/UI/Countdown/Countdown";
import { RoutesEnum } from "../../enums/routes";

import Pencil from "../../assets/icons/Pencil.svg";

import classes from "./Advertisements.module.scss";

const CustomActionCol = (props) => {
    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const t_advertisements = t.advertisements;
    const t_global = t.global;
    const auction = props?.row?.auction || false;
    const id = props?.row?.id || null;
    const offers = props?.row?.total_offers || 0;

    return(
        <>
            <span>{auction ? t_global.yes : t_global.no}</span>
            {(offers > 0) && (
                <NavLink to={`/my-advertisements/offers/${id}`}>
                    {t_advertisements.see_offers}<Badge bg="primary">{offers}</Badge>
                </NavLink>
            )}
        </>
    )
}

const Advertisements = (props: any) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const t_advertisements = t.advertisements;
    const t_global = t.global;

    const [myAds, setMyAds] = useState<[]>([]);
    const [activeTab, setActiveTab] = useState<string>("active");
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
        searchValue: "",
        datumOd: "",
        datumDo: "",
    });
    const [pagination, setPagination] = useState<IPaginationParameters>({
        sizePerPage: 10,
        page: 1
    });
    const [sorting, setSorting] = useState<ISortingParameters>({
        sortField: null,
        sortOrder: undefined
    });
    const [adsCount, setAdsCount] = useState({
        active: 0,
        inactive: 0,
        all: 0
    })
    const [totalData, setTotalData] = useState<number>(0);

    const onSearchChangeHandler = (params: ISearchParameters) => {
        setSearchparameters(params);
        const { page, sizePerPage } = pagination;
        const { sortField, sortOrder } = sorting;
        fetchAds(page, sizePerPage, sortField, sortOrder, activeTab, params);
    };

    const fetchAds = async(page = 1, sizePerPage = 10, sortField = null, sortOrder = undefined, selectedTab = "active", filter = searchParameters) => {
        const selectedTabVal = (selectedTab === "active") ? 1 : (selectedTab === "inactive") ? 2 : 3;
        let query = `page=${page}&per_page=${sizePerPage}&tender_type=${selectedTabVal}`;
        if ((sortField !== null) && (sortOrder !== undefined)) {
            query += `&sort_field=${sortField}&sort_type=${sortOrder}`;
            setSorting({sortField, sortOrder});
        }
        if ((filter.datumOd !== "") && (filter.datumDo !== "")) {
            query += `&date_from=${dayjs(filter.datumOd).format("YYYY-MM-DD")}`;
            query += `&date_to=${dayjs(filter.datumDo).format("YYYY-MM-DD")}`;
        }
        if (filter.searchValue !== "") {
            query += `&search_word=${filter.searchValue}`;
        }
        setMyAds([]);
        setPagination({
            page,
            sizePerPage
        });
        setLoadingData(true);
        try {
            const response = await httpGet<any>(`/products/get?${query}&XDEBUG_SESSION_START=phpstorm`);
            setMyAds(response.data[selectedTab].data);
            setTotalData(response.data[selectedTab].total_items)
            setLoadingData(false);
            setAdsCount((state) => ({
                ...state,
                [selectedTab]: response.data[selectedTab].total_items
            }));
        } catch (error) {
          console.error(error);
          setLoadingData(false);
        };
    };

    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        fetchAds(page, sizePerPage, sortField, sortOrder, activeTab);
    }

    const onTabChange = (tab) => {
        setActiveTab(tab);
        const { sizePerPage } = pagination;
        setSorting({sortField: null, sortOrder: undefined});
        setPagination({
            page: 1,
            sizePerPage
        });
        fetchAds(1, sizePerPage, null, undefined, tab);
    }

    const endTimeFormatter = (cell, row) => {
        if (dayjs(cell).isValid() && (dayjs(cell).isAfter(dayjs().format("YYYY/MM/DD")) || dayjs(cell).isSame(dayjs().format("YYYY/MM/DD")))) {
            return (
                <Countdown
                    date={dayjs(cell).format("YYYY/MM/DD")}
                    mode="light"
                />
            );
        } else {
            if (dayjs(cell).isValid()) {
                return dayjs(cell).format("DD.MM.YYYY");
            } else {
                return cell;
            }
        }
    }

    const tableColumns = [{
        dataField: "ad_id",
        text: t_advertisements.offer_num,
        formatter: (cell, row) => {
            return(
                <NavLink to={`${RoutesEnum.Advertisements}/${row.ad_id}`} className={classes.AdLink}>
                    <img src={Pencil} alt="Pencil icon" />
                    <span>{cell}</span>
                </NavLink>
            );
        },
        sort: true
    }, {
        dataField: "title",
        text: t_advertisements.offer_name,
        sort: true
    }, {
        dataField: "category",
        text: t_advertisements.categories
    }, {
        dataField: "date_auction",
        text: t_advertisements.closure,
        formatter: (cell, row) => {
            return endTimeFormatter(cell, row);
        }
    }, {
        dataField: "auction",
        text: t_advertisements.auction,
        formatter: (cell, row) => <CustomActionCol row={row} />
    }];

    const customCaret = (order, column) => {
        if (!order) {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-5px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else if (order === 'asc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                    </span>
                </span>
            );
        } else if (order === 'desc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else {
            return null;
        }
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {`${t_global["table_total_text"]} ${from} ${t_global["table_total_text_1"]} ${to} ${t_global["table_total_text_2"]} ${size} ${t_global["table_total_text_3"]}`}
        </span>
    );

    const paginationOptions = {
        custom: true,
        totalSize: totalData,
        page: pagination.page,
        sizePerPage: pagination.sizePerPage,
        paginationSize: 4,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        firstPageText: "<<",
        prePageText: "<",
        nextPageText: ">",
        lastPageText: ">>",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
          text: "10", value: 10
        }, {
          text: "50", value: 50
        }, {
          text: "100", value: 100
        }],
    };

    return (
        <React.Fragment>
            <Page>
                <Filters
                    onSearchChange={onSearchChangeHandler}
                    advertisements={myAds}
                />
                <div className={classes.table_wrapper}>
                    <div className={classes.buttons_wrapper}>
                        <div
                            className={`${classes.button} ${(activeTab === "active") ? classes.active : ""}`}
                            onClick={() => onTabChange("active")}
                        >
                            {t_advertisements["active"]} {(activeTab === "active")
                                && <Badge bg="primary">{adsCount.active}</Badge>
                            } 
                        </div>
                        <div
                            className={`${classes.button} ${(activeTab === "inactive")? classes.active : ""}`}
                            onClick={() => onTabChange("inactive")}
                        >
                            {t_advertisements["finished"]} {(activeTab === "inactive")
                                && <Badge bg="primary">{adsCount.inactive}</Badge>
                            }
                        </div>
                        <div
                            className={`${classes.button} ${(activeTab === "all") ? classes.active : ""}`}
                            onClick={() => onTabChange("all")}
                        >
                            {t_advertisements["all"]} {(activeTab === "all")
                                && <Badge bg="primary">{adsCount.all}</Badge>
                            }
                        </div>
                    </div>
                    <div className={classes.table_container}>
                        
                        <PaginationProvider
                            pagination={ paginationFactory(paginationOptions) }
                        >
                            {
                                ({
                                    paginationProps,
                                    paginationTableProps
                                }) => (
                                    <div>
                                        <BootstrapTable
                                            keyField="id"
                                            remote={{
                                                pagination: true
                                            }}
                                            onTableChange={onTableChange}
                                            data={myAds}
                                            columns={tableColumns}
                                            classes={classes.my_ads_table}
                                            noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                                            sort={{
                                                sortCaret: customCaret
                                            }}
                                            { ...paginationTableProps }
                                        />
                                        <div className={classes.pagination_wrapper}>
                                            <PaginationTotalStandalone
                                                { ...paginationProps }
                                            />
                                            <div>
                                                <PaginationListStandalone
                                                    { ...paginationProps }
                                                />
                                                <SizePerPageDropdownStandalone
                                                    { ...paginationProps }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </PaginationProvider>
                    </div>
                </div>
            </Page>
        </React.Fragment>
    );
};

export default Advertisements;
