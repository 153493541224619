import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import { useSelector } from "react-redux";

// import CustomPopover from "../../../../components/UI/Popover/Popover";
// import Button from "../../../../components/UI/Button/Button";

import Paper from "./../../../../assets/icons/Paper.svg";
import { INotification } from "./model/notification.model";
import { RootState } from "../../../../store";
// import { ref, remove } from "firebase/database";
// import { OFFERS, OFFERS_STATUS } from "../../../../api/config/routes";
// import { baseHttp } from "../../../../api/http/base";

// import CloseCircleAccent from "./../../../../assets/icons/CloseCircleAccent.svg";

import classes from "./NotificationItem.module.scss";

dayjs.extend(relativeTime);

const NotificationItem = (props: INotification) => {
    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "ponudi"
        ];
    });

    const translationsCurrencies = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "currencies"
        ];
    });

    // const userId = useSelector((state: RootState) => state.auth.user.userId);

    // const onDeclineClick = () => {
    //     document.body.click();
    // };

    // const onRemoveClick = () => {
    //     console.log(props);
    //     const offer_status_id = `${OFFERS_STATUS}/${userId}/${props.offer.product_id}/${props.offer.ponuduvac_id}/${props.offer.offer_id}`;
    //     const offer_id = `${OFFERS}/${props.offer.product_id}/${props.offer.offer_id}`;
    //     const dbRef = baseHttp.getDatabase();
    //     const offerStatusRef = ref(dbRef, offer_status_id);
    //     const offerRef = ref(dbRef, offer_id);

    //     setTimeout(() => {
    //         document.body.click();
    //     }, 200);

    //     Promise.all([remove(offerRef), remove(offerStatusRef)]);
    // };

    let classList = [classes.Container];

    if (props.unread) {
        classList.push(classes.Undread);
    }

    // const deletePopover = (
    //     <div className={classes.PopupContainer}>
    //         <div className="p-3 pb-1">
    //             <div className={classes.Info}>
    //                 {
    //                     translations[
    //                         "Дали сте сигурни дека сакате да ја избришете понудата?"
    //                     ]
    //                 }
    //             </div>
    //             <div className={classes.Actions}>
    //                 <div className="flex-grow-1">
    //                     <Button
    //                         label={translations["Не"]}
    //                         class="light"
    //                         asLink
    //                         onClick={onDeclineClick}
    //                     />
    //                 </div>
    //                 <Button
    //                     label={translations["Да, избриши"]}
    //                     asLink
    //                     asNormalWeight
    //                     onClick={onRemoveClick}
    //                 />
    //             </div>
    //         </div>
    //     </div>
    // );

    // const onClickHandler = () => {
    //     const offer_status_id = `${OFFERS_STATUS}/${userId}/${props.offer.product_id}/${props.offer.ponuduvac_id}/${props.offer.offer_id}`;

    //     const dbRef = baseHttp.getDatabase();
    //     const offerStatusRef = ref(dbRef, offer_status_id);

    //     setTimeout(() => {
    //         document.body.click();
    //     }, 200);

    //     baseHttp.set(baseHttp.ref(dbRef, offer_status_id), {
    //         seen: true,
    //     });
    // };

    return (
        <React.Fragment>
            <div className={classList.join(" ")}>
                {props.unread && (
                    <div className={classes.Indicator}>
                        <div className={classes.Bullet}></div>
                    </div>
                )}
                <div className={classes.Icon}>
                    <img src={Paper} alt="Notification" />
                </div>
                <div className={classes.Id}>{props.offer.random_offer_id}</div>
                <div className={classes.Content}>
                    {translations["Компанија"]} #
                    {props.offer.ponuduvac?.random_number_id}{" "}
                    {translations["ви испрати понуда во износ од"]}{" "}
                    {Number(props.offer.suma).toLocaleString("mk", {
                        minimumFractionDigits: 2,
                    })}
                    {translationsCurrencies["MKD"]}
                </div>
                <div className={classes.Actions}>
                    <div className={classes.arrive_date}>
                        {dayjs(props.offer.created_at).fromNow()}
                    </div>
                    {/* <CustomPopover
                        icon={MessageNotActive}
                        activeIcon={MessageActive}
                        overlay={emailPopover}
                        borderlessTransparent
                    /> */}
                    {/* <button onClick={onClickHandler}>
                        <span className={classes.Small}>
                            {translations["Погледни"]}
                        </span>
                        <span className={classes.Large}>
                            {translations["Погледни понуда"]}
                        </span>
                    </button> */}
                    {/* <CustomPopover
                        icon={CloseCircleAccent}
                        activeIcon={CloseCircleAccent}
                        overlay={deletePopover}
                        borderlessTransparent
                    /> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotificationItem;
