import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { IPropsModel } from "./header.model";
import { RootState } from "../../../../../store";
import { RoutesEnum } from "../../../../../enums/routes";
import OfferPreviewPdf from "../../../../../components/Printing/OfferPreviewPDF/OfferPreviewPDF";

import Download from "../../../../../assets/icons/DownloadWhite.svg";
import NoImageIcon from "../../../../../assets/icons/No-image-icon.svg";

import classes from "./Header.module.scss";

const Header = (props:IPropsModel) => {

    const navigate = useNavigate();

    const [viewPdf, setViewPdf] = useState<boolean>(false);

    const t = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "offers"
            ]
    );

    return (
        <React.Fragment>
            <div className={classes.header_wrapper}>
                <div className={classes.ad_info_wrapper}>
                    <div className={classes.ad_info}>
                        <div className={classes.ad_number}>
                            {t.ad_number} <span>{props?.ad_id}</span>
                        </div>
                        <span className={classes.vertical_line}></span>
                        <div className={classes.date}>
                            {dayjs(props?.date).isValid()
                                ? dayjs(props?.date).format("DD.MM.YYYY")
                                : props?.date
                            }
                        </div>
                    </div>
                    <div className={classes.actions_wrapper}>
                        <div className={classes.download_doc} onClick={() => setViewPdf(true)}>
                            <img src={Download} alt="Download" />
                            {t.download_doc}
                        </div>
                        <Link to={RoutesEnum.Messages} className={classes.ask_question}>
                            {t.ask_question}
                        </Link>
                        {(props?.offer_status && (props?.offer_status === "2")) && (
                            <button
                                className={`${classes.auction_join} ${classes.disabled}`}
                                disabled={false}
                                onClick={() => navigate(`/my-offers/auction/${props?.offer_id}`)}
                            >
                                {t.auction_join}
                                <span className={classes.icon}></span>
                            </button>
                        )}
                    </div>
                </div>
                <div className={classes.company_info_wrapper}>
                    <div className={classes.image_wrapper}>
                        <div className={classes.image}>
                            <img
                                src={props?.company?.logo || NoImageIcon}
                                alt="Company logo"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = NoImageIcon;
                                }}
                            />
                        </div>
                        <div className={classes.company}>
                            {props?.company?.name}
                        </div>
                    </div>
                    <div className={classes.info_wrapper}>
                        <div className={classes.label_style}>
                            {t.contracting_authority}
                        </div>
                        <div className={classes.company}>
                            {props?.company?.name}
                        </div>
                        <div className={classes.label_style}>
                            {props?.company?.web}
                        </div>
                        <div className={classes.label_style}>
                            {props?.company?.email}
                        </div>
                        <div className={classes.label_style}>
                            {props?.company?.location}
                        </div>
                        <div className={classes.label_style}>
                            {props?.company?.phone}
                        </div>
                    </div>
                </div>
            </div>
            {viewPdf && (
                <OfferPreviewPdf
                    data={props.offer}
                    onCancel={() => setViewPdf(false)}
                />
            )}
        </React.Fragment>
    );
};

export default Header;
