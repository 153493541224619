import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHttpState } from "./http.model";

const initialState: IHttpState = {
	isLoading: false,
	error: {
		message: '',
		show: false
	}
}

const httpSlice = createSlice({
	name: 'http',
	initialState: initialState,
	reducers: {
		showLoader: state => {
			state.isLoading = true;
		},
		hideLoader: state => {
			state.isLoading = false
		},
		showError: (state, action: PayloadAction<string>) => {
			state.error.message = action.payload;
			state.error.show = true;
		},
		hideError: state => {
			state.error.message = '';
			state.error.show = false;
		}
	}
});

export const httpActions = httpSlice.actions;
export default httpSlice.reducer;