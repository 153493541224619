import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import ContentFooter from "../../components/Layout/ContentFooter/ContentFooter";
import Page from "../../components/Layout/Page/Page";
// import { RootState } from "../../store";
import NotificationsHeader from "./NotificationsHeader/NotificationsHeader";
import NotificationsList from "./NotificationsList/NotificationsList";
// import classes from "./NotificationsPage.module.scss";

const NotificationsPage = () => {
    const [searchValue, setSearchValue] = useState<string>("");

    const onSearchValueChangeHandler = (val: string) => {
        setSearchValue((state) => val);
    };

    return (
        <React.Fragment>
            <Page>
                <NotificationsHeader
                    onSearchValueChange={onSearchValueChangeHandler}
                />
                <NotificationsList searchValue={searchValue} />
            </Page>
        </React.Fragment>
    );
};

export default NotificationsPage;
