import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { RootState } from "../../../../store";
import { IAdHeader } from "./model/header.model";

import classes from "./OfferHeader.module.scss";

const OfferHeader = (props: IAdHeader) => {

    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "create_ad"
        ];
    });

    return (
        <React.Fragment>
            <div className={classes.Container}>
                <div className={classes.page_title_wrapper}>
                    <div className={classes.ad_details_wrapper}>
                        <div className={classes.AdFormTitleRowRightInvoiceNo}>
                            {translations["number_od_advertisment"]}: <span>{props?.Info?.number}</span>
                        </div>
                        <div className={classes.separator}></div>
                        <div className={classes.AdFormTitleRowRightDate}>
                            {dayjs(props?.Info?.date).isValid()
                                ? dayjs(props?.Info?.date).format("DD.MM.YYYY")
                                : props?.Info?.date
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.Separator}></div>
            </div>
        </React.Fragment>
    );
};

export default OfferHeader;
