import React, { useEffect, useState } from "react";
import classes from "./UserInfo.module.scss";

import UserAvatar from "./../../../../assets/UserAvatar.svg";
import Verified from "./../../../../assets/icons/Verified.svg";
import LockLight from "./../../../../assets/icons/LockLight.svg";

import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { baseHttp } from "../../../../api/http/base";

import * as _ from "lodash";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../../../enums/routes";
import imagePlaceholder from "../../../../assets/imgPlaceholder.png";
import { httpPost } from "../../../../services/http";

const UserInfo = () => {
  const [logo, setLogo] = useState("");
  const isAnon = useSelector(
    (state: RootState) => state.sidebar.anonymousCreate
  );

  const user = useSelector((state: RootState) => state.auth.user.user);
  // const cloneUser = _.cloneDeep(user);

  let userInfo;
  let userIcon;

  if (!isAnon) {
    userInfo = (
      <React.Fragment>
        <h5>
          <Link to={RoutesEnum.Profile}>{user?.naziv}</Link>
          {/* <img src={Verified} alt="verified" /> */}
        </h5>
      </React.Fragment>
    );
    // alert(logo);
    userIcon = (
      <Link to={RoutesEnum.Profile}>
        <img src={user.logo} alt="user avatar" />
      </Link>
    );
  } else {
    userInfo = (
      <React.Fragment>
        <h5>
          <Link to={RoutesEnum.Profile}>
            Компанија #{user?.random_number_id}
          </Link>
        </h5>
      </React.Fragment>
    );

    userIcon = (
      <React.Fragment>
        <div className={classes.Anon}>
          <img className={classes.LockShade} src={LockLight} alt="lock light" />
          <img className={classes.Lock} src={LockLight} alt="lock light" />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.UserInfoContainer}>
        <div className={classes.UserAvatarLogo}>{userIcon}</div>
        <div className={classes.UserName}>{userInfo}</div>
      </div>
    </React.Fragment>
  );
};

export default UserInfo;
