import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

import IconButton from "../IconButton/IconButton";
import { IPopover } from "./model/popover.model";

import classes from "./Popover.module.scss";

const CustomPopover = (props: IPopover) => {
    const [popoverIcon, setPopoverIcon] = useState(props.icon);
    // let ref = React.useRef(null);

    let classList = [""];

    if (props.square) {
        classList.push("custom-popover-square");
    }

    if (props.popoverCustomClass) {
        classList.push(props.popoverCustomClass);
    } else {
        classList.push("custom-popover-container");
    }

    const popover = (
        <Popover
            className={classList.join(" ")}
            id="popover-positioned-left"
            arrowProps={{
                style: { marginRight: "30px" },
            }}
        >
            {props.overlay}
        </Popover>
    );

    const onToggleHandler = (shown: boolean) => {
        if (shown) {
            setPopoverIcon(props.activeIcon);
        } else {
            setPopoverIcon(props.icon);
        }
    };

    return (
        <React.Fragment>
            <div className={classes.Inline}>
                <OverlayTrigger
                    trigger="click"
                    placement={props?.placement || "bottom-end"}
                    rootClose
                    overlay={popover}
                    onToggle={onToggleHandler}
                >
                    <div className={classes.Inline}>
                        <IconButton
                            icon={popoverIcon}
                            class={"light"}
                            borderless
                            hoverNone
                            borderlessTransparent={props.borderlessTransparent}
                        />
                        {props.badgeValue !== null &&
                            props.badgeValue !== undefined && (
                                <div className={classes.Badge}>
                                    {props.badgeValue}
                                </div>
                            )}
                    </div>
                </OverlayTrigger>
            </div>
        </React.Fragment>
    );
};

export default CustomPopover;
