import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
 } from "react-bootstrap-table2-paginator";
 import { Link, NavLink } from "react-router-dom";

import { RootState } from "../../../store";
import { RoutesEnum } from "../../../enums/routes";
import Page from "../../../components/Layout/Page/Page";
import Countdown from "../../../components/UI/Countdown/Countdown";
import Filters from "./Components/Filters/Filters";
import {
    ISearchParameters,
    ISortingParameters,
    IPaginationParameters
} from "./auctions.model";
import { httpGet } from "../../../services/http";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import classes from "./Auction.module.scss";

dayjs.extend(customParseFormat);

const ActionButton = (props: any) => {

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "auctions"
        ];
    });

    const endDate = `${props.row.start_date_auction} ${props.row.end_time_auction}`;
    const startDate = `${props.row.start_date_auction} ${props.row.start_time_auction}`;

    if (dayjs(endDate).isValid() && dayjs(startDate).isValid()) {
        if ((dayjs(startDate).isBefore(dayjs()) || dayjs(startDate).isSame(dayjs())) && dayjs(endDate).isAfter(dayjs())) {
            return(
                <Link
                    to={props.link}
                    className={`${classes.action_btn} ${(props.activeTable === "my_auctions") ? "" : classes.with_bg}`}
                >
                    {(props.activeTable === "my_auctions")
                        ? t.in_progress
                        : props.is_own
                            ? t.in_progress
                            : t.join
                    }
                </Link>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

const EndTimeFormatter = (cell, row) => {
    const endDate = `${row.start_date_auction} ${row.end_time_auction}`;
    const startDate = `${row.start_date_auction} ${row.start_time_auction}`;
    
    if (dayjs(endDate).isValid() && dayjs(startDate).isValid()) {
        if (dayjs(endDate).isBefore(dayjs()) || dayjs(startDate).isAfter(dayjs())) {
            return (
                <>
                    <div className={classes.date}>{dayjs(row.start_date_auction, "YYYY-MM-DD").isValid() ? dayjs(row.start_date_auction, "YYYY-MM-DD").format("DD.MM.YYYY") : null}</div>
                    <div className={classes.time}>{dayjs(row.end_time_auction, "HH:mm:ss").isValid() ? dayjs(row.end_time_auction, "HH:mm:ss").format("HH:mm") : null}</div>
                </>
            )
        } else if ((dayjs(startDate).isBefore(dayjs()) || dayjs(startDate).isSame(dayjs())) && dayjs(endDate).isAfter(dayjs())) {
            return (
                <Countdown
                    date={endDate}
                    mode="auction_prev"
                    onFinish={() => {}}
                />
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

const Auction = () => {

    const [activeTable, setActiveTable] = useState("my_auctions");
    const [tableData, setTableData]:any = useState([]);
    const [searchParameters, setSearchparameters] = useState<ISearchParameters>({
        searchValue: "",
        datumOd: "",
        datumDo: "",
    });
    const [sorting, setSorting] = useState<ISortingParameters>({
        sortField: null,
        sortOrder: undefined
    });
    const [pagination, setPagination] = useState<IPaginationParameters>({
        sizePerPage: 10,
        page: 1
    });
    const [loadingData, setLoadingData] = useState<boolean>(true);
    const [totalData, setTotalData] = useState<number>(0);
    const [auctionsCount, setAuctionsCount] = useState({
        my_auctions: 0,
        upcoming_auctions: 0,
        past_auctions: 0
    })

    const t = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage];
    });

    const t_auctions = t.auctions;
    const t_paginator = t.paginator;
    const t_global = t.global;
    
    const tableColumns = [{
        dataField: "tender_id",
        text: t_auctions.auction_number,
        formatter: (cell, row) => {
            return(
                <NavLink to={`/auction/auction-bidding/${row.id}`} className={classes.AdLink}>
                    <span>{cell}</span>
                </NavLink>
            );
        },
        sort: true
    }, {
        dataField: "title",
        text: t_auctions.auction_name,
        sort: true
    }, {
        dataField: "company",
        text: t_auctions.company,
        sort: true,
        hidden: (activeTable === "my_auctions") ? true : false,
        classes: classes.company_link,
        formatter: (cell, row) => {
            if(row.company_id) {
                return(
                    <NavLink to={`/company?id=${row.company_id}`}>
                        <span>{cell}</span>
                    </NavLink>
                )
            } else {
                return cell;
            }
        }
    }, {
        dataField: "category",
        text: t_auctions.categories,
        sort: true,
        classes: classes.categories,
        headerClasses: classes.categories
    }, {
        dataField: "start_date_auction",
        text: t_auctions.start,
        formatter: (cell, row) => {
            return (
                <>
                    <div className={classes.date}>{dayjs(row.start_date_auction, "YYYY-MM-DD").isValid() ? dayjs(row.start_date_auction, "YYYY-MM-DD").format("DD.MM.YYYY") : null}</div>
                    <div className={classes.time}>{dayjs(row.start_time_auction, "HH:mm:ss").isValid() ? dayjs(row.start_time_auction, "HH:mm:ss").format("HH:mm") : null}</div>
                </>
            );
        },
        sort: true,
        headerClasses: classes.auction_start
    }, {
        dataField: "end_time_auction",
        text: t_auctions.closing,
        formatter: EndTimeFormatter,
        sort: true,
        headerClasses: classes.auction_end
    }, {
      dataField: "action",
      isDummyField: true,
      text: "",
      headerClasses: classes.action,
      hidden: (activeTable === "past_auctions"),
      formatter: (cell, row) => {
        const endDate = `${row.start_date_auction} ${row.end_time_auction}`;
        const startDate = `${row.start_date_auction} ${row.start_time_auction}`;
        switch (true) {
            case ((activeTable === "my_auctions") || (activeTable === "upcoming_auctions")):
                if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
                    if (dayjs().isBefore(dayjs(endDate))) {
                        return(
                            <ActionButton
                            link={
                                (activeTable === "my_auctions")
                                    ? `${RoutesEnum.Auction}/${row.id}`
                                    : `/auction/auction-bidding/${row.id}`
                            }
                            activeTable={activeTable}
                            is_own={row.own_auction}
                            row={row}
                        />
                        );
                    } else {
                        return null;
                    }
                } else {
                    return null;
                };
            default:
                return null;
        }
      }
    }];

    const customCaret = (order, column) => {
        if (!order) {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-5px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else if (order === 'asc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={`${classes.caret} ${classes.caret_up}`}></span>
                    </span>
                </span>
            );
        } else if (order === 'desc') {
            return (
                <span style={{display: "inline-block", position: "relative", top: "-2px"}}>
                    <span className={classes.sort_wrapper}>
                        <span className={classes.caret}></span>
                    </span>
                </span>
            );
        } else {
            return null;
        }
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {`${t_paginator.show} ${from} ${t_paginator.to} ${to} ${t_paginator.from_total} ${size} ${t_paginator.records}`}
        </span>
    );

    const fetchAuctions = async(page = 1, sizePerPage = 10, sortField = null, sortOrder = undefined, selectedTab = "my_auctions", filter = searchParameters) => {
        const selectedTabVal = (selectedTab === "my_auctions") ? 1 : (selectedTab === "upcoming_auctions") ? 2 : 3;
        let query = `page=${page}&per_page=${sizePerPage}&auction_type=${selectedTabVal}`;
        if ((sortField !== null) && (sortOrder !== undefined)) {
            query += `&sort_field=${sortField}&sort_type=${sortOrder}`;
            setSorting({sortField, sortOrder});
        }
        if ((filter.datumOd !== "") && (filter.datumDo !== "")) {
            query += `&date_from=${dayjs(filter.datumOd).format("YYYY-MM-DD")}`;
            query += `&date_to=${dayjs(filter.datumDo).format("YYYY-MM-DD")}`;
        }
        if (filter.searchValue !== "") {
            query += `&search_word=${filter.searchValue}`;
        }
        setTableData([]);
        setPagination({
            page,
            sizePerPage
        });
        setLoadingData(true);
        try {
            const response = await httpGet<any>(`/auctions/get?${query}&XDEBUG_SESSION_START=phpstorm`);
            setTableData(response.data[selectedTab].data);
            setTotalData(response.data[selectedTab].total_items)
            setLoadingData(false);
            setAuctionsCount((state) => ({
                ...state,
                [selectedTab]: response.data[selectedTab].total_items
            }));
        } catch (error) {
          console.error(error);
          setLoadingData(false);
        };
    };
      
    const paginationOptions = {
        custom: true,
        totalSize: totalData,
        page: pagination.page,
        sizePerPage: pagination.sizePerPage,
        paginationSize: 4,
        pageStartIndex: 1,
        alwaysShowAllBtns: true,
        withFirstAndLast: false,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        firstPageText: "<<",
        prePageText: "<",
        nextPageText: ">",
        lastPageText: ">>",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
          text: "10", value: 10
        }, {
          text: "50", value: 50
        }, {
          text: "100", value: 100
        }]
    };

    const onSearchChangeHandler = (params: ISearchParameters) => {
        setSearchparameters(params);        
        const { page, sizePerPage } = pagination;
        const { sortField, sortOrder } = sorting;
        fetchAuctions(page, sizePerPage, sortField, sortOrder, activeTable, params);
    };

    const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        fetchAuctions(page, sizePerPage, sortField, sortOrder, activeTable);
    }

    const onTabChange = (tab) => {
        setActiveTable(tab);
        const { sizePerPage } = pagination;
        setSorting({sortField: null, sortOrder: undefined});
        setPagination({
            page: 1,
            sizePerPage
        });
        fetchAuctions(1, sizePerPage, null, undefined, tab);
    }

    return (
        <React.Fragment>
            <Page>
                <div className={classes.auction_screen_wrapper}>
                    <Filters
                        onSearchChange={onSearchChangeHandler}
                        advertisements={tableData}
                        activeTable={activeTable}
                    />
                    <div className={classes.table_wrapper}>
                        <div className={classes.buttons_wrapper}>
                            <div
                                className={`${classes.button} ${(activeTable === "my_auctions") ? classes.active : ""}`}
                                onClick={() => onTabChange("my_auctions")}
                            >
                                {t_auctions.my_auctions} {(activeTable === "my_auctions") ? <Badge bg="primary">{auctionsCount.my_auctions}</Badge> : null}
                            </div>
                            <div
                                className={`${classes.button} ${(activeTable === "upcoming_auctions") ? classes.active : ""}`}
                                onClick={() => onTabChange("upcoming_auctions")}
                            >
                                {t_auctions.announced} {(activeTable === "upcoming_auctions") ? <Badge bg="primary">{auctionsCount.upcoming_auctions}</Badge> : null}
                            </div>
                            <div
                                className={`${classes.button} ${(activeTable === "past_auctions") ? classes.active : ""}`}
                                onClick={() => onTabChange("past_auctions")}
                            >
                                {t_auctions.finished} {(activeTable === "past_auctions") ? <Badge bg="primary">{auctionsCount.past_auctions}</Badge> : null}
                            </div>
                        </div>
                        <div className={classes.table_container}>
                            <PaginationProvider
                                pagination={ paginationFactory(paginationOptions) }
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                        <div>
                                            <BootstrapTable
                                                keyField="id"
                                                remote={{
                                                    pagination: true
                                                }}
                                                onTableChange={onTableChange}
                                                data={tableData}
                                                columns={tableColumns}
                                                classes={classes.auctions_table}
                                                noDataIndication={loadingData ? t_global.loading : t_global.no_data}
                                                sort={{
                                                    sortCaret: customCaret
                                                }}
                                                { ...paginationTableProps }
                                            />
                                            <div className={classes.pagination_wrapper}>
                                                <PaginationTotalStandalone
                                                    { ...paginationProps }
                                                />
                                                <div>
                                                    <PaginationListStandalone
                                                        { ...paginationProps }
                                                    />
                                                    <SizePerPageDropdownStandalone
                                                        { ...paginationProps }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </PaginationProvider>
                        </div>
                    </div>
                </div>
            </Page>
        </React.Fragment>
    );
};

export default Auction;
