import React from "react";
import { IInvalidFilesUpload } from "./model";

import classes from "./InvalidFilesUpload.module.scss";

const InvalidFilesUpload = (props: IInvalidFilesUpload) => {
    return (
        <React.Fragment>
            <React.Fragment>
                <div className={`${classes.InvalidFilesContainer} text-center`}>
                    <ul>
                        {props.fileNames.map((fileName) => (
                            <li className={classes.ListItem}>{fileName}</li>
                        ))}
                    </ul>
                </div>
            </React.Fragment>
        </React.Fragment>
    );
};

export default InvalidFilesUpload;
