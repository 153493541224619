import React, { useState } from "react";
import classes from "./NotificationsHeader.module.scss";

import Search from "./../../../assets/icons/Search.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { INotificationHeader } from "./model";

const NotificationsHeader = (props: INotificationHeader) => {
    const [searchValue, setSearchValue] = useState<string>("");

    const translations = useSelector((state: RootState) => {
        return state.translations.translations[state.translations.appLanguage][
            "ponudi"
        ];
    });

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue((state) => event?.target.value);
        props.onSearchValueChange(event?.target.value);
    };

    return (
        <React.Fragment>
            <div className={classes.Container}>
                <div className="row align-items-center">
                    <div className="col-12 col-md-12 col-lg-2 text-center text-lg-start">
                        <div className={classes.Title}>
                            {translations["Нотификации"]}
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-9 col-xl-5">
                        <div className={`${classes.SearchBar}`}>
                            <img
                                src={Search}
                                alt="search"
                                className={classes.Icon}
                            />
                            <input
                                type="text"
                                placeholder={translations["Пребарувај..."]}
                                value={searchValue}
                                onChange={onChangeHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default NotificationsHeader;
