import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { DAYS } from "../../constants/constants";
import classes from "./Calendar.module.scss";
import {
    createDaysForCurrentMonth,
    createDaysForNextMonth,
    createDaysForPreviousMonth,
} from "./helpers";
import { ICalendar } from "./models/calendar.model";

const Calendar = (props: ICalendar) => {
    const translations = useSelector(
        (state: RootState) =>
            state.translations.translations[state.translations.appLanguage][
                "components"
            ]
    );

    const year = props.year;
    const month = props.month;

    const currentMonthDays = createDaysForCurrentMonth(year, month);

    const previousMonthDays = createDaysForPreviousMonth(
        year,
        month,
        currentMonthDays
    );

    const nextMonthDays = createDaysForNextMonth(
        year,
        month,
        currentMonthDays,
        previousMonthDays
    );

    const allDays = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays,
    ];

    const onSelectDay = (date: string, isBeforeToday: boolean) => {
        if (props.preventPreviousDates && isBeforeToday) return;

        props.onDateSelected(date);
    };

    return (
        <React.Fragment>
            <div className={classes.Container}>
                <div className={classes.Calendar}>
                    <div className={classes.DaysName}>
                        {DAYS.map((d, i) => {
                            return (
                                <div key={i} className={classes.DayName}>
                                    {translations[d]}
                                </div>
                            );
                        })}
                    </div>
                    <div className={classes.Days}>
                        {allDays.map((d, i) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() =>
                                        onSelectDay(d.date, d.isBeforeToday)
                                    }
                                    className={`${classes.Day} ${
                                        !d.isCurrentMonth
                                            ? classes.NotCurrentMonth
                                            : ""
                                    } ${d.today ? classes.Today : ""} ${
                                        d.date === props.selectedDate
                                            ? classes.Selected
                                            : ""
                                    } ${
                                        props.preventPreviousDates &&
                                        d.isBeforeToday
                                            ? classes.IsBeforeToday
                                            : ""
                                    }`}
                                >
                                    {d.dayOfMonth}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Calendar;
